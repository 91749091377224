import { Box, HStack, Skeleton, Spinner, Stack, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Transfer } from 'src/api/Transfer';
import DataTable, { amountCell, amountHeader, dateCell } from 'src/components/DataTable';
import { ColumnDef, CellContext, Row } from '@tanstack/react-table';
import { FileDownload, FileSelect } from 'src/components/FileSelect';
import { transferService } from 'src/service/TransferService';
import { countries } from 'src/data/Countries';
import { Partner } from 'src/api/Partner';
import CountryFlag from 'src/components/CountryFlag';
import Content from 'src/components/Content';
import ReachLink from 'src/components/ReachLink';
import { formatPicker } from 'src/data/DateUtil';
import { EntryUtil } from 'src/data/EntryUtil';
import { AmountUtil } from 'src/data/AmountUtil';
import DatePicker from "react-datepicker";
import { CustomDateInput } from 'src/components/DateSelect';
import {
    Select as ReactSelect
} from "chakra-react-select";
import { LabelAndValueOption } from 'src/data/LabelAndValueOption';

const OverviewTransfers = () => {
    const [data, setData] = useState<Transfer[]>([]);
    const [partners, setPartners] = useState<Map<number, Partner>>(new Map()); // id to partner
    const [projects, setProjects] = useState<Map<number, string>>(new Map()); // id to project name
    const [isLoading, setIsLoading] = useState(false);
    // filters
    const [filterYear, setFilterYear] = useState<number | null>(new Date().getFullYear());
    const [filterProjectId, setFilterProjectId] = useState<number | null>(null);
    const [filterProjectOptions, setFilterProjectOptions] = useState<LabelAndValueOption[]>([]);
    const [filterPartnerId, setFilterPartnerId] = useState<number | null>(null);
    const [filterPartnerOptions, setFilterPartnerOptions] = useState<LabelAndValueOption[]>([]);

    const loadAllData = (): Promise<void> => {
        setIsLoading(true);
        return transferService.query(filterYear, filterProjectId, filterPartnerId)
            .then(r => {
                setData(r.results.concat());
                setPartners(new Map(r.partners.map(p => [p.id, p])));
                setFilterPartnerOptions(r.partners.map(p => tuple2OptionItem([p.id, p.name])));
                setProjects(new Map(r.projects.map(p => [p.id, p.name])));
                setFilterProjectOptions(r.projects.map(p => tuple2OptionItem([p.id, p.name])));
            })
            .finally(() => setIsLoading(false));
    }

    const onUpload = useCallback((): Promise<void> => {
        console.info(`upload transfers suceeded...`);
        return loadAllData();
    }, []);

    useEffect(() => {
        loadAllData();
    }, [onUpload, filterYear, filterProjectId, filterPartnerId]);

    // convenience to update ReactSelect
    const tuple2OptionItem = (on: [number, string]): LabelAndValueOption => {
        return {
            label: on[1].trim(),
            value: on[0]
        }
    }

    const columns: ColumnDef<Transfer>[] = useMemo(
        () => [
            {
                id: 'transferDate',
                header: 'Valuta',
                accessorKey: 'transferDate',
                cell: dateCell()
            },
            {
                id: 'country',
                header: 'Land',
                accessorKey: 'partnerId',
                cell: (info: CellContext<Transfer, number>) => {
                    let pid = info.getValue();
                    let partner = partners.get(pid);
                    return (
                        <HStack>
                            <CountryFlag code={partner.country} />
                            <Text>{countries[partner.country]}</Text>
                        </HStack>
                    )
                },
                sortingFn: (rowA: Row<Transfer>, rowB: Row<Transfer>, _): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    let ap = countries[partners.get(a.partnerId).country] ?? '';
                    let bp = countries[partners.get(b.partnerId).country] ?? '';
                    return ap.trim().localeCompare(bp.trim(), undefined, { sensitivity: 'base' });
                },
            },
            {
                id: 'partner',
                header: 'Partner',
                accessorKey: 'partnerId',
                cell: (info: CellContext<Transfer, number>) => {
                    let pid = info.getValue();
                    let partner = partners.get(pid);
                    return (<ReachLink to={`/partners/view/${pid}`} name={partner.name} tooltip='zu den Partnerdetails' />);
                },
                sortingFn: (rowA: Row<Transfer>, rowB: Row<Transfer>, _): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    let ap = partners.get(a.partnerId)?.name ?? '';
                    let bp = partners.get(b.partnerId)?.name ?? '';
                    return ap.trim().localeCompare(bp.trim(), undefined, { sensitivity: 'base' });
                },
            },
            {
                id: 'project',
                header: 'Projekt',
                accessorKey: 'projectId',
                cell: (info: CellContext<Transfer, number>) => {
                    let pid = info.getValue();
                    let projectName = projects.get(pid);
                    return (<ReachLink to={`/projects/view/${pid}`} name={projectName} tooltip='zu den Projektdetails' />);
                },
                sortingFn: (rowA: Row<Transfer>, rowB: Row<Transfer>, _): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    let ap = projects.get(a.partnerId) ?? '';
                    let bp = projects.get(b.partnerId) ?? '';
                    return ap.trim().localeCompare(bp.trim(), undefined, { sensitivity: 'base' });
                },
            },
            {
                id: 'budgetYear',
                header: 'Fonds Jahr',
                accessorKey: 'budgetYear'
            },
            {
                id: 'donationCode',
                header: 'Kostenstelle',
                accessorKey: 'donationCode'
            },
            {
                id: 'total',
                header: amountHeader({ name: 'Auflösung', width: '6rem' }),
                accessorKey: 'total',
                cell: amountCell({ width: '6rem' }),
            },
            {
                id: 'overhead',
                header: 'Overhead',
                accessorFn: (row: Transfer) => row.overhead * 100,
                cell: (info: CellContext<Transfer, number>) => (<Text width='6rem' textAlign='right'>{AmountUtil.format(info.getValue(), 1)} %</Text>)
            },
            {
                id: 'transfer',
                header: amountHeader({ name: 'Transfer', width: '6rem' }),
                accessorKey: 'transfer',
                cell: amountCell({ width: '6rem' }),
            },
            {
                id: 'currency',
                header: 'Währung',
                accessorKey: 'currency'
            },
            {
                id: 'rate',
                header: amountHeader({ name: 'Kurs', width: '5rem' }),
                accessorKey: 'rate',
                cell: amountCell({ width: '5rem' }),
            },
            {
                id: 'foreignTransfer',
                header: amountHeader({ name: 'Transfer FremdW', width: '6rem' }),
                accessorFn: (row: Transfer) => row.transfer * row.rate,
                cell: amountCell({ width: '8rem' }),
            },
            {
                id: 'comments',
                header: 'Kommentar',
                accessorKey: 'comments'
            },
        ], [partners, projects]
    );

    return (
        <Content caption='Transfers'>
            <Stack align={'baseline'} direction={{ base: 'column', sm: 'row' }} justify={'space-between'}>
                <HStack align={'left'}>
                    <Box w='10rem'>
                        <DatePicker
                            showYearPicker
                            width='5rem'
                            dateFormat='yyyy'
                            showPopperArrow={true}
                            isClearable={true}
                            selected={new Date().setFullYear(filterYear)}
                            onChange={(date) => {
                                setFilterYear(date?.getFullYear());
                            }}
                            customInput={<CustomDateInput />}
                        />
                    </Box>
                    <Box w='25rem'>
                        <ReactSelect
                            isSearchable
                            isClearable={true}
                            isDisabled={false}
                            autoFocus={false}
                            name='project-select'
                            placeholder='Projekt'
                            options={filterProjectOptions}
                            onChange={val => setFilterProjectId(val?.value)}
                            value={filterPartnerOptions.find(on => on.value === filterProjectId)}
                        />
                    </Box>
                    <Box w='25rem'>
                        <ReactSelect
                            isSearchable
                            isClearable={true}
                            isDisabled={false}
                            autoFocus={false}
                            name='partner-select'
                            placeholder='Parner'
                            options={filterPartnerOptions}
                            onChange={val => setFilterPartnerId(val?.value)}
                            value={filterPartnerOptions.find(on => on.value === filterPartnerId)}
                        />
                    </Box>
                </HStack>
                <HStack>
                    <FileSelect path='/api/funds/transfers/upload' onUpload={onUpload} />
                    <FileDownload path='/api/funds/transfers/download' name={`transfers-${formatPicker(new Date())}.xlsx`} />
                </HStack>
            </Stack>
            {
                isLoading ? (
                    <Stack py={5}>
                        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                    </Stack>
                ) : (
                    <>
                        <Text fontSize='small'>{EntryUtil.text(data.length)}</Text>
                        <DataTable columns={columns} data={data}
                            initialSortByColumn='transferDate' initialSortByDesc={true}
                            virtual={true}
                            hideColumnsOnXs={['fundSourceId', 'currency', 'rate', 'comments']} />
                    </>
                )
            }
        </Content >
    );
}

export default OverviewTransfers
