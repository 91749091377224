import { useContext, createContext } from 'react';
import usePersistState from './PersistState';
import WebUser from './WebUser'

// https://ibaslogic.com/routing-with-react-router/

export const AUTH_STORAGE_KEY = 'web-user';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  // eventually inject the initial state from local storage here
  const [user, setUser] = usePersistState(AUTH_STORAGE_KEY, null);
  const login = (user: WebUser) => setUser(user);
  const logout = () => setUser(null);
  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
}