import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/modal";
import { Box, Button, Divider, Flex, Heading, HStack, Icon, Text } from "@chakra-ui/react";
import { ReactElement } from "react";
import { TiWarningOutline } from "react-icons/ti";



export interface ConfirmDialogProps {
    heading: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => Promise<void>;
}

export const ConfirmDialog = ({ heading, isOpen, onClose, onConfirm }: ConfirmDialogProps): ReactElement => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset='scale'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader borderTopWidth='5px' borderTopColor='orange' />
                <ModalCloseButton />
                <ModalBody>
                    <Flex flexDirection='row'>
                        <Icon as={TiWarningOutline} color={'orange'} boxSize='4em'/>
                        <Box w='100%'>
                            <Heading as='h1' w='100%' color='red.500' textAlign='center' size='md' mb={4}>
                                {heading}
                            </Heading>
                            <Text align='center'>Willst du wirklich fortfahren?</Text>
                        </Box>
                    </Flex>
                    <Divider colorScheme='orange' mt='15px'/>
                </ModalBody>
                <ModalFooter>
                    <HStack spacing={2} width='50%' alignItems='flex-end'>
                        <Button w='full' bgColor='blue.400' color='white' _hover={{ bg: 'blue.500' }} onClick={onClose}>
                            Nein
                        </Button>
                        <Button w='full' bgColor='red.400' color='white' _hover={{ bg: 'red.500' }} onClick={() => onConfirm().then(() => onClose())}>
                            Ja
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmDialog