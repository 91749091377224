import { BiBuilding, BiEnvelope, BiUser, BiWallet } from 'react-icons/bi';
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, InputGroup, InputLeftElement, Stack, useColorModeValue } from "@chakra-ui/react"
import CurrencySelect from 'src/components/CurrencySelect';
import ErrorMessage from "src/components/ErrorMessage";
import CountrySelect from 'src/components/CountrySelect';
import { Controller, useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from 'react';
import { Partner } from 'src/api/Partner';
import { partnerService } from 'src/service/PartnerService';
import { useNavigate } from 'react-router-dom';

export interface PartnersFormProps {
    partnerId?: number;
    organizationId: number;
    onSubmit: (partner: Partner) => Promise<void>;
    viewOnly?: boolean;
    header: string;
    // set the flag when want to explicitly edit consultan or partner
    // if not set then will be configured at loading time in the effect hook
    // e.g. if partnerId is set, then this flag will be derived at loading time
    consultantOnly?: boolean;
}

const PartnersForm = ({ partnerId, organizationId, onSubmit, viewOnly, header, consultantOnly }: PartnersFormProps): JSX.Element => {
    const { control, register, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm<Partner>({
        defaultValues: {
            id: partnerId, 
            organizationId: organizationId, 
            createdAt: new Date(),
            consultant: consultantOnly,
        }
    });
    const [serverError, setServerError] = useState('');
    const [consultant, setConsultant] = useState<boolean | null>(consultantOnly ?? null);
    const navigate = useNavigate();

    const onSubmitInternal = useCallback(
        (p: Partner): Promise<void> => {
            return onSubmit(p).then(() =>
                navigate(p.consultant ? '/partners/consultants' : '/partners')).catch(err => setServerError('Fehler'));
        }, [onSubmit, navigate]
    );

    useEffect(() => {
        if (partnerId > -1) {
            partnerService.find(partnerId).then(p => {
                setConsultant(p.consultant);
                console.log(`consultant=${p.consultant}`)
                reset(p)
            });
        }
    }, [partnerId, reset]);
    const readOnly = viewOnly ?? false;
    console.info(`form is readonly: ${readOnly}`);

    return (
        <Flex
            minH={{ base: '50vh', lg: '70vh' }}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.25', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'xl'} py={12} px={6}>
                <form onSubmit={handleSubmit(onSubmitInternal)}>
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'} textAlign={'center'}>{`${header}  ${consultant ? 'Konsulent' : 'Partner'}`}</Heading>
                    </Stack>
                    <Box
                        rounded={'lg'}
                        bg={useColorModeValue('gray.25', 'gray.700')}
                        boxShadow={'lg'}
                        p={8}>
                        <Stack spacing={4}>
                            {serverError && <ErrorMessage message={serverError} />}

                            <Box>
                                <FormControl id="name" isRequired isReadOnly={readOnly} isInvalid={!!errors['name']}>
                                    <FormLabel>Name</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none' children={<BiUser color='blue' />} />
                                        <Input autoFocus={true} type="text" {...register('name')} />
                                    </InputGroup>
                                </FormControl>
                            </Box>
                            <Box>
                                <CountrySelect control={control} name='country' readOnly={readOnly} />
                            </Box>

                            <Box>
                                <FormControl id="bankName" isRequired isReadOnly={readOnly}>
                                    <FormLabel>Bank</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none' children={<BiBuilding color='blue' />} />
                                        <Input placeholder='Bankverbindung' type="text" {...register('bankName')} />
                                    </InputGroup>
                                </FormControl>
                            </Box>

                            <Stack direction={{ base: 'column', sm: 'row' }}>
                                <Box>
                                    <FormControl id="accountName" isRequired isReadOnly={readOnly}>
                                        <FormLabel>Konto-Inhaber</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement pointerEvents='none' children={<BiUser color='blue' />} />
                                            <Input type="text" {...register('accountName')} />
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl id="currency" isRequired isReadOnly={readOnly}>
                                        <FormLabel>Währung</FormLabel>
                                        <Controller control={control} name='currency' render={({ field }) => (
                                            <CurrencySelect
                                                value={field.value}
                                                onChange={(code) => field.onChange(code)}
                                                isDisabled={readOnly}
                                            />
                                        )} />
                                    </FormControl>
                                </Box>
                            </Stack>


                            <Stack direction={{ base: 'column', sm: 'row' }}>
                                <Box>
                                    <FormControl id="accountNumber" isRequired isReadOnly={readOnly}>
                                        <FormLabel>Konto Nr</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement pointerEvents='none' children={<BiWallet color='blue' />} />
                                            <Input type="text" {...register('accountNumber')} />
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl id="swiftCode" isRequired isReadOnly={readOnly}>
                                        <FormLabel>SWIFT Code</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement pointerEvents='none' children={<BiWallet color='blue' />} />
                                            <Input type="text" {...register('swiftCode')} />
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                            </Stack>

                            <Box>
                                <FormControl id="bankCode" isReadOnly={readOnly}>
                                    <FormLabel>Bank Code</FormLabel>
                                    <Input type="text" {...register('bankCode')} />
                                </FormControl>
                            </Box>

                            <Box>
                                <FormControl id="email" isRequired isReadOnly={readOnly}>
                                    <FormLabel>E-Mail</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none' children={<BiEnvelope color='blue' />} />
                                        <Input type="text" {...register('email')} />
                                    </InputGroup>
                                </FormControl>
                            </Box>

                            <Stack spacing={10} pt={2}>
                                <Stack
                                    direction={{ base: 'column', sm: 'row' }}
                                    align={'center'}
                                    justify={'space-between'}
                                    spacing={5}>
                                    <Button isDisabled={isSubmitting}
                                        onClick={() => navigate(consultant ? '/partners/consultants/' : '/partners/')}
                                        bg={'red.400'} color={'white'} _hover={{ bg: 'red.500' }}>
                                        Schliessen
                                    </Button>
                                    {!readOnly && (
                                        <Button isLoading={isSubmitting} type='submit' bg={'blue.400'} color={'white'} _hover={{ bg: 'blue.500' }}>
                                            Speichern
                                        </Button>
                                    )}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </form>
            </Stack>
        </Flex>
    );
}

export default PartnersForm