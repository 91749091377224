import { Role } from "src/api/Role";
import { Admin } from "src/api/Admin";
import { Member } from "src/api/Member";
import { Root } from "src/api/Root";

// Utility functions to decide the organization and related media.
export const OrgUtil = {

    // returns true if the subdomain is demo or the request has a query parameter for org=demo
    isDemo(): boolean {
        return window.location.hostname.includes('demo') || window.location.search.includes('org=demo');
    },

    isRoleMember(role: Role): boolean {
        return role === Member || role === 'member';
    },
    isRoleAdmin(role: Role): boolean {
        return role === Admin || role === 'admin';
    },
    isRoleRoot(role: Role): boolean {
        return role === Root || role === 'root';
    },

    isAllowed(currentRole: Role, expectedRole?: Role): boolean {
        //console.log(`current: ${currentRole}`);
        if (!expectedRole) return true; // Handles undefined case first

        if (OrgUtil.isRoleRoot(expectedRole)) return OrgUtil.isRoleRoot(currentRole);
        if (OrgUtil.isRoleAdmin(expectedRole)) return OrgUtil.isRoleAdmin(currentRole) || OrgUtil.isRoleRoot(currentRole);
        if (OrgUtil.isRoleMember(expectedRole)) return true; // Member role is accessible to all
    
        return false;
    },
}
