

export const EntryUtil = {

    text(n: number): string {
        switch (n) {
            case 0: return 'Leer';
            case 1: return '1 Eintrag';
            default: return `${n} Einträge`;
        }
    },
}