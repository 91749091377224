import {
  Flex,
  Box,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Avatar,
  Show,
  Image
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useAuthContext } from 'src/auth/AuthContext';

const Logout = () => {
  const { user, logout } = useAuthContext();

  useEffect(() => {
    logout();
    console.log('logged out...');
  });

  return (
    <Stack as={Box} direction={{ base: 'column', md: 'row' }}
    align={'center'}
    justify={{ base: 'left', lg: 'center' }}
  >

    <Flex
      minH={{ base: '50vh', lg: '70vh' }}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.25', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={6} px={6}>
        <Stack align={'center'}>
          <Avatar bg="orange.500" />
          <Heading fontSize={'4xl'} textAlign={'center'}>Abmeldung</Heading>
          {user && user.data.firstName && <Text fontSize={'lg'} color={'gray.600'}>Bye {user.data.firstName} 👋</Text>}
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('gray.25', 'gray.700')}
          boxShadow={'lg'}
          pos='relative'
          p={8}>
          <Stack spacing={4}>
            <Text noOfLines={2}>
              Die Sitzung abgelaufen ist,
              bitte melde dich erneut an.
            </Text>
            <Stack spacing={10} pt={2}>
              <Button size="lg" bg={'blue.400'} color={'white'} _hover={{ bg: 'blue.500' }}
                onClick={event => window.location.href = '/login'}>
                Anmelden
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>

    <Show above='md'>
        <Flex minH={{ base: '50vh', lg: '70vh' }}>
          <Image
            maxW={'4xl'}
            alt={'Logout image'}
            objectFit={'scale-down'}
            src={'/images/trees.webp'}
            px={5}
          />
        </Flex>
      </Show>

    </Stack>
  );
}

export default Logout