import ReactApexChart from "react-apexcharts";
import { toTime } from "src/data/DateUtil";
import { ApexOptions } from 'apexcharts';
import { Project } from "src/api/Project";
import { Country } from "src/api/Country";
import { useMediaQuery } from "@chakra-ui/react";

const limitYear = (d: Date, startYear: number): Date => {
    const a = new Date(d);
    if (a.getUTCFullYear() < startYear) {
        a.setUTCFullYear(startYear, 0, 1);
        a.setUTCHours(0, 0, 0, 0);
    }
    return a;
}

// we have data starting from this year, regardless a few projects are dated from 2019
const minYear = 2020;

const ProjectTimeline = ({ projects, latestEndDate, countries, height }: { projects: Project[], countries: Country[], latestEndDate: Date, height: number }) => {
    // hide some information on smaller devices
    const [isSmallScreen] = useMediaQuery('(max-width: 1000px)');
    const iso2Country = new Map(countries.map(c => [c.iso2, c]));
    const maxDate = new Date(new Date(latestEndDate).getFullYear() + 1, 0, 0, 0, 0, 0, 0);
    //console.log(`${minDate} - ${maxDate}`)
    let data = projects.map(p => {
        let startDate = limitYear(p.startDate, minYear);
        startDate.setUTCHours(0, 0, 0, 0);
        let point = {
            x: p.name.trim(),
            y: [
                toTime(startDate),
                toTime(p.endDate ?? maxDate),
            ],
            fillColor: p.color
        };
        return point;
    });
    let series: ApexAxisChartSeries = [
        { data: data }
    ];
    let options: ApexOptions = {
        chart: {
            id: 'project-timeline',
            height: height,
            type: 'rangeBar',
            defaultLocale: 'en',
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: true,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: false,
                },
            },
            zoom: {
                enabled: false,
            },
            dropShadow: {
                enabled: true,
                top: 2,
                left: 2,
                blur: 2,
                opacity: 0.5
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                distributed: true,
                dataLabels: {
                    hideOverflowingLabels: false
                }
            }
        },
        dataLabels: {
            enabled: true,
            distributed: false,
            formatter: function (val, opts) {
                var label = opts.w.globals.labels[opts.dataPointIndex]
                // var a = formatOverview(val[0])
                // var b = formatOverview(val[1])
                // return `${label}: ${a} - ${b}`;
                return label;
            },
            style: {
                colors: ['#333', '#333'],
                fontWeight: 'bold',
            },
            background: {
                enabled: false,
            },
        },
        xaxis: {
            type: 'datetime',
            tickPlacement: 'on',
            axisTicks: {
                show: true // Show X-axis ticks
            },
        },
        yaxis: {
            show: (isSmallScreen ? false : true),
            labels: {
                minWidth: (isSmallScreen ? 0 : 126),
                style: {
                    colors: 'grey',
                },
                formatter: function (val: any, opts?: any) {
                    if (isSmallScreen) {
                        return '';
                    } else if (opts) {
                        let country = iso2Country.get(projects[opts.dataPointIndex]?.country);
                        return country?.iso2 === 'CH' ? 'Intern' : country?.region + '/' + country?.name;
                    } else {
                        return val;
                    }
                },
            },
        },
        grid: {
            borderColor: '#888',
            strokeDashArray: 7,
            position: 'front',
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            row: {
                colors: ['white', 'grey'],
                opacity: 0.05
            },
        }
    };

    return (
        <div id="chart" >
            <ReactApexChart options={options} series={series} type="rangeBar" height={height} />
        </div >
    )
}

export default ProjectTimeline