import { Button } from "@chakra-ui/button"
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Text, HStack, Stack, Box } from "@chakra-ui/layout";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/modal";
import { Divider, Flex, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Switch } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { YearlyBudget } from "src/api/YearlyBudget";
import { CustomDateInput, formatDate } from "src/components/DateSelect";
import { AmountUtil } from "src/data/AmountUtil";
import DatePicker from "react-datepicker";
import de from 'date-fns/locale/de';
import ErrorMessage from "src/components/ErrorMessage";
import { CustomAmountInput } from "src/components/AmountInput";
import { FcCalculator } from "react-icons/fc";

export interface BudgetDialogProps {
    data: YearlyBudget;
    currency: string,
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (yb: YearlyBudget) => Promise<void>;
}

const BudgetDialog = ({ data, currency, isOpen, onClose, onConfirm }: BudgetDialogProps) => {

    //console.info(`received ${JSON.stringify(data)}`);
    const [budget, setBudget] = useState<YearlyBudget>(data);
    const [error, setError] = useState('');

    // update the state got from the props at every open
    useEffect(() => setBudget(data), [data]);

    const onSubmitInternal = (event) => {
        event.preventDefault();
        let yb = budget;
        console.info(`budget: ${JSON.stringify(yb)}`);
        try {
            if (!yb.year) {
                throw new Error('Jahr leer');
            }
            if (!yb.budget) {
                throw new Error('Budget leer');
            }
            if (!yb.rateDate) {
                throw new Error('Kursdatum leer');
            }
            if (!yb.rate) {
                throw new Error('Kurs leer');
            }
            if (!yb.projectBudget) {
                throw new Error('Project budget leer');
            }
            if (yb.overhead < 0) {
                throw new Error('Overhead leer');
            }
            return onConfirm(yb).then(() => onClose());
        } catch (err) {
            console.error(err);
            setError(err.message);
        }
    };

    const formatOh = (val): string => `${val}%`;
    const parseOh = (val): number => val.replace(/%/, '');
    // decoupled for the moment - as requested
    //const fundraising = (projectBudget: number, overhead: number): number => projectBudget * 100 / (100 - overhead);

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset='scale' size='lg'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <HStack><FcCalculator size='1.5rem' /><Text>Jahresbudget</Text></HStack>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Stack spacing='5'>
                        {error && <ErrorMessage message={error} />}
                        <Stack direction={{ base: 'column', sm: 'row' }} justify={'space-between'}>
                            <Box w='50%'>
                                <FormControl id="year" isRequired>
                                    <FormLabel>Budget Yahr</FormLabel>
                                    <DatePicker
                                        showYearPicker
                                        dateFormat="yyyy"
                                        showPopperArrow={true}
                                        selected={new Date().setFullYear(budget.year)}
                                        onChange={(date) => {
                                            setError('');
                                            setBudget(prev => {
                                                let merged = { ...prev, ...{ year: date?.getFullYear() } };
                                                return merged;
                                            })
                                        }}
                                        customInput={<CustomDateInput />}
                                    />
                                </FormControl>
                            </Box>
                            <Box w='50%'>
                                <FormControl id="budget" isRequired>
                                    <FormLabel>Budget ({currency})</FormLabel>
                                    <CustomAmountInput
                                        value={budget.budget}
                                        placeholder='in fremder Währung'
                                        onChange={(ev) => {
                                            setError('');
                                            setBudget(prev => {
                                                let v = AmountUtil.parse(ev.target.value);
                                                let pb = v / prev.rate;
                                                //let fb = fundraising(pb, prev.overhead);
                                                let merged = {
                                                    ...prev, ...{
                                                        budget: v,
                                                        projectBudget: pb
                                                    }
                                                };
                                                return merged;
                                            })
                                        }}
                                    />
                                </FormControl>
                            </Box>
                        </Stack>
                        <Box borderWidth='1px' borderRadius='lg' p='.5rem' shadow='lg'>
                            <Text fontWeight='bold' pb='.5rem'>Projekt Budget</Text>
                            <Stack direction={{ base: 'column', sm: 'row' }} justify={'space-between'}>
                                <Box w='50%'>
                                    <FormControl id="rateDate" isRequired>
                                        <FormLabel>Kursdatum</FormLabel>
                                        <DatePicker
                                            name='rateDate'
                                            dateFormat='dd.MM.yyyy'
                                            locale={de}
                                            showPopperArrow={true}
                                            selected={formatDate(budget.rateDate)}
                                            onChange={(date) => {
                                                setError('');
                                                setBudget(prev => {
                                                    let merged = { ...prev, ...{ rateDate: date } };
                                                    return merged;
                                                })
                                            }}
                                            customInput={<CustomDateInput />}
                                        />
                                    </FormControl>
                                </Box>
                                <Box w='50%'>
                                    <FormControl id="rate" isRequired>
                                        <FormLabel>Kurs (CHF/{currency})</FormLabel>
                                        <CustomAmountInput
                                            value={budget.rate}
                                            placeholder='Valutakurs'
                                            onChange={ev => {
                                                setError('');
                                                setBudget(prev => {
                                                    let v = AmountUtil.parse(ev.target.value);
                                                    let pb = prev.budget / v;
                                                    //let fb = fundraising(pb, prev.overhead);
                                                    let merged = {
                                                        ...prev, ...{
                                                            rate: v,
                                                            projectBudget: pb,
                                                        }
                                                    };
                                                    return merged;
                                                })
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </Stack>

                            <Stack direction={{ base: 'column', sm: 'row' }} justify={'space-between'} pt='.5rem'>
                                <Box w='50%'>
                                    <FormControl>
                                        <FormLabel>Indikativ Kurs</FormLabel>
                                        <Switch id='indicativeRate' isChecked={budget.indicativeRate} onChange={ev => {
                                            setError('');
                                            setBudget(prev => {
                                                let ir = ev.currentTarget.checked;
                                                let pb = ir ? prev.projectBudget : prev.budget / prev.rate;
                                                let merged = {
                                                    ...prev, ...{
                                                        indicativeRate: ir,
                                                        projectBudget: pb,
                                                    }
                                                };
                                                return merged;
                                            })
                                        }}>Manuell Eingeben</Switch>
                                    </FormControl>
                                </Box>
                                <Box w='50%'>
                                    <FormControl id="projectBudget" isRequired isReadOnly={!budget.indicativeRate}>
                                        <FormLabel>Budget (CHF)</FormLabel>
                                        <CustomAmountInput
                                            value={budget.projectBudget}
                                            isDisabled={!budget.indicativeRate}
                                            placeholder='Budget / Kurs'
                                            onChange={ev => {
                                                setError('');
                                                setBudget(prev => {
                                                    let pb = AmountUtil.parse(ev.target.value);
                                                    let merged = {
                                                        ...prev, ...{
                                                            projectBudget: pb,
                                                        }
                                                    };
                                                    return merged;
                                                })
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </Stack>
                        </Box>

                        <Box borderWidth='1px' borderRadius='lg' p='.5rem' shadow='lg'>
                            <Text fontWeight='bold' pb='.5rem'>Fundraising Budget</Text>
                            <Stack direction={{ base: 'column', sm: 'row' }} justify={'space-between'}>
                                <Box w='50%'>
                                    <FormControl id="overhead" isRequired>
                                        <FormLabel>Overhead %</FormLabel>
                                        <NumberInput
                                            inputMode='numeric'
                                            precision={1} step={0.5}
                                            clampValueOnBlur={false}
                                            min={0} max={99} // 100 not possible
                                            onChange={(valueString) => {
                                                setError('');
                                                let v = parseOh(valueString);
                                                setBudget(prev => {
                                                    //let fb = fundraising(prev.projectBudget, v);
                                                    let merged = {
                                                        ...prev, ...{
                                                            overhead: v,
                                                        }
                                                    };
                                                    return merged;
                                                });
                                            }}
                                            value={formatOh(budget?.overhead ?? 10)}
                                        >
                                            <NumberInputField name='overhead' />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper bg='green.50' />
                                                <NumberDecrementStepper bg='red.50' />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </FormControl>
                                </Box>

                                <Box w='50%'>
                                    <FormControl id="fundraisingBudget" isRequired>
                                        <FormLabel>Fundraising (CHF)</FormLabel>
                                        <CustomAmountInput
                                            value={budget.fundraisingBudget}
                                            placeholder='Budget + OH'
                                            onChange={(ev) => {
                                                setError('');
                                                setBudget(prev => {
                                                    let v = AmountUtil.parse(ev.target.value);
                                                    let merged = {
                                                        ...prev, ...{
                                                            fundraisingBudget: v,
                                                        }
                                                    };
                                                    return merged;
                                                })
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </Stack>
                        </Box>

                        <Divider my='0' />
                        <Stack justify={'space-between'} alignItems={{ base: 'flex-end' }}>
                            <Flex>
                                <Text>Projekt Budget:</Text><Text mx='3' textAlign='right' fontWeight='black'>{AmountUtil.format(budget.projectBudget ?? 0)}</Text><Text>CHF</Text>
                            </Flex>
                            <Flex>
                                <Text>Fundraising Budget:</Text><Text mx='3' textAlign='right' fontWeight='black'>{AmountUtil.format(budget.fundraisingBudget ?? 0)}</Text><Text>CHF</Text>
                            </Flex>
                        </Stack>
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <HStack spacing={2} >
                        <Button w='full' bg={'red.400'} color={'white'} _hover={{ bg: 'red.500' }} onClick={onClose}>
                            Schliessen
                        </Button>
                        <Button w='full' bg={'blue.400'} color={'white'} _hover={{ bg: 'blue.500' }} onClick={event => onSubmitInternal(event)}>
                            Speichern
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default BudgetDialog