import { PasswordForgotRequest } from 'src/api/PasswordForgotRequest';
import { LoginRequest } from '../api/LoginRequest'
import { LoginResponse } from '../api/LoginResponse'
import apiClient from './core/ApiClient'
import { PasswordChangeRequest } from 'src/api/PasswordChangeRequest';

const login = (email: string, password: string, remember: boolean): Promise<LoginResponse> => {
    let payload: LoginRequest = {
        email: email,
        password: password,
        remember: remember
    }
    return apiClient.post<LoginRequest, LoginResponse>('/api/auth/login', payload);
};

const passwordForgot = (email: string): Promise<void> => {
    let payload: PasswordForgotRequest = {
        email: email
    }
    return apiClient.post<PasswordForgotRequest, void>('/api/auth/forgot', payload);
};

const passwordChange = (oldPassword: string, newPassword: string): Promise<void> => {
    let payload: PasswordChangeRequest = {
        oldPassword: oldPassword,
        newPassword: newPassword
    }
    return apiClient.put<PasswordChangeRequest, void>('/api/auth/password', payload);
};

export const authService = {
    login,
    passwordForgot,
    passwordChange
}