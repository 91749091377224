// Generated by ScalaTS 0.7.0: https://scala-ts.github.io/scala-ts/

declare var exports: any;

export const nsRole = exports;

import * as nsAdmin from './Admin';
import * as nsMember from './Member';
import * as nsRoot from './Root';

export const dependencyModules = [
  nsAdmin,
  nsMember,
  nsRoot,
];

export interface RoleUnion {
}

export function isRoleUnion(v: any): v is RoleUnion {
  return (
    typeof v === 'object' && Object.keys(v).length === 0
  );
}

export class RoleSingleton {
  public readonly Member: nsMember.MemberSingleton = nsMember.MemberInhabitant;

  public readonly Admin: nsAdmin.AdminSingleton = nsAdmin.AdminInhabitant;

  public readonly Root: nsRoot.RootSingleton = nsRoot.RootInhabitant;

  private static instance: RoleSingleton;

  private constructor() {}

  public static getInstance() {
    if (!RoleSingleton.instance) {
      RoleSingleton.instance = new RoleSingleton();
    }

    return RoleSingleton.instance;
  }
}

// fix generated code: ReferenceError: Cannot access  before initialization at
// ---
// export const RoleSingletonInhabitant: RoleSingleton = RoleSingleton.getInstance();

// export function isRoleSingleton(v: any): v is RoleSingleton {
//   return (v instanceof RoleSingleton) && (v === RoleSingletonInhabitant);
// }

// export const RoleInhabitant = RoleSingletonInhabitant;

export type Role = RoleUnion;

export function isRole(v: any): v is Role {
  return (
    isRoleUnion(v)
  );
}