import { Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Stack, Spinner, Skeleton, ModalFooter, Button, Box, Flex, HStack, Icon, VStack, useColorModeValue, Divider, ScaleFade, SimpleGrid, Stat, StatLabel, StatNumber, Heading } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { IconType } from "react-icons";
import { BiCircle, BiCircleHalf, BiCircleQuarter, BiCircleThreeQuarter } from "react-icons/bi";
import { AccountingEntry } from "src/api/AccountingEntry";
import { ProvisionQuarterDetail } from "src/api/ProvisionQuarterDetail";
import { ProvisionsDetailResponse } from "src/api/ProvisionsDetailResponse";
import DataTable, { amountCell, amountHeader, dateCell } from "src/components/DataTable";
import LineWithDot from "src/components/LineWithDot";
import { AmountUtil } from "src/data/AmountUtil";
import { reportService } from "src/service/ReportService";


interface ProvisioningEntry {
    entry: AccountingEntry;
    source: string;
}

interface CardProps {
    title: string;
    detail: ProvisionQuarterDetail;
    icon: IconType;
}

const Card = ({ title, detail, icon }: CardProps) => {

    const columns: ColumnDef<ProvisioningEntry>[] = useMemo(
        () => [
            {
                id: 'date',
                header: () => <div style={{ textAlign: 'left', width: '8rem', paddingRight: '.1rem' }}>Datum</div>,
                accessorKey: 'entry.entryDate',
                cell: dateCell(),
            },
            {
                id: 'year',
                header: 'Fonds Yahr',
                accessorKey: 'entry.year',
            },
            {
                id: 'amount',
                header: amountHeader({ name: 'Summe', width: '8rem' }),
                accessorKey: 'entry.amount',
                cell: amountCell({ width: '8rem' }),
            },
            {
                id: 'entryType',
                header: () => <div style={{ textAlign: 'right', width: '8rem', paddingRight: '.1rem' }}>Typ</div>,
                accessorKey: 'entry.entryType',
            },
            {
                id: 'source',
                header: 'Aus',
                accessorKey: 'source',
            },
            {
                id: 'comments',
                header: () => <div style={{ textAlign: 'left', width: '20rem', paddingRight: '.1rem' }}>Bemerkungen</div>,
                accessorKey: 'entry.comments',
            },
        ], []);

    const entries = detail.previous.map(p => {
        let a: ProvisioningEntry = { entry: p, source: 'Vorjahr' };
        return a;
    }).concat(detail.current.map(c => {
        let a: ProvisioningEntry = { entry: c, source: 'Laufjahr' };
        return a;
    }));

    const sum = (list: readonly AccountingEntry[]): number => list.reduce((e, accu) => e + (accu.amount ?? 0), 0);

    return (
        <HStack
            p={{ base: 1, sm: 3 }}
            bg={useColorModeValue('gray.100', 'gray.800')}
            spacing={2}
            rounded="lg"
            alignItems="center"
            pos="relative"
            _before={{
                content: `""`,
                w: '0',
                h: '0',
                borderColor: `transparent ${useColorModeValue('#edf2f6', '#1a202c')} transparent`,
                borderStyle: 'solid',
                borderWidth: '15px 15px 15px 0',
                position: 'absolute',
                left: '-15px',
                display: 'block'
            }}
        >
            <Icon as={icon} w={8} h={8} color="teal.400" />
            <Heading>{title}</Heading>
            <Box>
                <VStack spacing={2} mb={3} textAlign="left">
                    <DataTable columns={columns} data={entries} initialSortByColumn='date' initialSortByDesc={false} />
                    <Divider borderColor='teal.400' borderWidth='1px' />
                    {/* stats */}
                    <Box w='full'>
                        <ScaleFade in={true} initialScale={0.9} transition={{ enter: { duration: 1 } }}>
                            <Stat shadow={'md'}
                                mt={2}
                                border={'1px solid'}
                                borderColor={useColorModeValue('gray.200', 'gray.800')}
                                rounded={'lg'}>
                                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={1} mt={0} mb={3}>
                                    <Box textAlign="center">
                                        <StatLabel>Vorjahr</StatLabel>
                                        <StatNumber fontWeight='bold'>{AmountUtil.format(detail.start + sum(detail.previous))}</StatNumber>
                                    </Box>
                                    <Box textAlign="center">
                                        <StatLabel>Laufjahr</StatLabel>
                                        <StatNumber fontWeight='bold'>{AmountUtil.format(sum(detail.current))}</StatNumber>
                                    </Box>
                                    <Box textAlign="center">
                                        <StatLabel>Saldo</StatLabel>
                                        <StatNumber>{AmountUtil.format(detail.end)}</StatNumber>
                                    </Box>
                                </SimpleGrid>
                            </Stat>
                        </ScaleFade>
                    </Box>
                </VStack>
            </Box>
        </HStack>
    );
};

export interface DashboardProvisionsDialogProps {
    projectId: number;
    projectName: string;
    projectYear: number;
    donationCode: string;
    isOpen: boolean;
    onClose: () => void;
}

const DashboardProvisionsDialog = ({ projectId, projectName, projectYear, donationCode, isOpen, onClose }: DashboardProvisionsDialogProps) => {

    const [data, setData] = useState<ProvisionsDetailResponse | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        console.log(`details for ${donationCode}`);
        setIsLoading(true);
        reportService.provisionsDetail(projectYear, projectId, donationCode)
            .then(r => setData(r))
            .finally(() => setIsLoading(false));
    }, [projectYear, projectId, donationCode, isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}
            isCentered
            size='6xl'
            scrollBehavior='outside'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{projectName}<Text size='xs'>{projectYear} - {donationCode}</Text></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {!data && isLoading ? (
                        <Stack py={5}>
                            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                            <Skeleton height='40px' />
                            <Skeleton height='40px' />
                            <Skeleton height='40px' />
                            <Skeleton height='40px' />
                        </Stack>
                    ) : (
                        <>
                            {[
                                { title: 'Q1', detail: data.q1, icon: BiCircleQuarter },
                                { title: 'Q2', detail: data.q2, icon: BiCircleHalf },
                                { title: 'Q3', detail: data.q3, icon: BiCircleThreeQuarter },
                                { title: 'Q4', detail: data.q4, icon: BiCircle }
                            ].map((milestone, index) => (
                                <Flex key={index} mb="10px">
                                    <LineWithDot />
                                    <Card {...milestone} />
                                </Flex>
                            ))}
                        </>
                    )}
                </ModalBody>

                <ModalFooter>
                    <Button bg={'red.400'} color={'white'} _hover={{ bg: 'red.500' }} onClick={onClose}>
                        Schliessen
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );

}

export default DashboardProvisionsDialog