import { format, parseISO, parse } from 'date-fns';
import { de } from 'date-fns/locale'

export const formatOverview = (date: Date): string => format(new Date(date), 'PPP', { locale: de });

export const formatPicker = (date: Date): string => format(new Date(date), 'dd.MM.yyyy');

export const dueFormat = 'MMMM d, yyyy h:mm aa'
export const formatDue = (date: Date): string => format(new Date(date), dueFormat);
export const parseDue = (s: string): Date => parse(s, dueFormat, new Date());

export const parseIso = (date: Date): Date => parseISO(new Date(date).toISOString());

export const parse2Date = (s: string): Date => parse(s, 'dd.MM.yyyy', new Date());

export const toTime = (date: Date): number => new Date(date).getTime();

export const dayOfYear = (date: Date): number => Math.floor((date.getTime() - parseISO(date.getFullYear().toString()).getTime()) / 86400000) + 1;
export const isWinterHoliday = (date: Date): boolean => {
    const day = dayOfYear(date);
    return day < 7 || day > 350;
}
export const dateOfYear = (year: number): Date => {
    const now = new Date();
    now.setFullYear(year);
    return now;
}