
import { Badge } from "@chakra-ui/react"
import { Role } from "src/api/Role";
import { OrgUtil } from "src/data/OrgUtil";

interface RoleBadgeProps {
    role: Role
}

const RoleBadge = (props: RoleBadgeProps) => {
    return (
        <>
            {OrgUtil.isRoleMember(props.role) && (<Badge variant='solid' colorScheme='green'>Member</Badge>)}
            {OrgUtil.isRoleAdmin(props.role) && (<Badge variant='solid' colorScheme='orange'>Admin</Badge>)}
            {OrgUtil.isRoleRoot(props.role) && (<Badge variant='solid' colorScheme='red'>Root</Badge>)}
        </>
    )
}

export default RoleBadge