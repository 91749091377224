import { UsersListResponse } from 'src/api/UsersListResponse';
import { UsersMeResponse } from '../api/UsersMeResponse'
import apiClient from './core/ApiClient'
import { User } from 'src/api/User';

const getMe = (token?: string): Promise<UsersMeResponse> => apiClient.get<UsersMeResponse>('/api/users/me', token);

const listAll = (): Promise<readonly User[]> => apiClient.get<UsersListResponse>('/api/users/').then(r => r.results);

const remove = (id: number): Promise<void> => apiClient.delete<void>(`/api/users/${id}`);

export const userService = {
    getMe,
    listAll,
    remove,
}