import { useAuthContext } from "src/auth/AuthContext";
import { useParams } from "react-router-dom";
import ExpensesForm from "./ExpensesForm";

const ExpensesView = () => {
    const { user } = useAuthContext();
    const { id } = useParams();
    return <ExpensesForm expenseId={parseInt(id)} organizationId={user.data.organizationId} onSubmit={(_ps) => Promise.resolve()} viewOnly={true} header='Ansicht' />
}

export default ExpensesView