import ProjectsForm from './ProjectsForm'
import { useAuthContext } from 'src/auth/AuthContext';
import { useParams } from 'react-router-dom';

const ProjectsView = () => {
    const { user } = useAuthContext();
    const { id } = useParams();

    return <ProjectsForm projectId={parseInt(id)} organizationId={user.data.organizationId} onSubmit = {(_project) => Promise.resolve()} viewOnly={true} header='Ansicht'/>
}

export default ProjectsView