import { Box, Flex, Skeleton, Spinner, Stack, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'src/components/DataTable';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { FileSelect } from 'src/components/FileSelect';
import { donorService } from 'src/service/DonorService';
import Content from 'src/components/Content';
import { Donor } from 'src/api/Donor';


const OverviewDonors = () => {
    const [data, setData] = useState<Donor[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadAllData = (): Promise<void> => {
        setIsLoading(true);
        return donorService.listAll()
            .then(r => setData(r.concat()))
            .then(() => setIsLoading(false));
    }

    const onUpload = useCallback((): Promise<void> => {
        console.info(`upload donors suceeded...`);
        return loadAllData();
    }, []);

    useEffect(() => {
        loadAllData();
    }, [onUpload]);

    const columns: ColumnDef<Donor>[] = useMemo<ColumnDef<Donor>[]>(
        () => [
            {
                id: 'id',
                header: 'Id',
                accessorKey: 'id',
                cell: (info: CellContext<Donor, string>) => (
                    <Text fontWeight={'bold'}>{info.getValue()}</Text>
                ),
            },
            {
                id: 'donorType',
                header: () => (<div style={{ textAlign: "right" }}>Typ</div>),
                accessorKey: 'donorType',
                cell: info => (<Text textAlign='right'>Kirche</Text>)
            },
            {
                id: 'name',
                header: 'Name',
                accessorKey: 'name',
            },
            {
                id: 'city',
                header: 'Ort',
                accessorKey: 'city',
            },
        ], []
    );

    return (
        <Content caption='Donors'>
            <Flex justify={'center'} align={'center'}>
                <Box>
                    <FileSelect path='/api/donations/donors/upload' onUpload={onUpload} />
                </Box>
            </Flex>
            {isLoading ? (
                <Stack py={5}>
                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <>
                    <DataTable columns={columns} data={data} hideColumnsOnXs={['donorType', 'city']} />
                </>
            )}
        </Content>
    );
}


export default OverviewDonors
