import { DonationCode } from 'src/api/DonationCode';
import { DonationsCodeFindResponse } from 'src/api/DonationsCodeFindResponse';
import { DonationsCodeListResponse } from 'src/api/DonationsCodeListResponse';
import { DonationsCodeStoreRequest } from 'src/api/DonationsCodeStoreRequest';
import apiClient from './core/ApiClient'

const root = '/api/donations/codes';

const add = (dc: DonationCode): Promise<void> => {
    let payload: DonationsCodeStoreRequest = {
        code: dc
    }
    return apiClient.post<DonationsCodeStoreRequest, void>(root, payload);
}

const update = (dc: DonationCode): Promise<void> => {
    let payload: DonationsCodeStoreRequest = {
        code: dc
    }
    return apiClient.put<DonationsCodeStoreRequest, void>(root, payload);
}

const remove = (code: string): Promise<void> => apiClient.delete<void>(`${root}/${code}`);

const find = (code: string): Promise<DonationCode> => apiClient.get<DonationsCodeFindResponse>(`${root}/${code}`).then(r => r.code);

const listAll = (): Promise<DonationsCodeListResponse> => apiClient.get<DonationsCodeListResponse>(root);

export const donationsCodeService = {
    add,
    update,
    remove,
    find,
    listAll,
}