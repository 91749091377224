import { Box, Modal, ModalOverlay, ModalContent, ModalHeader, HStack, ModalCloseButton, ModalBody, Stack, FormControl, FormLabel, Input, ModalFooter, Button, Text, FormErrorMessage } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FcList } from "react-icons/fc";
import {
    OptionBase,
    CreatableSelect,
    Select as SingleSelect
} from "chakra-react-select";
import ErrorMessage from "src/components/ErrorMessage";
import { Controller, useForm } from "react-hook-form";
import { countries, supported } from "src/data/Countries";
import { DonationCode } from "src/api/DonationCode";


export interface DonationCodeDialogProps {
    initialData: DonationCode | null;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (dc: DonationCode) => Promise<void>;
    existingGroups: Set<string>,
    existingTypes: Map<number, string>;
}

export interface CodeOption extends OptionBase {
    label: string;
    value: string;
}

export interface TypeOption extends OptionBase {
    label: string;
    value: number;
}

const DonationCodeDialog = ({ initialData, isOpen, onClose, onConfirm, existingGroups, existingTypes }: DonationCodeDialogProps) => {

    const { control, register, handleSubmit, reset, formState: { isSubmitting } } = useForm<DonationCode>({
        defaultValues: {
            group: '', code: '', name: '', donationTypeId: -1
        }
    });
    const [error, setError] = useState('');

    // merge existing ones with supported countries
    const supportedCountries = new Set<string>(Object.keys(countries).filter((k) => supported.has(k)).map(c => countries[c]));
    const groupOptions = Array.from(new Set([...existingGroups, ...supportedCountries])).map((k) => {
        let co: CodeOption = {
            label: k,
            value: k,
        }
        return co;
    });

    const typeOptions = Array.from(existingTypes).map(([id, name]) => {
        let co: TypeOption = {
            label: name,
            value: id,
        }
        return co;
    });

    // update the state got from the props at every open
    useEffect(() => {
        console.info(`resetting form to ${JSON.stringify(initialData)}`)
        reset(initialData);
    }, [initialData, reset]);

    const onSubmitInternal =
        async (p: DonationCode): Promise<void> => {
            try {
                console.info(`submitting: ${JSON.stringify(p)}`);
                return await onConfirm(p).then(() => onClose());
            } catch (err) {
                setError('Fehler');
            }
        };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset='scale'>
            <form onSubmit={handleSubmit(onSubmitInternal)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <HStack><FcList size='1.5em' /><Text>Kostenstelle</Text></HStack>
                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <Stack spacing='5'>
                            {error && <ErrorMessage message={error} />}
                            <Box>
                                <Controller control={control} name='group'
                                    rules={{ required: "Gruppe auswählen", validate: (value) => value.length > 0 }}
                                    render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error } }) => (
                                        <FormControl id="group" isRequired>
                                            <FormLabel>Gruppe (oder neue Gruppe erstellen)</FormLabel>
                                            <CreatableSelect
                                                isSearchable
                                                isRequired
                                                required
                                                ref={ref}
                                                name={name}
                                                onBlur={onBlur}
                                                autoFocus={true}
                                                placeholder='Asien und Afrika'
                                                options={groupOptions}
                                                onChange={val => onChange(val.value)}
                                                value={groupOptions.find(c => c.value === value)}
                                                formatCreateLabel={(item) => `Neue Gruppe für ${item}`}
                                            />
                                            <FormErrorMessage>{error?.message}</FormErrorMessage>
                                        </FormControl>
                                    )} />
                            </Box>
                            <Box>
                                <FormControl id="code" isRequired isDisabled={initialData.code.length > 0}>
                                    <FormLabel>Kostenstelle</FormLabel>
                                    <Input placeholder='1AF000PS' {...register('code')} />
                                </FormControl>
                            </Box>
                            <Box>
                                <Controller control={control} name='donationTypeId'
                                    rules={{ required: "Projektmittel typ auswählen", validate: (value) => value > -1 }}
                                    render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error } }) => (
                                        <FormControl id="donationTypeId" isRequired>
                                            <FormLabel>Projektmittel Typ</FormLabel>
                                            <SingleSelect
                                                isSearchable
                                                isRequired
                                                required
                                                ref={ref}
                                                name={name}
                                                onBlur={onBlur}
                                                placeholder='Einzelspenden'
                                                options={typeOptions}
                                                onChange={val => onChange(val.value)}
                                                value={typeOptions.find(c => c.value === value)}
                                            />
                                            <FormErrorMessage>{error?.message}</FormErrorMessage>
                                        </FormControl>
                                    )} />
                            </Box>
                            <Box>
                                <FormControl id="name" isRequired>
                                    <FormLabel>Bezeichnung</FormLabel>
                                    <Input placeholder='Bildung für Kinder in Afrika' {...register('name')} />
                                </FormControl>
                            </Box>
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <HStack spacing={2} >
                            <Button w='full' bg={'red.400'} color={'white'} _hover={{ bg: 'red.500' }} onClick={onClose}>
                                Schliessen
                            </Button>
                            <Button isLoading={isSubmitting} type='submit' w='full' bg={'blue.400'} color={'white'} _hover={{ bg: 'blue.500' }}>
                                Speichern
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    );
}

export default DonationCodeDialog