import { Divider, Flex, Heading, Skeleton, Spinner, Stack, Stat, StatArrow, StatGroup, StatHelpText, StatLabel, StatNumber, Text, VStack } from "@chakra-ui/react";
import { ColumnDef, CellContext } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectYear } from "src/api/ProjectYear";
import Content from "src/components/Content"
import DataTable, { amountHeader, dateCell, moneyCell, moneyListCell, moneySort, moneyText, amountCell, valueCurrencyText } from "src/components/DataTable";
import { LabelAndValueOption } from "src/data/LabelAndValueOption";
import { projectService } from "src/service/ProjectService";
import { reportService } from "src/service/ReportService";
import {
    Select as ReactSelect
} from "chakra-react-select";
import { Money } from "src/api/Money";
import { ProgressBarReact } from "src/components/ProgressBar";
import { TransferYear } from "src/api/TransferYear";
import { TransferDifference } from "src/api/TransferDifference";
import { MoneyUtil } from "src/data/MoneyUtil";
import { ProjectBalance } from "src/api/ProjectBalance";
import { ProjectBalanceReportResponse } from "src/api/ProjectBalanceReportResponse";

const DashboardProject = () => {
    const { id } = useParams();

    const [projectId, setProjectId] = useState<number | null>(id ? parseInt(id) : null);
    const [projectNames, setProjectNames] = useState<LabelAndValueOption[]>([]);
    const [data, setData] = useState<ProjectYear[]>([]);
    const [difference, setDifference] = useState<TransferDifference[]>([]);
    const [differenceTotal, setDifferenceTotal] = useState<number>(0);
    const [plannings, setPlannings] = useState<Map<number, TransferYear>>(new Map());
    const [isLoading, setIsLoading] = useState(false);
    const [balance, setBalance] = useState<ProjectBalanceReportResponse | null>(null);

    const ProjectTabs = () => (<>
        <Flex pt='1rem' justify='left' direction={{ base: 'column', sm: 'row' }} mb='3rem'>
            <StatGroup shadow={'md'} border={'1px solid lightgray'} rounded={'md'} textAlign='right'>
                <Stat px='1rem' key='b1' borderRight='1px solid lightgray'>
                    <StatLabel>Projektfonds</StatLabel>
                    <Divider orientation='horizontal' />
                    <StatNumber whiteSpace={'nowrap'}>{MoneyUtil.format(balance?.provision)}</StatNumber>
                    <StatHelpText fontSize='xs'>Wie viel Geld steht aktuell für das Projekt</StatHelpText>
                </Stat>
                {balance ? balance.partner.map(pm =>
                    <Stat px='1rem' key='b2' borderRight='1px solid lightgray'>
                        <StatLabel>Fond beim Partner</StatLabel>
                        <Divider orientation='horizontal' />
                        <StatNumber whiteSpace={'nowrap'}>{MoneyUtil.format(pm)}</StatNumber>
                        <StatHelpText fontSize='xs'>Wie viel Geld hat der Partner noch</StatHelpText>
                    </Stat>
                ) :
                    <Stat px='1rem' key='b2' borderRight='1px solid lightgray'>
                        <StatLabel>Fond beim Partner</StatLabel>
                        <Divider orientation='horizontal' />
                        <StatNumber whiteSpace={'nowrap'}>{MoneyUtil.format(undefined)}</StatNumber>
                        <StatHelpText fontSize='xs'>Wie viel Geld hat der Partner noch</StatHelpText>
                    </Stat>
                }
                <Stat px='1rem' key='b3' borderRight='1px solid lightgray'>
                    <StatLabel>Ausgeliehen</StatLabel>
                    <Divider orientation='horizontal' />
                    <StatNumber whiteSpace={'nowrap'}>{MoneyUtil.format(balance?.borrowed)}</StatNumber>
                    <StatHelpText fontSize='xs'>Wie viel Geld wurde ausgeliehen von den Internen Reserven</StatHelpText>
                </Stat>
                <Stat px='1rem' key='b4'>
                    <StatLabel>Differenz</StatLabel>
                    <Divider orientation='horizontal' />
                    <StatNumber whiteSpace={'nowrap'}>{MoneyUtil.format({value: differenceTotal, currency: 'CHF'})}</StatNumber>
                    <StatHelpText fontSize='xs'>Differenz zwischen der Überweisung mit dem tatsächlichen und dem budgetierten Wechselkurs</StatHelpText>
                </Stat>
            </StatGroup>
        </Flex>
    </>);

    const loadProjectData = (projectId: number): Promise<void> => {
        setIsLoading(true);

        const a = reportService.projectDetail(projectId)
            .then(r => {
                let total: ProjectYear = {
                    key: {
                        projectId: projectId,
                        year: undefined
                    },
                    balance: r.overall.balance,
                    coverage: r.overall.coverage
                }
                // overall aggregations with every year
                setData(r.results.concat(total));
            });
        const b = reportService.projectTransfer(projectId)
            .then(r => {
                setDifference(r.differences.flatMap(d => d.transfers));
                setPlannings(new Map(r.differences.map(d => [d.year, d])));
                // calculate the total of difference from the conversion rates
                setDifferenceTotal(r.differences
                    .flatMap(d => d.transfers)
                    .map(t => t.plannedTransfer.value - t.transfer.value)
                    .reduce((d1, d2) => d1 + d2, 0));
            });
        const c = reportService.projectBalance(projectId)
            .then(r => setBalance(r))    

        return Promise.all([a, b, c]).then(_ => { }).finally(() => setIsLoading(false));
    }

    useEffect(() => {
        projectService.listNames().then(ps => {
            let ops = ps.map(p => {
                let n: LabelAndValueOption = {
                    label: p.name.trim(),
                    value: p.id
                }
                return n;
            });
            ops.sort((a, b) => a.label.localeCompare(b.label));
            setProjectNames(ops);
        });
    }, []);

    useEffect(() => {
        if (projectId) {
            loadProjectData(projectId);
        }
    }, [projectId])

    const moneyCell2 = (width: string = '10rem') => (info: CellContext<ProjectYear, Money>) => {
        const money = info.getValue();
        return moneyText(money, width, info.row.original.key.year ? 'normal' : 'extrabold');
    }

    const columns: ColumnDef<ProjectYear>[] = useMemo(
        () => [
            {
                id: 'year',
                header: 'Mitteljahr',
                accessorFn: (row: ProjectYear) => row.key.year,
                cell: (info: CellContext<ProjectYear, number>) => {
                    let year = info.getValue();
                    return (<Text width='3rem' fontWeight='bold'>{year ?? 'Total'}</Text>)
                },
            },
            {
                id: 'budget',
                header: amountHeader({ name: 'Projekt Budget' }),
                accessorKey: 'balance.budget',
                cell: moneyCell2(),
                sortingFn: moneySort(),
            },
            {
                id: 'transfer',
                header: amountHeader({ name: 'Transfer', explanation: 'Transfers an Partner und Konsulenten (in grün)' }),
                accessorFn: (row: ProjectYear) => row.balance,
                cell: (info: CellContext<ProjectYear, ProjectBalance>) => {
                    let balance = info.getValue();
                    let fontWeight = info.row.original.key.year ? 'normal' : 'extrabold';
                    return <VStack>
                        {moneyText(balance.transfer, '10rem', fontWeight)}
                        {balance.transferConsultant.value !== 0 && <Flex color='green' fontSize='small'>{moneyText(balance.transferConsultant, '10rem', fontWeight)}</Flex>}
                    </VStack>
                }
            },
            {
                id: 'transferCoverage',
                header: amountHeader({ name: 'Deckung %', explanation: '∑ Transfers / Budget' }),
                accessorKey: 'coverage.transferCoverage',
                cell: (info: CellContext<ProjectYear, number>) => ProgressBarReact(info.getValue())
            },
            {
                id: 'localBudget',
                header: amountHeader({ name: 'Fremd Budget' }),
                accessorKey: 'balance.localBudget',
                cell: moneyCell2('12rem'),
                sortingFn: moneySort(),
            },
            {
                id: 'localTransfers',
                header: amountHeader({ name: 'Fremd Transfer', explanation: 'Transfers an Partner und Konsulenten (in grün) in Fremdwährungen' }),
                accessorFn: (row: ProjectYear) => row.balance,
                cell: (info: CellContext<ProjectYear, ProjectBalance>) => {
                    let balance = info.getValue();
                    let fontWeight = info.row.original.key.year ? 'normal' : 'extrabold';
                    return <VStack>
                        {moneyListCell(balance.localTransfers, info.row.original.balance.localBudget.currency, fontWeight, '12rem')}
                        {balance.localTransfersConsultant.length > 0 && <Flex color='green' fontSize='small'>{moneyListCell(balance.localTransfersConsultant, info.row.original.balance.localBudget.currency, fontWeight, '12rem')}</Flex>}
                    </VStack>
                }
            },
            {
                id: 'localExpenses',
                header: amountHeader({ name: 'Ausgaben', explanation: '∑ Ausgaben / Budget' }),
                accessorKey: 'balance.localExpenses',
                accessorFn: (row: ProjectYear) => row.balance.localExpenses,
                cell: (info: CellContext<ProjectYear, Money[]>) => moneyListCell(info.getValue(), info.row.original.balance.localBudget.currency, info.row.original.key.year ? 'normal' : 'extrabold', '12rem'),
            },
            {
                id: 'expenseCoverage',
                header: amountHeader({ name: 'Ausgaben %' }),
                accessorKey: 'coverage.expenseCoverage',
                cell: (info: CellContext<ProjectYear, number>) => ProgressBarReact(info.getValue())
            },
        ], []);

    const differenceColumns: ColumnDef<TransferDifference>[] = useMemo(
        () => [
            {
                id: 'year',
                header: 'Mitteljahr',
                accessorFn: (row: TransferDifference) => row.budgetYear,
            },
            {
                id: 'transferDate',
                header: 'Valuta',
                accessorKey: 'transferDate',
                cell: dateCell(),
                enableGrouping: false,
            },
            {
                id: 'budgetRate',
                header: amountHeader({ name: 'Budget Kurs' }),
                accessorFn: row => plannings.get(row.budgetYear)?.plannedRate,
                cell: amountCell({}),
                aggregatedCell: '',
                enableGrouping: false,
            },
            {
                id: 'localTransfer',
                header: amountHeader({ name: 'Transfer', explanation: 'Transfer in der lokalen oder gewünschten Währung' }),
                accessorKey: 'localTransfer',
                cell: moneyCell(),
                sortingFn: moneySort(),
                aggregatedCell: info => {
                    const year = info.row.original.budgetYear;
                    const transfers = plannings.get(year)?.transfers.map(t => t.localTransfer);
                    return MoneyUtil.merge(transfers).map(m => moneyText(m, '10rem', 'normal'));
                },
                enableGrouping: false,
            },
            {
                id: 'plannedTransfer',
                header: amountHeader({ name: 'Transfer Budget Kurs', explanation: 'Wie viel würde die Überweisung mit dem ursprünglichen Budgetkurs' }),
                accessorKey: 'plannedTransfer',
                cell: moneyCell(),
                sortingFn: moneySort(),
                aggregatedCell: info => {
                    const year = info.row.original.budgetYear;
                    const sum = MoneyUtil.sumAmount(plannings.get(year)?.transfers.map(t => t.plannedTransfer) ?? []);
                    return valueCurrencyText(sum, 'CHF', '10rem', 'normal')
                },
                enableGrouping: false,
            },
            {
                id: 'rate',
                header: amountHeader({ name: 'Aktueller Kurs' }),
                accessorKey: 'rate',
                cell: amountCell({}),
                aggregatedCell: '',
                enableGrouping: false,
            },
            {
                id: 'transfer',
                header: amountHeader({ name: 'Transfer Aktueller Kurs', explanation: 'Überweisung mit dem aktuellen Wechselkurs' }),
                accessorKey: 'transfer',
                cell: moneyCell(),
                sortingFn: moneySort(),
                aggregatedCell: info => {
                    const year = info.row.original.budgetYear;
                    const sum = MoneyUtil.sumAmount(plannings.get(year)?.transfers.map(t => t.transfer) ?? []);
                    return valueCurrencyText(sum, 'CHF', '10rem', 'normal')
                },
                enableGrouping: false,
            },
            {
                id: 'difference',
                header: amountHeader({ name: 'Differenz', explanation: 'Differenz zwischen der Überweisung mit dem tatsächlichen und dem budgetierten Wechselkurs' }),
                accessorFn: row => row.plannedTransfer.value - row.transfer.value,
                cell: (info: CellContext<TransferDifference, number>) =>
                    <Flex>
                        {valueCurrencyText(info.getValue(), 'CHF', '10rem', 'normal')}
                        <Stat>
                            <StatArrow type={info.getValue() < 0 ? 'decrease' : 'increase'} />
                        </Stat>
                    </Flex>,
                aggregatedCell: (info: CellContext<TransferDifference, number>) =>
                    <Flex>
                        {valueCurrencyText(info.getValue(), 'CHF', '10rem', 'normal')}
                        <Stat>
                            <StatArrow type={info.getValue() < 0 ? 'decrease' : 'increase'} />
                        </Stat>
                    </Flex>,
                enableGrouping: false,
            },
        ], [plannings]);

    return (
        <Content caption='Projekt Ansicht'>
            <Stack w={'md'} py={6}>
                <ReactSelect
                    isSearchable
                    isRequired
                    required
                    isDisabled={false}
                    autoFocus={false}
                    name='project-select'
                    placeholder='Projekt'
                    options={projectNames}
                    onChange={val => setProjectId(val.value)}
                    value={projectNames.find(c => c.value === projectId)}
                />
            </Stack>
            <ProjectTabs />
            
            {isLoading ? (
                <Stack py={5}>
                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <>
                    <DataTable columns={columns} data={data} initialSortByColumn='year' initialSortByDesc={true}
                        hideColumnsOnXs={['transferCoverage', 'expenseCoverage']}
                    />
                    <Heading mt='3rem' size='md' textAlign='center'>Transfers & Kursabweichung</Heading>
                    <DataTable columns={differenceColumns} data={difference} initialSortByColumn={['year', 'transferDate']} initialSortByDesc={true}
                        initialGroupByColumns={['year']} hideColumnsOnXs={['budget']}
                    />
                </>
            )}
        </Content>
    )
}

export default DashboardProject