import apiClient from "./core/ApiClient";
import { ProvisionsReportResponse } from "src/api/ProvisionsReportResponse";
import { FundraisingReportResponse } from "src/api/FundraisingReportResponse";
import { FundraisingProjectResponse } from "src/api/FundraisingProjectResponse";
import { FundraisingProjectYearResponse } from "src/api/FundraisingProjectYearResponse";
import { ProvisionsDetailResponse } from "src/api/ProvisionsDetailResponse";
import { ProjectAllReportResponse } from "src/api/ProjectAllReportResponse";
import { ProjectReportResponse } from "src/api/ProjectReportResponse";
import { ProjectTransferResponse } from "src/api/ProjectTransferResponse";
import { FundMoveReportResponse } from "src/api/FundMoveReportResponse";
import { ProjectBalanceReportResponse } from "src/api/ProjectBalanceReportResponse";
import { FundMoveDebtReportResponse } from "src/api/FundMoveDebtReportResponse";
import { ProjectProvisionReportResponse } from "src/api/ProjectProvisionReportResponse";

const provisions = (year: number): Promise<ProvisionsReportResponse> =>
  apiClient.get<ProvisionsReportResponse>(`/api/reports/provisions/${year}`);

const provisionsDetail = (
  year: number,
  projectId: number,
  donationCode: string
): Promise<ProvisionsDetailResponse> =>
  apiClient.get<ProvisionsDetailResponse>(
    `/api/reports/provisions/${year}/${projectId}/${donationCode}`
  );

const fundraisingAll = (): Promise<FundraisingReportResponse> =>
  apiClient.get<FundraisingReportResponse>("/api/reports/fundraising");

const fundraisingProject = (
  projectId: number
): Promise<FundraisingProjectResponse> =>
  apiClient.get<FundraisingProjectResponse>(
    `/api/reports/fundraising/${projectId}`
  );

const fundraisingProjectYear = (
  projectId: number,
  year: number
): Promise<FundraisingProjectYearResponse> =>
  apiClient.get<FundraisingProjectYearResponse>(
    `/api/reports/fundraising/${projectId}/${year}`
  );

const projectAll = (): Promise<ProjectAllReportResponse> =>
  apiClient.get<ProjectAllReportResponse>("/api/reports/projects");

const projectDetail = (projectId: number): Promise<ProjectReportResponse> =>
  apiClient.get<ProjectReportResponse>(`/api/reports/projects/${projectId}`);

const projectTransfer = (projectId: number): Promise<ProjectTransferResponse> =>
  apiClient.get<ProjectTransferResponse>(`/api/reports/projects/${projectId}/transfers`);

const fundMoves = (): Promise<FundMoveReportResponse> =>
  apiClient.get<FundMoveReportResponse>(`/api/reports/projects/funds/moves`);

const fundMovesDebts = (): Promise<FundMoveDebtReportResponse> =>
  apiClient.get<FundMoveDebtReportResponse>(`/api/reports/projects/funds/moves/debts`);

// provision, dept and transfered to partner
const projectBalance = (projectId: number): Promise<ProjectBalanceReportResponse> =>
  apiClient.get<ProjectBalanceReportResponse>(`/api/reports/projects/${projectId}/balance`);

const projectProvision = (projectId: number): Promise<ProjectProvisionReportResponse> =>
  apiClient.get<ProjectBalanceReportResponse>(`/api/reports/projects/${projectId}/provision`);

export const reportService = {
  provisions,
  provisionsDetail,
  fundraisingAll,
  fundraisingProject,
  fundraisingProjectYear,
  projectAll,
  projectDetail,
  projectTransfer,
  fundMoves,
  fundMovesDebts,
  projectBalance,
  projectProvision,
};
