import { Box, Container, Stack, VisuallyHidden, Text, chakra, useColorModeValue, Divider } from "@chakra-ui/react";
import { ReactNode } from "react";
import { FcInfo, FcInspection } from "react-icons/fc";

const SocialButton = ({
    children,
    label,
    href,
}: {
    children: ReactNode;
    label: string;
    href: string;
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

// bezier path and copyright with social buttons
const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <Box>
            <svg viewBox="0 110 1440 125">
                <path fill='lightgray' fillOpacity=".5" d="M 1 117 L 66 162 C 121 206 192 224 288 224 C 384 224 479 172 626 162 C 731 157 781 152 920 172 C 1017 192 1050 209 1191 209 C 1292 212 1341 192 1435 160 L 1436 236 L 3 237 Z"></path>
            </svg>

            <Divider orientation='horizontal' background='lightgray' colorScheme='gray' py='1px'/>

            <Box bg={useColorModeValue('gray.100', 'gray.600')} px={4} boxShadow={'lg'} mb={5}>
            <Container
                as={Stack}
                maxW={'6xl'}
                py={4}
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
                justify={{ base: 'center', md: 'space-between' }}
                align={{ base: 'center', md: 'center' }}>
                <Text>© 2023-{year}</Text>
                <Stack direction={'row'} spacing={6}>
                    <SocialButton label={'Info'} href={'/info'}>
                        <FcInfo />
                    </SocialButton>
                    <SocialButton label={'Entwicklung'} href={'/dev'}>
                        <FcInspection />
                    </SocialButton>
                </Stack>
            </Container>
            </Box>
        </Box>
    );
}

export default Footer