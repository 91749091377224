import { AddIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { ReactElement } from "react";


export interface AddButtonProps {
    caption: string;
    onClick: () => void;
}

const AddButton = (props: AddButtonProps): ReactElement => {
    return (<Button leftIcon={<AddIcon />} colorScheme="blue" rounded="md" onClick={() => props.onClick()}>{props.caption}</Button>)
}

export default AddButton