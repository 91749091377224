import { useAuthContext } from "src/auth/AuthContext";
import CommitmentsForm from "./CommitmentsForm";
import { commitmentService } from 'src/service/CommitmentService';
import { useParams } from "react-router-dom";

const CommitmentsEdit = () => {
    const { user } = useAuthContext();
    const { id } = useParams();
    return <CommitmentsForm commitmentId={parseInt(id)} organizationId={user.data.organizationId} onSubmit={(ps) => commitmentService.update(ps)} header='Zusage Bearbeiten' />
}

export default CommitmentsEdit