import PartnersForm from './PartnersForm'
import { partnerService } from 'src/service/PartnerService';
import { useAuthContext } from 'src/auth/AuthContext';
import PartnersProps from './PartnersProps';

const PartnersAdd = ({ consultant }: PartnersProps) => {
    const { user } = useAuthContext();
    return <PartnersForm partnerId={-1}
        organizationId={user.data.organizationId}
        onSubmit={(partner) => partnerService.add(partner)}
        header='Neue'
        consultantOnly={consultant}
    />
}

export default PartnersAdd