import { HStack, Skeleton, Spinner, Stack, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable, { amountCell, amountHeader, dateCell } from 'src/components/DataTable';
import { ColumnDef, CellContext, Row } from '@tanstack/react-table';
import { FileDownload, FileSelect } from 'src/components/FileSelect';
import { fundMoveService } from 'src/service/FundMoveService';
import { FundMove } from 'src/api/FundMove';
import Content from 'src/components/Content';
import ReachLink from 'src/components/ReachLink';
import { formatPicker } from 'src/data/DateUtil';
import { EntryUtil } from 'src/data/EntryUtil';

const OverviewFundsMoves = () => {
    const [data, setData] = useState<FundMove[]>([]);
    const [projects, setProjects] = useState<Map<number, string>>(new Map()); // id to project name
    const [isLoading, setIsLoading] = useState(false);

    const loadAllData = (): Promise<void> => {
        setIsLoading(true);
        return fundMoveService.listAll()
            .then(r => {
                setData(r.results.concat());
                setProjects(new Map(r.projects.map(p => [p.id, p.name])));
            })
            .finally(() => setIsLoading(false));
    }

    const onUpload = useCallback((): Promise<void> => {
        console.info(`upload funds suceeded...`);
        return loadAllData();
    }, []);

    useEffect(() => {
        loadAllData();
    }, [onUpload]);

    const columns: ColumnDef<FundMove>[] = useMemo(
        () => [
            {
                id: 'transferDate',
                header: 'Datum',
                accessorKey: 'transferDate',
                cell: dateCell()
            },
            {
                id: 'fromProject',
                header: 'Von Projekt',
                accessorKey: 'fromProjectId',
                cell: (info: CellContext<FundMove, number>) => {
                    let pid = info.getValue();
                    let projectName = projects.get(pid);
                    return (<ReachLink to={`/projects/view/${pid}`} name={projectName} tooltip='zu den Projektdetails' />);
                },
                sortingFn: (rowA: Row<FundMove>, rowB: Row<FundMove>, _): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    let ap = projects.get(a.fromProjectId) ?? '';
                    let bp = projects.get(b.fromProjectId) ?? '';
                    return ap.trim().localeCompare(bp.trim());
                },
            },
            {
                id: 'fromDonationCode',
                header: 'Von Kostenstelle',
                accessorKey: 'fromDonationCode'
            },
            {
                id: 'budgetYear',
                header: 'Fonds Jahr',
                accessorKey: 'budgetYear'
            },
            {
                id: 'toProject',
                header: 'Auf Projekt',
                accessorKey: 'toProjectId',
                cell: (info) => {
                    let pid = info.row.original.toProjectId;
                    let projectName = projects.get(pid);
                    return (<ReachLink to={`/projects/view/${pid}`} name={projectName} tooltip='zu den Projektdetails' />);
                },
                sortingFn: (rowA: Row<FundMove>, rowB: Row<FundMove>, _): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    let ap = projects.get(a.toProjectId) ?? '';
                    let bp = projects.get(b.toProjectId) ?? '';
                    return ap.trim().localeCompare(bp.trim());
                },
            },
            {
                id: 'toDonationCode',
                header: 'Auf Kostenstelle',
                accessorKey: 'toDonationCode'
            },
            {
                id: 'fundSourceName',
                header: 'Fonds Quelle',
                accessorKey: 'fundSourceName',
                cell: (info: CellContext<FundMove, string>) => (<Text fontWeight={'bold'}>{info.getValue()}</Text>)
            },
            {
                id: 'transfer',
                header: amountHeader({ name: 'Summe', width: '7rem' }),
                accessorKey: 'transfer',
                cell: amountCell({ width: '7rem' }),
            },
        ], [projects]
    );

    return (
        <Content caption='Zuweisung Fonds'>
            <Stack align={'baseline'} direction={{ base: 'column', sm: 'row' }} justify={'end'}>
                <HStack>
                    <FileSelect path='/api/funds/moves/upload' onUpload={onUpload} />
                    <FileDownload path='/api/funds/moves/download' name={`fondsanderungen-${formatPicker(new Date())}.xlsx`} />
                </HStack>
            </Stack>
            {isLoading ? (
                <Stack py={5}>
                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <>
                    <Text fontSize='small'>{EntryUtil.text(data.length)}</Text>
                    <DataTable columns={columns} data={data} initialSortByColumn='transferDate' initialSortByDesc={true} hideColumnsOnXs={['fromDonationCode', 'toDonationCode']} />
                </>
            )}
        </Content>
    );
}

export default OverviewFundsMoves
