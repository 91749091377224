import {
    AspectRatio,
    Box,
    Container,
    forwardRef,
    Heading,
    Input,
    Progress,
    Stack,
    Text
} from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { useState } from "react";
import apiClient from "src/service/core/ApiClient";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";

const first = {
    rest: {
        rotate: "-15deg",
        scale: 0.95,
        x: "-50%",
        filter: "grayscale(80%)",
        transition: {
            duration: 0.5,
            type: "tween",
            ease: "easeIn"
        }
    },
    hover: {
        x: "-70%",
        scale: 1.1,
        rotate: "-20deg",
        filter: "grayscale(0%)",
        transition: {
            duration: 0.4,
            type: "tween",
            ease: "easeOut"
        }
    }
};

const second = {
    rest: {
        rotate: "15deg",
        scale: 0.95,
        x: "50%",
        filter: "grayscale(80%)",
        transition: {
            duration: 0.5,
            type: "tween",
            ease: "easeIn"
        }
    },
    hover: {
        x: "70%",
        scale: 1.1,
        rotate: "20deg",
        filter: "grayscale(0%)",
        transition: {
            duration: 0.4,
            type: "tween",
            ease: "easeOut"
        }
    }
};

const third = {
    rest: {
        scale: 1.1,
        filter: "grayscale(80%)",
        transition: {
            duration: 0.5,
            type: "tween",
            ease: "easeIn"
        }
    },
    hover: {
        scale: 1.3,
        filter: "grayscale(0%)",
        transition: {
            duration: 0.4,
            type: "tween",
            ease: "easeOut"
        }
    }
};

const PreviewImage = forwardRef<any, any>((props, ref) => {
    return (
        <Box
            bg="white"
            top="0"
            height="100%"
            width="100%"
            position="absolute"
            boxShadow='lg'
            rounded="sm"
            borderColor="gray.400"
            as={motion.div}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            {...props}
            ref={ref}
        >
        </Box>
    );
});


export const FileSelect = ({ path, onUpload, ...props }: { path: string, onUpload: () => Promise<void> }) => {

    const handleUpload = (file2Upload: File): Promise<void> => {
        console.info(`uploading ${file2Upload.name}`);
        setIsLoading(true);
        setIsSuccess(false);
        let formData = new FormData();
        formData.append('file', file2Upload);
        let res = apiClient.upload(path, formData);
        return res
            .catch(apiClient.handleError)
            .then(_res => {
                setIsLoading(false);
                setIsSuccess(true);
                return onUpload();
            })
            .catch(err => {
                setIsLoading(false);
                setError(err.message);
                return;
            });
    }

    const [error, setError] = useState<string>('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const controls = useAnimation();
    const startAnimation = () => controls.start("hover");
    const stopAnimation = () => controls.stop();

    return (
        <Container mx='0px' px='0px' {...props}>
            <AspectRatio width="40" ratio={1}>
                <Box
                    borderColor="gray.300"
                    borderStyle="dashed"
                    borderWidth="2px"
                    rounded="lg"
                    shadow="md"
                    role="group"
                    transition="all 150ms ease-in-out"
                    _hover={{
                        shadow: "lg",
                    }}
                    as={motion.div}
                    initial="rest"
                    animate="rest"
                    whileHover="hover"
                >
                    <Box position="relative" height="100%" width="100%">
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            height="100%"
                            width="100%"
                            display="flex"
                            flexDirection="column"
                        >
                            <Stack
                                height="100%"
                                width="100%"
                                display="flex"
                                alignItems="center"
                                justify="center"
                                spacing="4"
                            >
                                <Box height="12" width="9" position="relative">
                                    <PreviewImage
                                        variants={first}
                                        backgroundImage="/images/csv.png"
                                    />
                                    <PreviewImage
                                        variants={second}
                                        backgroundImage="/images/file.png"
                                    />
                                    <PreviewImage
                                        variants={third}
                                        backgroundImage="/images/excel2.png"
                                    />
                                </Box>
                                <Stack p="0" textAlign="center" spacing="1">
                                    <Heading fontSize="lg" color="gray.700" fontWeight="bold">
                                        Datei ablegen
                                    </Heading>
                                    <Text hidden={isSuccess || error !== ''} fontSize='xs'>oder zum Hochladen klicken</Text>

                                    {isLoading && <Progress colorScheme='green' size='md' isIndeterminate />}
                                    {error && <ErrorMessage message={error} />}
                                    {isSuccess && <SuccessMessage message='Erfolgreich!' />}
                                </Stack>
                            </Stack>
                        </Box>
                        <Input
                            type='file'
                            cursor='pointer'
                            title='keine Datei ausgewählt'
                            height="100%"
                            width="100%"
                            position="absolute"
                            top="0"
                            left="0"
                            opacity="0"
                            aria-hidden="true"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                            multiple={false}
                            onDragEnter={startAnimation}
                            onDragLeave={stopAnimation}
                            onChange={ev => {
                                setError('');
                                setIsSuccess(false);
                                if (ev.target.files.length > 0) {
                                    handleUpload(ev.target.files[0]);
                                }
                            }}
                        />
                    </Box>
                </Box>
            </AspectRatio>
        </Container>
    );
}

export const FileDownload = ({ path, name, ...props }: { path: string, name: string }) => {

    const controls = useAnimation();
    const startAnimation = () => controls.start("hover");
    const stopAnimation = () => controls.stop();
    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = async (path: string) => {
        console.log(`downloading from ${path}`);
        setIsLoading(true);
        const blob = await apiClient.download(path).finally(() => setIsLoading(false));
        // create a download link for the Blob
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        // set the download attribute and trigger the download
        a.setAttribute('download', name);
        document.body.appendChild(a);
        console.log('saving...');
        a.click();
        console.log('cleaning up...');
        // cleanup the temporary URL
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    return (
        <Container mx='0px' px='0px' {...props}>
            <AspectRatio width="40" ratio={1}>
                <Box
                    borderColor="gray.300"
                    borderStyle="dashed"
                    borderWidth="2px"
                    rounded="lg"
                    shadow="md"
                    role="group"
                    transition="all 150ms ease-in-out"
                    _hover={{
                        shadow: "lg",
                    }}
                    as={motion.div}
                    initial="rest"
                    animate="rest"
                    whileHover="hover"
                >
                    <Box position="relative" height="100%" width="100%">
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            height="100%"
                            width="100%"
                            display="flex"
                            flexDirection="column"
                        >
                            <Stack
                                height="100%"
                                width="100%"
                                display="flex"
                                alignItems="center"
                                justify="center"
                                spacing="4"
                            >
                                <Box height="12" width="9" position="relative">
                                    <PreviewImage
                                        variants={third}
                                        backgroundImage="/images/excel2.png"
                                    />
                                </Box>
                                <Stack p="0" textAlign="center" spacing="1">
                                    <Heading fontSize="lg" color="gray.700" fontWeight="bold">
                                        Daten herunterladen
                                    </Heading>
                                    {isLoading && <Progress colorScheme='green' size='md' isIndeterminate />}
                                </Stack>
                            </Stack>
                        </Box>
                        <Box
                            cursor='pointer'
                            title='zum Herunterladen klicken'
                            height="100%"
                            width="100%"
                            position="absolute"
                            top="0"
                            left="0"
                            opacity="0"
                            aria-hidden="true"
                            onDragEnter={startAnimation}
                            onDragLeave={stopAnimation}
                            onClick={ev => handleDownload(path)}
                        />
                    </Box>
                </Box>
            </AspectRatio>
        </Container>
    )
}