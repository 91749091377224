import { Partner } from "src/api/Partner";
import { PartnersFindResponse } from "src/api/PartnersFindResponse";
import { PartnersListResponse } from "src/api/PartnersListResponse";
import { PartnersStoreRequest } from "src/api/PartnersStoreRequest";
import apiClient from "./core/ApiClient";

const root = "/api/partners";

const add = (partner: Partner): Promise<void> => {
  let payload: PartnersStoreRequest = {
    partner: partner,
  };
  return apiClient.post<PartnersStoreRequest, void>(root, payload);
};

const update = (partner: Partner): Promise<void> => {
  let payload: PartnersStoreRequest = {
    partner: partner,
  };
  return apiClient.put<PartnersStoreRequest, void>(root, payload);
};

const remove = (id: number): Promise<void> =>
  apiClient.delete<void>(`${root}/${id}`);

const find = (id: number): Promise<Partner> =>
  apiClient.get<PartnersFindResponse>(`${root}/${id}`).then((r) => r.partner);

const listAll = (): Promise<readonly Partner[]> =>
  apiClient.get<PartnersListResponse>(root).then((r) => r.results);

const listFiltered = (consultant: boolean): Promise<readonly Partner[]> =>
  apiClient.get<PartnersListResponse>(`${root}?consultant=${consultant}`).then((r) => r.results);

export const partnerService = {
  add,
  update,
  remove,
  find,
  listAll,
  listFiltered,
};
