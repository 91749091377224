import { Donor } from 'src/api/Donor';
import apiClient from './core/ApiClient'
import { DonorListResponse } from 'src/api/DonorListResponse';

const path = '/api/donations/donors';

const listAll = (): Promise<readonly Donor[]> => apiClient.get<DonorListResponse>(path).then(r => r.results);

export const donorService = {
    listAll,
}