import { BiBarChartAlt, BiPieChartAlt, BiWallet, BiWindow } from 'react-icons/bi';
import {
    Text, Box, Button, Flex, FormControl, FormLabel, Heading, Input, InputGroup, InputLeftElement, Stack, useColorModeValue,
    SimpleGrid, Radio, RadioGroup, HStack, Stat, StatLabel, StatNumber, Textarea, Divider, useDisclosure, IconButton,
    Menu, MenuButton, MenuItem, MenuList, ScaleFade, Switch, TabPanels, TabPanel, Tabs, TabList, Tab
} from "@chakra-ui/react"
import CurrencySelect from 'src/components/CurrencySelect';
import ErrorMessage from "src/components/ErrorMessage";
import { Controller, useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { projectService } from 'src/service/ProjectService';
import { partnerService } from 'src/service/PartnerService';
import { useNavigate } from 'react-router-dom';
import DataTable from 'src/components/DataTable';
import { ColumnDef, CellContext } from '@tanstack/react-table';
import { ProjectSummary } from 'src/api/ProjectSummary';
import CountrySelect from 'src/components/CountrySelect';
import DateSelectController from 'src/components/DateSelect';
import { YearlyBudget } from 'src/api/YearlyBudget';
import { formatOverview } from 'src/data/DateUtil';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import BudgetDialog from './BudgetDialog';
import { AmountUtil } from 'src/data/AmountUtil';
import { FaEllipsisV } from 'react-icons/fa';
import ConfirmDialog from 'src/components/ConfirmDialog';
import {
    OptionBase,
    Select as ReactSelect
} from "chakra-react-select";
import AddButton from 'src/components/AddButton';
import ColorPicker from 'src/components/ColorPicker';
import { countries } from "src/data/Countries";

export interface ProjectsFormProps {
    projectId: number;
    organizationId: number;
    onSubmit: (ps: ProjectSummary) => Promise<void>;
    viewOnly?: boolean;
    header: string;
}

export interface PartnerOption extends OptionBase {
    label: string;
    value: number;
}

const ProjectsForm = ({ projectId, organizationId, onSubmit, viewOnly, header }: ProjectsFormProps): JSX.Element => {
    const { control, register, handleSubmit, reset, getValues, formState: { errors, isSubmitting }, watch } = useForm<ProjectSummary>({
        defaultValues: {
            project: {
                id: projectId,
                name: '',
                startDate: new Date(),
                endDate: new Date(),
                internal: false,
                color: projectId > -1 ? '#FFFFFF' : '#FE9200', // otherwise flickers
                createdAt: new Date(),
                organizationId: organizationId,
            },
            budget: [],
            partnerIds: []
        }
    });
    const [serverError, setServerError] = useState('');

    // fixed or open
    const [budgetType, setBudgetType] = useState<string>('fixed');
    // use a default, will be updated before the modal opens
    const [changeBudget, setChangeBudget] = useState<YearlyBudget>(
        {
            projectId: -1,
            year: new Date().getFullYear(),
            budget: undefined,
            projectBudget: undefined,
            rate: undefined,
            rateDate: new Date(),
            indicativeRate: false,
            fundraisingBudget: undefined,
            overhead: 20,
            organizationId: organizationId
        });
    const { isOpen: isBudgetOpen, onOpen: onBudgetOpen, onClose: onBudgetClose } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

    // manage the budget list in a state (not in the form)
    const [yearlyBudget, setYearlyBudget] = useState<YearlyBudget[]>([]);
    // manage configured partner ids in a state (not in the form)
    const [partnerIds, setPartnerIds] = useState<number[]>([]);

    const navigate = useNavigate();

    // read all partner details to map it with the partner ids configured to the project
    const [partners, setPartners] = useState([]);
    useEffect(() => {
        partnerService.listAll().then(ps => setPartners(ps.concat()));
    }, []);

    // in edit or view mode fill in the form details, budget and partner ids
    useEffect(() => {
        if (projectId > -1) {
            projectService.find(projectId).then(p => {
                console.info(`found project ${JSON.stringify(p)}`);
                setBudgetType(p.project.endDate ? 'fixed' : 'open');
                setYearlyBudget(p.budget.concat());
                setPartnerIds(p.partnerIds.concat());
                reset(p);
            });
        }
    }, [projectId, reset]);


    const onBudgetChange = useCallback((yb: YearlyBudget): Promise<void> => {
        console.info(`upserting budget for ${yb.year}`);
        setChangeBudget(yb);
        // update data, override the same year
        setYearlyBudget(prev => {
            let old = prev.filter(p => p.year !== yb.year);
            let newBudget: YearlyBudget[] = [].concat(old, yb).sort((a, b) => a.year - b.year);
            return newBudget;
        });
        return Promise.resolve();
    }, []);

    const readOnly = viewOnly ?? false;
    console.info(`form is readonly: ${readOnly}`);

    const onSubmitInternal = (p: ProjectSummary): Promise<void> => {
        let validated: ProjectSummary = {
            ...p,
            ...{
                project: { ...p.project, ...{ endDate: (budgetType === 'open' ? null : p.project.endDate) } },
                budget: yearlyBudget,
                partnerIds: partnerIds
            }
        }
        console.info(`submitting ${JSON.stringify(validated)}`);
        return onSubmit(validated)
            .then(() => navigate('/projects'))
            .catch(_err => setServerError('Fehler'));
    };


    const amountHeader = (name: string) => () => (<div style={{ fontSize: 'xs', textAlign: 'right', width: '8rem', paddingRight: '.2rem' }}>{name}</div>)
    const amountCell = (fontWeight: string = 'normal') => (info: CellContext<YearlyBudget, number>) =>
        (<Text fontSize='xs' fontWeight={fontWeight} width='8rem' textAlign='right'>{AmountUtil.format(info.getValue())}</Text>)
    const columns: ColumnDef<YearlyBudget>[] = useMemo(
        () => [
            {
                id: 'year',
                header: 'Jahr',
                accessorKey: 'year',
                cell: (info: CellContext<YearlyBudget, number>) => (<Text size='xs' fontWeight='bold'>{info.getValue()}</Text>)
            },
            {
                id: 'budget',
                header: amountHeader('Budget'),
                accessorKey: 'budget',
                cell: amountCell()
            },
            {
                id: 'rate',
                header: () => (<div style={{ textAlign: "right" }}>Kurs</div>),
                accessorFn: (row: YearlyBudget) => AmountUtil.format(row.rate),
                cell: (info: CellContext<YearlyBudget, string>) => (<Text fontSize='xs' textAlign='right'>{info.getValue()}</Text>)
            },
            {
                id: 'rateDate',
                header: () => (<div style={{ textAlign: "right" }}>Kursdatum</div>),
                accessorFn: (row: YearlyBudget) => formatOverview(row.rateDate),
                cell: (info: CellContext<YearlyBudget, string>) => (<Text fontSize='xs' textAlign='right'>{info.getValue()}</Text>)
            },
            {
                id: 'projectChf',
                header: amountHeader('Projekt CHF'),
                accessorKey: 'projectBudget',
                cell: amountCell('bold')
            },
            {
                id: 'overhead',
                header: () => (<div style={{ textAlign: "right" }}>Overhead</div>),
                accessorKey: 'overhead',
                cell: (info: CellContext<YearlyBudget, number>) => (<Text fontSize='xs' textAlign='right' fontWeight='bold'>{AmountUtil.format(info.getValue(), 1)}%</Text>)
            },
            {
                id: 'fundraisingChf',
                header: amountHeader('Fundraising CHF'),
                accessorKey: 'fundraisingBudget',
                cell: amountCell('bold')
            },
            {
                id: 'edit',
                enableSorting: false,
                cell: (info) => {
                    let row = info.row.original;
                    return (
                        <>
                            {!readOnly && (
                                <Menu isLazy>
                                    <MenuButton as={IconButton} variant='outline' p="0px" bg="transparent" color="blue.500" cursor="pointer" icon={<FaEllipsisV />} />
                                    <MenuList>
                                        <MenuItem icon={<EditIcon />} command='⌘E' onClick={() => {
                                            //console.info(`set to ${JSON.stringify(row)}`);
                                            setChangeBudget(row);
                                            onBudgetOpen();
                                        }}>Bearbeiten</MenuItem>
                                        <MenuItem icon={<DeleteIcon color="red.500" />} command='⌘D' onClick={() => {
                                            //console.info(`set to ${JSON.stringify(row)}`);
                                            setChangeBudget(row);
                                            onDeleteOpen();
                                        }}>Löschen</MenuItem>
                                    </MenuList>
                                </Menu>
                            )}
                        </>
                    )
                }
            }
        ], [onBudgetOpen, onDeleteOpen, readOnly]
    );

    return (
        <Flex
            minH={{ base: '50vh', lg: '70vh' }}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.25', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'5xl'} py={12} px={6}>
                <form onSubmit={handleSubmit(onSubmitInternal)}>
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'} textAlign={'center'}>{header}</Heading>
                    </Stack>
                    <Box
                        rounded={'lg'}
                        bg={useColorModeValue('gray.25', 'gray.700')}
                        boxShadow={'lg'}
                        p={8}>
                        <Stack spacing={4}>
                            {serverError && <ErrorMessage message={serverError} />}

                            {/* 2 col layout */}
                            <Stack direction={{ base: 'column', sm: 'row' }} spacing={8}>
                                <Stack spacing={4}>
                                    <Box>
                                        <FormControl id="name" isRequired isReadOnly={readOnly} isInvalid={!!errors['project.name']}>
                                            <FormLabel fontWeight={'bold'}>Name</FormLabel>
                                            <InputGroup>
                                                <InputLeftElement pointerEvents='none' children={<BiWindow color='blue' />} />
                                                <Input autoFocus={true} type="text" {...register('project.name')} />
                                            </InputGroup>
                                        </FormControl>
                                    </Box>

                                    {/* multi select for partners */}
                                    <Box maxW={{ sm: '320px', base: '' }} minW='320px'>
                                        <FormControl id="partnerId">
                                            <FormLabel>Partner Organisationen</FormLabel>
                                            <ReactSelect
                                                isSearchable={true}
                                                isMulti={true}
                                                isClearable={false}
                                                isDisabled={readOnly}
                                                name='partnersId'
                                                placeholder='Partners'
                                                options={partners.map(p => {
                                                    let po: PartnerOption = {
                                                        value: p.id,
                                                        label: p.name
                                                    }
                                                    return po;
                                                })}
                                                value={partners
                                                    .filter(p => partnerIds.includes(p.id))
                                                    .map(p => {
                                                        let po: PartnerOption = {
                                                            value: p.id,
                                                            label: p.name
                                                        }
                                                        return po;
                                                    })}
                                                onChange={val => setPartnerIds(val.map(v => v.value))}
                                            />
                                        </FormControl>
                                    </Box>

                                    <Box>
                                        <CountrySelect control={control} name='project.country' readOnly={readOnly} />
                                    </Box>

                                    <Box>
                                        <FormControl id="currency" isRequired isReadOnly={readOnly}>
                                            <FormLabel>Währung</FormLabel>
                                            <Controller control={control} name='project.currency' render={({ field }) => (
                                                <CurrencySelect
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    isDisabled={readOnly}
                                                />
                                            )} />
                                        </FormControl>
                                    </Box>
                                </Stack>

                                {/* second column on large screen */}
                                <Stack spacing='4'>
                                    <Box>
                                        <FormControl id="budgetType" isRequired isReadOnly={readOnly}>
                                            <FormLabel fontWeight={'bold'}>Budget</FormLabel>
                                            <RadioGroup value={budgetType} onChange={setBudgetType}>
                                                <Stack direction='row'>
                                                    <Radio value='fixed'>Fester Zeitraum</Radio>
                                                    <Radio value='open'>Laufend</Radio>
                                                </Stack>
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>

                                    <Stack direction={{ base: 'column', sm: 'row' }} justify={'space-between'}>
                                        <Box>
                                            <FormControl id="startDate" isRequired isReadOnly={readOnly} isDisabled={readOnly}>
                                                <FormLabel>Anfangsdatum</FormLabel>
                                                <DateSelectController control={control} name='project.startDate' clearable={false} />
                                            </FormControl>
                                        </Box>
                                        <Box>
                                            <FormControl id="endDate" isReadOnly={readOnly} isRequired={budgetType === 'fixed'} isDisabled={readOnly || budgetType === 'open'}>
                                                <FormLabel>Enddatum</FormLabel>
                                                <DateSelectController control={control} name='project.endDate' clearable={true} />
                                            </FormControl>
                                        </Box>
                                    </Stack>

                                    <Stack direction={{ base: 'column', sm: 'row' }} justify={'space-between'}>
                                        <Box>
                                            <FormControl id="internal" isReadOnly={readOnly} isDisabled={readOnly}>
                                                <Controller
                                                    control={control}
                                                    name='project.internal'
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <Switch isChecked={value} onChange={onChange} ref={ref}>Interne Reserve</Switch>
                                                    )}
                                                />
                                            </FormControl>
                                        </Box>

                                        <Box>
                                            <FormControl id='color' isReadOnly={readOnly} isDisabled={readOnly}>
                                                <Controller
                                                    control={control}
                                                    name='project.color'
                                                    render={({ field: { value, onChange } }) => (
                                                        <HStack alignItems="start">
                                                            <ColorPicker value={value} onChange={onChange} isReadOnly={readOnly}/>
                                                            <Text textAlign='start'>Projekt Farbe</Text>
                                                        </HStack>
                                                    )}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Stack>

                                    <Box>
                                        <FormControl id="comments" isReadOnly={readOnly}>
                                            <FormLabel>Bemerkungen</FormLabel>
                                            <Textarea {...register('project.comments')} height='7rem' />
                                        </FormControl>
                                    </Box>

                                </Stack>
                            </Stack>
                            <Divider borderColor={watch('project.color')} borderWidth='2px' dropShadow='2px' />

                            {/* yearly budget and summary with multiple countries and currencies */}
                            <Tabs variant='enclosed'>
                                <TabList>
                                    <Tab>{countries[watch('project.country')] ?? 'Land'}</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>

                                        <Stack>

                                            <Stack alignItems={{ base: 'flex-end' }}>
                                                <Box>
                                                    <AddButton caption='Jahresbudget' onClick={() => onBudgetOpen()} />
                                                </Box>
                                            </Stack>
                                            <BudgetDialog
                                                data={changeBudget}
                                                currency={getValues('project.currency') ?? 'CHF'}
                                                isOpen={isBudgetOpen}
                                                onClose={onBudgetClose}
                                                onConfirm={async (yb: YearlyBudget): Promise<void> => onBudgetChange(yb)}
                                            />

                                            <DataTable
                                                caption={yearlyBudget.length > 0 ? '' : 'kein Budget eingetragen'}
                                                columns={columns}
                                                data={yearlyBudget}
                                                initialSortByColumn='year'
                                                initialSortByDesc={false}
                                                onRowClick={row => {
                                                    setChangeBudget(row);
                                                    onBudgetOpen();
                                                }}
                                            />
                                            <ConfirmDialog
                                                heading={`${changeBudget?.year} Löschen`}
                                                isOpen={isDeleteOpen}
                                                onClose={onDeleteClose}
                                                onConfirm={async (): Promise<void> => {
                                                    setYearlyBudget(prev => {
                                                        let toDelete = changeBudget
                                                        let newBudget = prev.filter(p => p.year !== toDelete.year).sort((a, b) => a.year - b.year);
                                                        return newBudget;
                                                    });
                                                    return Promise.resolve();
                                                }}
                                            />

                                            {/* stats */}
                                            <Box>
                                                <ScaleFade in={true} initialScale={0.9} transition={{ enter: { duration: 1 } }}>
                                                    <Stat shadow={'md'}
                                                        mt={2}
                                                        border={'1px solid'}
                                                        borderColor={useColorModeValue('gray.200', 'gray.800')}
                                                        rounded={'lg'}>
                                                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={1} mt={0} mb={3}>
                                                            <Box textAlign="center">
                                                                <Stack mt={4} align='center'>
                                                                    <HStack>
                                                                        <BiWallet color='blue' size={'1.5em'} />
                                                                        <Text fontSize={'lg'} fontWeight={'bold'}>Gesamtbudget</Text>
                                                                    </HStack>
                                                                </Stack>
                                                                <StatNumber fontWeight='bold'>{AmountUtil.format(yearlyBudget.reduce((a, yb) => a + (yb.budget ?? 0), 0))}</StatNumber>
                                                                <StatLabel>{getValues('project.currency') ?? 'CHF'}</StatLabel>
                                                            </Box>
                                                            <Box textAlign="center">
                                                                <Stack mt={4} align='center'>
                                                                    <HStack>
                                                                        <BiPieChartAlt color='blue' size={'1.5em'} />
                                                                        <Text fontSize={'lg'} fontWeight={'bold'}>Projektbudget</Text>
                                                                    </HStack>
                                                                </Stack>
                                                                <StatNumber fontWeight='bold'>{AmountUtil.format(yearlyBudget.reduce((a, yb) => a + (yb.projectBudget ?? 0), 0))}</StatNumber>
                                                                <StatLabel>CHF</StatLabel>
                                                            </Box>
                                                            <Box textAlign="center">
                                                                <Stack mt={4} align='center'>
                                                                    <HStack>
                                                                        <BiBarChartAlt color='blue' size={'1.5em'} />
                                                                        <Text fontSize={'lg'} fontWeight={'bold'}>Fundraisingbudget</Text>
                                                                    </HStack>
                                                                </Stack>
                                                                <StatNumber fontWeight='bold'>{AmountUtil.format(yearlyBudget.reduce((a, yb) => a + (yb.fundraisingBudget ?? 0), 0))}</StatNumber>
                                                                <StatLabel>CHF</StatLabel>
                                                            </Box>
                                                        </SimpleGrid>
                                                    </Stat>
                                                </ScaleFade>
                                            </Box>
                                        </Stack>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>



                            {/* submittion */}
                            <Stack spacing={10} pt={2}>
                                <Stack
                                    direction={{ base: 'column', sm: 'row' }}
                                    align={'center'}
                                    justify={'space-between'}
                                    spacing={10}>
                                    <Button isDisabled={isSubmitting} onClick={() => navigate('/projects/')} bg='red.400' color='white' _hover={{ bg: 'red.500' }}>
                                        Schliessen
                                    </Button>
                                    {!readOnly && (
                                        <Button isLoading={isSubmitting} type='submit' bg='blue.400' color='white' _hover={{ bg: 'blue.500' }}>
                                            Speichern
                                        </Button>
                                    )}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </form>
            </Stack>
        </Flex>
    );
}

export default ProjectsForm