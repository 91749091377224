
import { Tag, Text, Tooltip, useColorModeValue } from "@chakra-ui/react"
import { Link } from "react-router-dom"

interface ReachLinkProps {
    name?: string
    to: string
    tooltip?: string
}

const ReachLink = (props: ReachLinkProps) => {
    const colorLinkHover = useColorModeValue('gray.900', 'gray.200');
    return (
        <Tag>
            <Tooltip hasArrow label={props.tooltip} placement='top-end' bg='blue.700'>
                <Text color='blue.600' _hover={{ color: colorLinkHover }}>
                    <Link to={props.to}>{props.name ?? props.to}</Link>
                </Text>
            </Tooltip>
        </Tag>
    )
}

export default ReachLink