import { Flex, FormControl, FormErrorMessage, FormLabel, Text } from "@chakra-ui/react";
import {
    OptionBase, chakraComponents
} from "chakra-react-select";
import { Control, Controller } from "react-hook-form";
import { countries, supported } from "src/data/Countries";
import CountryFlag from "./CountryFlag";
import { ReactSelectFixed } from "./FixReactSelect";


export interface CountryOption extends OptionBase {
    label: string;
    value: string;
    icon: React.ReactElement
}

const options = Object.keys(countries)
    .filter((k) => supported.has(k))
    .map((k) => {
        let co: CountryOption = {
            label: countries[k],
            value: k,
            icon: <CountryFlag code={k} />
        }
        return co;
    })


const customOption = (props) => (
    <chakraComponents.Option label={props.data.name} {...props}>
        <Flex align="center">
            {props.data.icon}
            <Text ml="10px">{props.data.label}</Text>
        </Flex>
    </chakraComponents.Option>
);

const CountrySelect = ({ control, name, readOnly }: { control: Control<any, any>, name: string, readOnly: boolean }) => {
    return (
        <Controller control={control} name={name}
            rules={{ required: "Land auswählen", validate: (value, formValues) => value.length > 0 }}
            render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error } }) => (
                <FormControl id='country' isRequired isInvalid={!!error} isReadOnly={readOnly}>
                    <FormLabel>Land</FormLabel>
                    <ReactSelectFixed
                        isSearchable
                        isRequired
                        required
                        name={name}
                        onBlur={onBlur}
                        onChange={val => onChange(val.value)}
                        value={options.find(c => c.value === value)}
                        placeholder="Land auswählen"
                        options={options}
                        components={{ Option: customOption }}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                    />
                    <FormErrorMessage>{error?.message}</FormErrorMessage>
                </FormControl>
            )
            } />
    );
}

export default CountrySelect