import { AUTH_STORAGE_KEY } from "src/auth/AuthContext";
import WebUser from "src/auth/WebUser";

export interface HttpResponse<T> {
    data: T;
    status: number;
}

export interface ApiErrorResponse {
    reason?: string;
}

export const DEFAULT_API_ERROR = new Error('Etwas ist schief gelaufen');

export type ClientOptions = Omit<RequestInit, 'body'> & { body?: unknown };


const extractErrorMessage = async (err: unknown): Promise<Error> => {
    if (!err || typeof err !== 'object') {
        return DEFAULT_API_ERROR;
    }

    if (err instanceof Error) {
        return err;
    }
    try {
        const apiError: ApiErrorResponse = await (err as Response).json();
        return apiError?.reason ? new Error(apiError.reason) : DEFAULT_API_ERROR;
    } catch {
        return DEFAULT_API_ERROR;
    }
};

const extractResponseData = <T>(response: HttpResponse<T>): T => response.data;

const handleHttp = async <T>(cb: () => Promise<Response>): Promise<HttpResponse<T>> => {
    try {
        const response = await cb();

        if (!response.ok) {
            // handle unauthorized - 401 - force logout
            if (response.status === 401) {
                // without leading and trailing slash
                let path = window.location.pathname.replace(/^\/|\/$/g, '').trim();
                // ignore landing and login pages
                console.log(`unauthorized - ${path}`);
                if (!['login', 'logout'].includes(path)) {
                    // redirect to logout and check expiry
                    window.location.href = '/logout';
                }
            }
            return Promise.reject(response);
        }
        const text = await response.text();
        return Promise.resolve({ data: text ? JSON.parse(text) : undefined, status: response.status });
    } catch (error) {
        return Promise.reject(error);
    }
};

const tokenFromLocalStorage = (): string | null => {
    let entry: string = localStorage.getItem(AUTH_STORAGE_KEY);
    let user: WebUser | null = entry ? JSON.parse(entry) : null;
    return user?.token;
}

// default http client from Node
// token can be provided explicitly by the caller or read from the locale storage
const client = <T>(path: RequestInfo, options?: ClientOptions, token?: string): Promise<HttpResponse<T>> => {
    const bodyOptions: ClientOptions =
        !!options?.method && options?.method !== 'GET' && options?.body !== undefined
            ? {
                body: JSON.stringify(options.body),
            }
            : {};

    // send auth token explicitly passed or from the locale storage
    let jwtToken = token ?? tokenFromLocalStorage();

    let jsonHeaders = { 'Content-Type': 'application/json', ...(options?.headers ?? {}) };
    let allHeaders = jwtToken ? { 'Authorization': `Bearer ${jwtToken}`, ...jsonHeaders } : jsonHeaders

    const init = {
        ...options,
        headers: allHeaders,
        method: options?.method ?? 'GET',
        ...bodyOptions,
    };

    return handleHttp<T>(() => fetch(path, init as RequestInit));
};

export class ApiClient {

    constructor(baseUrl: string) {
        this.#baseUrl = baseUrl;
    }

    #baseUrl = undefined;

    #headers = new Map<string, string>();

    get baseUrl(): string {
        return this.#baseUrl || '';
    }

    /**
     * Sets default headers that cannot be overriden
     */
    setHeader(key: string, value: string): void {
        if (this.#headers.has(key)) {
            console.error(`Can't override header[${key}]`);
        } else {
            this.#headers.set(key, value);
        }
    }

    #url(endpoint: string): string {
        if (!this.#baseUrl) {
            console.error('base url is not set!');
            return endpoint;
        }

        return this.#baseUrl + endpoint;
    }

    #enrich<T>(path: string, method: ClientOptions['method'], init?: ClientOptions, token?: string): Promise<HttpResponse<T>> {
        const url = this.#url(path);

        const defaultHeaders = Object.fromEntries(this.#headers);

        const headers = {
            ...defaultHeaders,
            ...init?.headers,
        };

        const hasHeaders = Object.keys(headers).length > 0;

        return client<T>(url, {
            ...init,
            method,
            credentials: 'include',
            ...(hasHeaders
                ? {
                    headers,
                }
                : undefined),
        }, token);
    }
    getWithResponse<T>(path: string, init?: ClientOptions, token?: string): Promise<HttpResponse<T>> {
        return this.#enrich<T>(path, 'GET', init, token);
    }
    get<T>(path: string, token?: string): Promise<T> {
        return this.getWithResponse<T>(path, null as ClientOptions, token).then(extractResponseData);
    }
    postWithResponse<T>(path: string, init?: ClientOptions): Promise<HttpResponse<T>> {
        return this.#enrich<T>(path, 'POST', init);
    }
    post<P, T>(path: string, payload: P): Promise<T> {
        return this.postWithResponse<T>(path, { body: payload } as ClientOptions).then(extractResponseData);
    }
    putWithResponse<T>(path: string, init?: ClientOptions): Promise<HttpResponse<T>> {
        return this.#enrich<T>(path, 'PUT', init);
    }
    put<P, T>(path: string, payload: P): Promise<T> {
        return this.putWithResponse<T>(path, { body: payload } as ClientOptions).then(extractResponseData);
    }
    deleteWithResponse<T>(path: string, init?: ClientOptions): Promise<HttpResponse<T>> {
        return this.#enrich<T>(path, 'DELETE', init);
    }
    delete<T>(path: string): Promise<T> {
        return this.deleteWithResponse<T>(path, null as ClientOptions).then(extractResponseData);
    }
    upload<T>(path: string, data: FormData): Promise<HttpResponse<T>> {
        const url = this.#url(path);
        const jwtToken = tokenFromLocalStorage();
        return handleHttp<T>(() => fetch(url, {
            method: 'POST',
            body: data,
            headers: { Authorization: `Bearer ${jwtToken}` },
        }));
    }
    download(path: string): Promise<Blob> {
        const url = this.#url(path);
        const jwtToken = tokenFromLocalStorage();
        return fetch(url, {
            method: 'GET',
            headers: { Authorization: `Bearer ${jwtToken}` },
        }).then(r => {
            if (r.ok) return r.blob();
            else throw Error(`failed to download ${r.status}`);
        });
    }

    // unify error handling in the catch when needed
    handleError<T = void>(errReponse: unknown): Promise<T> {
        return extractErrorMessage(errReponse).then((error: Error) => Promise.reject(error));
    }
}

// react inject variables starting with REACT_APP_ to client side
let baseUrl = (process.env.API_HOST as string) || (process.env.REACT_APP_API_HOST as string) || 'https://peregin.com';
const apiClient = new ApiClient(baseUrl);
console.info(`API host: ${apiClient.baseUrl}`);

export default apiClient;
