import { Button, Center, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, SimpleGrid, useDisclosure } from "@chakra-ui/react"
import { useEffect, useState } from "react";

interface ColorPickerProps {
    value?: string;
    onChange: (string) => void;
    isReadOnly: boolean;
}

const ColorPicker = (props: ColorPickerProps) => {

    const [color, setColor] = useState(props.value ?? '#FFFFFF');
    const { onOpen, onClose, isOpen } = useDisclosure()
    useEffect(() => {
        const on = props.value;
        console.log(`color is ${on}`);
        setColor(on);
    }, [isOpen, props.value]);

    const colors = [
        '#4D4D4D', '#999999', '#F0F0F0', '#F44E3B', '#FE9200', '#FCDC00',
        '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF',
        '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400',
        '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF',
        '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00',
        '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E',
    ];

    return (
        <Popover
            variant="picker"
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            placement='auto-end'
            closeOnBlur={true}
        >
            <PopoverTrigger>
                <Button
                    aria-label={color}
                    background={color}
                    height="22px"
                    width="22px"
                    padding={0}
                    minWidth="unset"
                    borderRadius={3}
                    isDisabled={props.isReadOnly}
                ></Button>
            </PopoverTrigger>
            <PopoverContent width="330px">
                <PopoverArrow bg={color} />
                <PopoverCloseButton color="white" />
                <PopoverHeader
                    height="100px"
                    backgroundColor={color}
                    borderTopLeftRadius={5}
                    borderTopRightRadius={5}
                    color="white"
                >
                    <Center height="100%"></Center>
                </PopoverHeader>
                <PopoverBody height="100px">
                    <SimpleGrid columns={12} spacing={2}>
                        {colors.map((c) => (
                            <Button
                                key={c}
                                aria-label={c}
                                background={c}
                                height="22px"
                                width="22px"
                                padding={0}
                                minWidth="unset"
                                borderRadius={3}
                                _hover={{ background: c }}
                                onClick={() => {
                                    setColor(c);
                                    props.onChange(c);
                                    onClose();
                                }}
                            ></Button>
                        ))}
                    </SimpleGrid>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

export default ColorPicker