import apiClient from './core/ApiClient'
import { TransfersMoveStoreRequest } from 'src/api/TransfersMoveStoreRequest';
import { TransferMove } from 'src/api/TransferMove';
import { TransfersMoveFindResponse } from 'src/api/TransfersMoveFindResponse';
import { TransfersMoveListResponse } from 'src/api/TransfersMoveListResponse';

const root = '/api/funds/transfers/moves';

const add = (entry: TransferMove): Promise<void> => {
    let payload: TransfersMoveStoreRequest = {
        summary: entry
    }
    return apiClient.post<TransfersMoveStoreRequest, void>(root, payload);
}

const update = (expense: TransferMove): Promise<void> => {
    let payload: TransfersMoveStoreRequest = {
        summary: expense
    }
    return apiClient.put<TransfersMoveStoreRequest, void>(root, payload);
}

const remove = (id: number): Promise<void> => apiClient.delete<void>(`${root}/${id}`);

const find = (id: number): Promise<TransferMove> => apiClient.get<TransfersMoveFindResponse>(`${root}/${id}`).then(r => r.entry);

const listAll = (): Promise<TransfersMoveListResponse> => apiClient.get<TransfersMoveListResponse>(root);

export const transferMoveService = {
    add,
    update,
    remove,
    find,
    listAll,
}