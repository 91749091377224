import { useAuthContext } from "src/auth/AuthContext";
import { transferMoveService } from 'src/service/TransferMoveService';
import { useParams } from "react-router-dom";
import TransfersMoveForm from "./TransfersMoveForm";

const TransferMoveEdit = () => {
    const { user } = useAuthContext();
    const { id } = useParams();
    return <TransfersMoveForm transferMoveId={parseInt(id)} organizationId={user.data.organizationId} onSubmit={(ps) => transferMoveService.update(ps)} header='Übertrag Bearbeiten' />
}

export default TransferMoveEdit