import { BoardsStoreRequest } from 'src/api/BoardsStoreRequest';
import apiClient from './core/ApiClient'
import { Board } from 'src/api/Board';
import { BoardsFindResponse } from 'src/api/BoardsFindResponse';
import { BoardCard } from 'src/api/BoardCard';
import { LanesStoreRequest } from 'src/api/LanesStoreRequest';
import { LanesFindResponse } from 'src/api/LanesFindResponse';

const root = '/api/boards';

const update = (board: Board): Promise<void> => {
    let payload: BoardsStoreRequest = {
        board: board
    }
    return apiClient.put<BoardsStoreRequest, void>(root, payload);
}

const find = (name: string): Promise<Board> => apiClient.get<BoardsFindResponse>(`${root}/${name}`).then(r => r.board);

const findLane = (name: string): Promise<BoardCard[]> => apiClient.get<LanesFindResponse>(`${root}/lanes/${name}`).then(r => r.lane.concat([]));

const archive = (card: BoardCard): Promise<void> => {
    let payload: LanesStoreRequest = {
        card: card
    };
    return apiClient.post<LanesStoreRequest, void>(`${root}/lanes/archive`, payload);
}

export const boardService = {
    update,
    find,
    findLane,
    archive,
}