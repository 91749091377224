import apiClient from './core/ApiClient'
import { Expense } from 'src/api/Expense';
import { ExpensesFindResponse } from 'src/api/ExpensesFindResponse';
import { ExpensesListResponse } from 'src/api/ExpensesListResponse';
import { ExpensesStoreRequest } from 'src/api/ExpensesStoreRequest';

const root = '/api/expenses';

const add = (expense: Expense): Promise<void> => {
    let payload: ExpensesStoreRequest = {
        summary: expense
    }
    return apiClient.post<ExpensesStoreRequest, void>(root, payload);
}

const update = (expense: Expense): Promise<void> => {
    let payload: ExpensesStoreRequest = {
        summary: expense
    }
    return apiClient.put<ExpensesStoreRequest, void>(root, payload);
}

const remove = (id: number): Promise<void> => apiClient.delete<void>(`${root}/${id}`);

const find = (id: number): Promise<Expense> => apiClient.get<ExpensesFindResponse>(`${root}/${id}`).then(r => r.expense);

const listAll = (): Promise<ExpensesListResponse> => apiClient.get<ExpensesListResponse>(root);

export const expenseService = {
    add,
    update,
    remove,
    find,
    listAll,
}