import { Flex, Text, Box, Progress, useColorModeValue } from "@chakra-ui/react";
import { AmountUtil } from "src/data/AmountUtil";
import { motion } from "framer-motion";
import ProgressBar from "@ramonak/react-progress-bar";

const normalize = (value: number): number => Math.min(100, Math.max(0, value * 100));
const color = (percent: number, chakra: boolean = true): string => {
    let colour = chakra ? 'red' : 'tomato'
    if (percent > 80) {
        colour = chakra ? 'whatsapp' : 'limegreen'
    } else if (percent > 60) {
        colour = chakra ? 'teal' : 'gold'
    } else if (percent > 40) {
        colour = chakra ? 'telegram' : 'sandybrown'
    } else if (percent > 20) {
        colour = 'salmon'
    }
    return colour;
}

const ProgressBarChakra = (value: number, fontWeight: string = 'normal') => {
    let percent = normalize(value);
    return (
        <Flex direction='column'>
            <Text fontSize='sm'
                fontWeight={fontWeight}
                pb='.2rem' textAlign='right'>{AmountUtil.format(value * 100, 0)}%</Text>
            <Box as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                initial={{ scaleX: 0, x: '-6.4vw' }}
                animate={{ scaleX: 1, x: 0 }}
                transition='0.3s linear'
            >
                <Progress borderRadius='15px' shadow={'md'} value={percent} colorScheme={color(percent)} />
            </Box>
        </Flex>
    )
}

export const ProgressBarReact = (value: number, fontWeight: string = 'normal') => {
    const percent = normalize(value);
    const labelColor = useColorModeValue('black', 'white');
    return (<ProgressBar completed={percent.toFixed(0)}
        transitionDuration='0.7s'
        animateOnRender
        bgColor={color(percent, false)}
        labelAlignment='outside'
        labelColor={labelColor}
        customLabel={`${(100 * value).toFixed(0)}%`}
        customLabelStyles={{ fontSize: '.9rem', fontWeight: fontWeight }}
    />)
}

export default ProgressBarChakra;