import { useNavigate } from "react-router-dom";
import {
    Box,
    Flex,
    Avatar,
    HStack,
    Link,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    useColorModeValue,
    useColorMode,
    Stack,
    AvatarBadge,
    Badge,
    Drawer,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    DrawerContent,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Icon,
    Image,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { BiExit } from 'react-icons/bi';
import Logo from './Logo'
import { useAuthContext } from "src/auth/AuthContext";
import WebUser from "src/auth/WebUser";
import apiClient from "src/service/core/ApiClient";
import { IconType } from "react-icons";
import { FcBarChart, FcCollaboration, FcComboChart, FcDoughnutChart, FcInfo, FcInspection, FcList, FcPieChart, FcVoicePresentation } from "react-icons/fc";
import { OrgUtil } from "src/data/OrgUtil";
import { PathUtil } from "src/data/PathUtil";
import { Admin } from "src/api/Admin";

// defines a menu as a tree structure, shown as multilevel navbar for desktop and mobile screens
interface MenuNode {
    name: string,
    link?: string,
    nodes?: Array<MenuNode>,
    icon?: IconType
}
const memberNodes: Array<MenuNode> = [
    { name: 'Startseite', link: '/' },
    {
        name: 'Fundraising', nodes: [
            { name: 'Fundraising Übersicht', link: '/fundraising/', icon: FcComboChart },
            { name: 'Fundraising Pro Projekt', link: '/fundraising/projects/', icon: FcBarChart },
            { name: 'Zusagen', link: '/fundraising/commitments/' },
            { name: 'Eigenmittel Übertrag Partner', link: '/transfers/moves/' },
        ]
    },
    {
        name: 'Projektverwaltung', nodes: [
            { name: 'Projekte Übersicht', link: '/projects/overview/', icon: FcDoughnutChart },
            { name: 'Ansicht Pro Projekt', link: '/projects/overview/projects/', icon: FcBarChart },
            { name: 'Projekte', link: '/projects/' },
            { name: 'Partner', link: '/partners/' },
            { name: 'Konsulenten', link: '/partners/consultants/' },
            { name: 'Ausgaben', link: '/expenses/' },
        ]
    },
    // { name: 'Donors', link: '/donations/donors/' },
    {
        name: 'Finanzverwaltung', nodes: [
            { name: 'Ansicht Projektfonds', link: '/funds/provisions/', icon: FcPieChart },
            { name: 'Ansicht Zuweisung Fonds', link: '/funds/moves/overview/', icon: FcList },
            { name: 'Kostenstellen', link: '/donations/codes' },
            { name: 'Fonds', link: '/funds/' },
            { name: 'Zuweisung Fonds', link: '/funds/moves/' },
            { name: 'Transfers', link: '/transfers/' },
        ]
    },
]

const adminNodes: Array<MenuNode> = [
    {
        name: 'Administration', nodes: [
            { name: 'Benutzer', link: '/admin/users/', icon: FcCollaboration },
        ]
    },
]

const NavLink = ({ label, link, active }) => {
    const activeColor = useColorModeValue('blue.500', 'blue.300');
    const normalColor = useColorModeValue('gray.200', 'gray.700');
    return (
        <Link
            px={2}
            py={2}
            rounded={'md'}
            //borderWidth='1px'
            bg={active ? activeColor : 'transparent'}
            color={active ? 'white' : 'normal'}
            _hover={{
                textDecoration: 'none',
                bg: active ? activeColor : normalColor,
            }}
            href={link}>
            {label}
        </Link>
    )
};

const Header = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colorMode, toggleColorMode } = useColorMode();
    const navigate = useNavigate();
    const { user, logout } = useAuthContext();

    const menuNodes: Array<MenuNode> = OrgUtil.isAllowed(user?.data?.role, Admin) ? [...memberNodes, ...adminNodes] : memberNodes;

    const avatarSize = useBreakpointValue({ base: 'sm', md: 'md' });
    const webUser = user as WebUser | null;
    const avatarName = `${webUser?.data?.firstName} ${webUser?.data?.lastName}`;
    const avatarColor = useColorModeValue('white', 'gray.800');
    const isTest = apiClient.baseUrl.includes('localhost');
    const bgColor = useColorModeValue('gray.100', 'gray.900');

    const handleLogout = () => {
        logout();
        console.info('logged out...');
        navigate('/');
    }

    // without leading and trailing slash - to check if the menu is active
    const path = PathUtil.dropSlashesAndId(window.location.pathname);

    return (
        <Box bg={bgColor} px={4} boxShadow={'lg'} mb={5}>
            <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                {user && (<IconButton
                    size={'md'}
                    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    aria-label={'Menü öffnen'}
                    display={{ md: 'none' }}
                    onClick={isOpen ? onClose : onOpen}
                />)}
                <HStack spacing={8} alignItems={'center'}>
                    <Link href='/' aria-label='Fundraising'>
                        {OrgUtil.isDemo() && (<Box w='165px'><Image src='/images/org/demo/fr-simple.webp' alt='Fundraising' pos='absolute' maxW='220' transform={'translate(-25px, -35px)'}/></Box>)}
                        {!OrgUtil.isDemo() && (<Logo boxSize={36} />)}
                    </Link>
                    {isTest && (<Badge colorScheme='red' variant='outline'>Test</Badge>)}
                    {/* navbar menu, only from MD size */}
                    {user && (
                        <HStack
                            as={'nav'}
                            spacing={1}
                            display={{ base: 'none', md: 'flex' }}>

                            {menuNodes.map((item) => {
                                // is link active?
                                const active = PathUtil.dropSlashesAndId(item.link ?? 'x') === path || item.nodes?.map(n => PathUtil.dropSlashesAndId(n.link ?? '')).includes(path);
                                //console.log(`active=${active}, path=${path}`);
                                return (
                                    item.link ?
                                        <NavLink key={item.name} label={item.name} link={item.link} active={active} />
                                        :
                                        <Menu key={item.name}>
                                            <MenuButton
                                                as={Button}
                                                colorScheme={active ? 'blue' : 'gray'}
                                                boxShadow={active ? 'lg' : 'none'}
                                                rightIcon={<ChevronDownIcon />}
                                                cursor={'pointer'}
                                                minW={'fit-content'}
                                                borderWidth='1px'
                                            >{item.name}</MenuButton>
                                            <MenuList>
                                                {item.nodes?.map((subitem) => (
                                                    <MenuItem key={subitem.name}
                                                        icon={subitem.icon && <Icon boxSize={'6'} as={subitem.icon} />}
                                                        iconSpacing={1}
                                                        as='a'
                                                        rounded={'md'}
                                                        {...(path === PathUtil.dropSlashesAndId(subitem.link ?? '') && { background: 'blue.700', color: 'white' })}
                                                        href={subitem.link}>{subitem.name}</MenuItem>
                                                ))}
                                            </MenuList>
                                        </Menu>
                                )
                            }
                            )}

                        </HStack>
                    )}
                </HStack>
                <Flex alignItems={'center'}>
                    {/* profile menu */}
                    {user ? (
                        <Menu>
                            <MenuButton
                                as={Button}
                                rightIcon={<ChevronDownIcon />}
                                rounded={'full'}
                                cursor={'pointer'}
                                colorScheme={['profile', 'info', 'dev'].includes(path) ? 'blue' : 'gray'}
                                minW={0}>
                                <Avatar
                                    size={avatarSize}
                                    color={avatarColor}
                                    bg={isTest ? 'red.500' : 'blue.500'}
                                    showBorder={true}
                                    name={avatarName}>
                                    <AvatarBadge boxSize='1em' bg='green.500' />
                                </Avatar>
                            </MenuButton>
                            <MenuList>
                                <MenuItem {...(path === 'profile') && { background: 'blue.700', color: 'white' }}
                                    icon={<FcVoicePresentation />} command='⌘P' as='a' href='/profile/'>Profil</MenuItem>
                                <MenuItem {...(path === 'info') && { background: 'blue.700', color: 'white' }}
                                    icon={<FcInfo />} command='⌘I' as='a' href='/info/'>Info</MenuItem>
                                <MenuItem {...(path === 'dev') && { background: 'blue.700', color: 'white' }}
                                    icon={<FcInspection />} command='⌘E' as='a' href='/dev/'>Entwicklung</MenuItem>
                                <MenuDivider />
                                <MenuItem icon={<BiExit />} command='⌘Q' as='a' onClick={() => handleLogout()}>Abmelden</MenuItem>
                            </MenuList>
                        </Menu>
                    ) : (
                        <Button colorScheme="blue" size="sm" rounded="md" onClick={() => navigate('/login/')}>Anmelden</Button>
                    )}

                    <Button onClick={toggleColorMode} aria-label='Mode'>
                        {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                    </Button>
                </Flex>
            </Flex>

            {/* hamburger menu */}
            <Drawer placement='left' onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader bg={bgColor} borderBottomWidth={'1px'} display={'flex'} alignItems={'center'}>
                        &nbsp;
                        <Flex pos="absolute">
                            <Logo boxSize={36} />
                        </Flex>
                    </DrawerHeader>

                    <DrawerBody p={0}>
                        <Accordion allowToggle>
                            {menuNodes.map((item) => {
                                // is link active?
                                const active = PathUtil.dropSlashesAndId(item.link ?? 'x') === path || item.nodes?.map(n => PathUtil.dropSlashesAndId(n.link ?? '')).includes(path);
                                return (
                                    item.link ?
                                        <Stack key={`ms-${item.name}`} as={'nav'} spacing={4} my='0.2rem'>
                                            <NavLink key={`ml-${item.name}`} label={item.name} link={item.link} active={PathUtil.dropSlashesAndId(item.link) === path} />
                                        </Stack>
                                        :
                                        <AccordionItem key={`m-${item.name}`}>
                                            <AccordionButton {...(active && { background: 'blue.700', color: 'white' })}>
                                                <Box as="span" flex='1' textAlign='left'>
                                                    {item.name}
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                            <AccordionPanel pb={4} ml='1rem'>
                                                {item.nodes?.map((subitem) => {
                                                    const active = PathUtil.dropSlashesAndId(subitem.link) === path
                                                    return (
                                                        <HStack key={`msa-${subitem.name}`} as={'nav'} spacing={4} my='0.2rem' rounded='md' {...(active && { background: 'blue.500', color: 'white' })}>
                                                            <NavLink key={`mla-${subitem.name}`} label={subitem.name} link={subitem.link} active={active} />
                                                            {subitem.icon && <Icon boxSize={'5'} as={subitem.icon} />}
                                                        </HStack>
                                                    )
                                                })}
                                            </AccordionPanel>
                                        </AccordionItem>
                                )
                            })}
                        </Accordion>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    );
}

export default Header