import ProjectsForm from './ProjectsForm'
import { projectService } from 'src/service/ProjectService';
import { useAuthContext } from 'src/auth/AuthContext';
import { useParams } from 'react-router-dom';

const ProjectsEdit = () => {
    const { user } = useAuthContext();
    const { id } = useParams();

    return <ProjectsForm projectId={parseInt(id)} organizationId={user.data.organizationId} onSubmit = {(project) => projectService.update(project)} header='Projekt Bearbeiten'/>
}

export default ProjectsEdit