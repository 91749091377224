import { Divider, Flex, Skeleton, Spinner, Stack, Stat, StatGroup, StatHelpText, StatLabel, StatNumber, Text, useDisclosure } from "@chakra-ui/react";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { FundraisingYear } from "src/api/FundraisingYear";
import Content from "src/components/Content";
import {
    Select as ReactSelect
} from "chakra-react-select";
import DataTable, { amountHeader, amountText } from "src/components/DataTable";
import { ProgressBarReact } from "src/components/ProgressBar";
import { AmountUtil } from "src/data/AmountUtil";
import { reportService } from "src/service/ReportService";
import { projectService } from 'src/service/ProjectService';
import { LabelAndValueOption } from "src/data/LabelAndValueOption";
import DashboardFundraisingDialog from "./DashboardFundraisingDialog";
import { MoneyUtil } from "src/data/MoneyUtil";
import { FundraisingCoverage } from "src/api/FundraisingCoverage";
import { Project } from "src/api/Project";
import { DateSelectInfo } from "src/components/DateSelect";

const DashboardFundraisingProject = () => {
    const { id } = useParams();
    //console.log(`project id=${id}`);

    const [projectId, setProjectId] = useState<number | null>(id ? parseInt(id) : null);
    const [year, setYear] = useState<number | null>(null); // used to know the year in the details dialog
    const [instituteOnly, setInstituteOnly] = useState<boolean>(false); // used to know the column in the details dialog (institute only)
    const [projectOptions, setProjectOptions] = useState<LabelAndValueOption[]>([]);
    const [data, setData] = useState<FundraisingYear[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentYearCoverage, setCurrentYearCoverage] = useState<FundraisingCoverage | null>(null); // of current year
    const [overallCoverage, setOverallCoverage] = useState<FundraisingCoverage | null>(null); // of overall
    const [project, setProject] = useState<Project | null>(null); // project details with start and eventually end date

    const currentYear = new Date().getFullYear();

    const { isOpen: isEntriesOpen, onOpen: onEntriesOpen, onClose: onEntriesClose } = useDisclosure();

    const FundraisingTabs = ({ children }) => (
        <Flex pt='1rem' justify='left' direction={{ base: 'column', sm: 'row' }} mb='3rem'>
            <StatGroup shadow={'md'} border={'1px solid lightgray'} rounded={'md'} textAlign='right'>
                <Stat px='1rem' key='b1' borderRight='1px solid lightgray'>
                    <StatLabel>Jahresdeckung</StatLabel>
                    <Divider orientation='horizontal' />
                    <StatNumber whiteSpace={'nowrap'}>{MoneyUtil.format({ value: currentYearCoverage?.coverageTotal ?? 0, currency: 'CHF' })}</StatNumber>
                    {ProgressBarReact(currentYearCoverage?.coverage ?? 0)}
                    <StatHelpText fontSize='xs'>Jahresdeckung für {currentYear}</StatHelpText>
                </Stat>
                <Stat px='1rem' key='b2' borderRight='1px solid lightgray'>
                    <StatLabel>Gesamtdeckung</StatLabel>
                    <Divider orientation='horizontal' />
                    <StatNumber whiteSpace={'nowrap'}>{MoneyUtil.format({ value: overallCoverage?.overallOpenTotal ?? 0, currency: 'CHF' })}</StatNumber>
                    {ProgressBarReact(overallCoverage?.overallCoverage ?? 0)}
                    <StatHelpText fontSize='xs'>Gesamtdeckung</StatHelpText>
                </Stat>
                {children}
            </StatGroup>
        </Flex>
    );

    const loadProjectData = (projectId: number): Promise<void> => {
        setIsLoading(true);
        return reportService.fundraisingProject(projectId).then(r => {
            let total: FundraisingYear = {
                key: {
                    projectId: projectId,
                    year: undefined
                },
                balance: r.overall.balance,
                coverage: r.overall.coverage
            }
            // overall aggregations with every year
            setData(r.results.concat(total));
            setCurrentYearCoverage(r.results.find(a => a.key.year === currentYear)?.coverage);
            setOverallCoverage(r.overall.coverage);
            setProject(r.project);
        }).finally(() => setIsLoading(false));
    }

    useEffect(() => {
        projectService.listFundraisingNames().then(ps => {
            let ops = ps.map(p => {
                let n: LabelAndValueOption = {
                    label: p.name.trim(),
                    value: p.id
                }
                return n;
            });
            ops.sort((a, b) => a.label.localeCompare(b.label));
            setProjectOptions(ops);
        });
    }, []);

    useEffect(() => {
        if (projectId) {
            loadProjectData(projectId);
        }
    }, [projectId])

    const amountCell2 = () => (info: CellContext<FundraisingYear, number>) => amountText(AmountUtil.format(info.getValue()), '7rem', info.row.original.key.year ? 'normal' : 'extrabold')

    const amountCellRedIfLate = () => (info: CellContext<FundraisingYear, number>) => {
        let v = info.getValue();
        if (info.row.original.key.year < currentYear && v > 0) {
            return amountText(AmountUtil.format(v), '7rem', 'bold', 'red.500');
        }
        return amountText(AmountUtil.format(info.getValue()), '7rem', info.row.original.key.year ? 'normal' : 'extrabold')
    }

    const columns: ColumnDef<FundraisingYear>[] = useMemo(
        () => [
            {
                id: 'year',
                header: 'Mitteljahr',
                accessorFn: (row: FundraisingYear) => row.key.year,
                cell: (info: CellContext<FundraisingYear, number>) => {
                    let year = info.getValue();
                    return (<Text width='3rem' fontWeight='bold'>{year ?? 'Total'}</Text>)
                },
            },
            {
                id: 'budget',
                header: amountHeader({ name: 'Budget', fontWeight: 'normal', width: '7rem', casing: 'none', fontSize: 'xs' }),
                accessorKey: 'balance.budgetTotal',
                cell: amountCell2(),
                enableSorting: false,
            },
            {
                id: 'institute', // click checked on this id, in this case the details dialog shows the institues only
                header: amountHeader({ name: 'Beiträge', fontWeight: 'normal', width: '7rem', casing: 'none', fontSize: 'xs', explanation: 'Institutionelle Beiträge' }),
                accessorKey: 'balance.instituteTotal',
                cell: amountCell2(),
                enableSorting: false,
            },
            {
                id: 'commitment',
                header: amountHeader({ name: 'Zusagen', fontWeight: 'normal', width: '7rem', casing: 'none', fontSize: 'xs' }),
                accessorKey: 'balance.commitmentTotal',
                cell: amountCellRedIfLate(),
                enableSorting: false,
            },
            {
                id: 'nonInstitute',
                header: amountHeader({ name: 'Eigenmittel', fontWeight: 'normal', width: '7rem', casing: 'none', fontSize: 'xs', explanation: 'Einzelspenden + Patenschaften + Übertrag Eigenmittel CO-OPERAID + Übertrag Eigenmittel beim Partner' }),
                // Eigenmittel = Eigenmittel + Startcapital + Ubertrag
                accessorFn: (row: FundraisingYear) => row.balance.nonInstituteTotal + row.balance.fundMoveTotal + row.balance.transferMoveTotal,
                cell: amountCell2(),
                enableSorting: false,
            },
            // {
            //     id: 'fundMove',
            //     header: amountHeader({ name: 'Startkapital', fontWeight: 'normal', width: '7rem', casing: 'none', fontSize: 'xs' }),
            //     accessorKey: 'balance.fundMoveTotal',
            //     cell: amountCell2(),
            //     enableSorting: false,
            // },
            // {
            //     id: 'transferMove',
            //     header: amountHeader({ name: 'Übertrag Partner', fontWeight: 'normal', width: '7rem', casing: 'none', fontSize: 'xs' }),
            //     accessorKey: 'balance.transferMoveTotal',
            //     cell: amountCell2(),
            //     enableSorting: false,
            // },
            {
                id: 'income',
                header: amountHeader({ name: 'Total Mittel', fontWeight: 'normal', width: '7rem', casing: 'none', fontSize: 'xs', explanation: 'Beiträge + Zusagen + Eigenmittel' }),
                accessorKey: 'coverage.incomeTotal',
                cell: amountCell2(),
                enableSorting: false,
            },
            {
                id: 'coverage',
                header: amountHeader({ name: 'Jahresdeckung', fontWeight: 'normal', width: '7rem', casing: 'none', fontSize: 'xs', explanation: 'Jahresdeckung = Jahresbudget - Total Mittel Laufjahr' }),
                accessorKey: 'coverage.coverageTotal',
                cell: amountCell2(),
                enableSorting: false,
            },
            {
                id: 'coveragePercent',
                header: amountHeader({ name: 'Jahresdeckung%', fontWeight: 'normal', width: '7rem', casing: 'none', fontSize: 'xs', explanation: 'Jahresdeckung % = Total Mittel Laufjahr / Fundraising Budget Laufjahr' }),
                accessorKey: 'coverage.coverage',
                cell: (info: CellContext<FundraisingYear, number>) => ProgressBarReact(info.getValue(), info.row.original.key.year ? 'normal' : 'bold'),
                enableSorting: false,
            },
            {
                id: 'open',
                header: amountHeader({ name: 'Offener Betrag', fontWeight: 'normal', width: '8rem', casing: 'none', fontSize: 'xs', explanation: 'Offener Betrag = Gesamt Fundraising Budget - Total Mittel Laufjahr' }),
                accessorKey: 'coverage.overallOpenTotal',
                cell: amountCell2(),
                enableSorting: false,
            },
            {
                id: 'overallCoveragePercent',
                header: amountHeader({ name: 'Gesamtdeckung%', fontWeight: 'normal', width: '7rem', casing: 'none', fontSize: 'xs', explanation: 'Gesamtdeckung % = Total Mittel / Gesamt Fundraising Budget %' }),
                accessorKey: 'coverage.overallCoverage',
                cell: (info: CellContext<FundraisingYear, number>) => ProgressBarReact(info.getValue(), info.row.original.key.year ? 'normal' : 'bold'),
                enableSorting: false,
            },
        ], []
    );

    return (
        <Content caption='Fundraising Projekt'>

            <Stack w={'md'} py={6}>
                <ReactSelect
                    isSearchable
                    isRequired
                    required
                    isDisabled={false}
                    autoFocus={false}
                    name='project-select'
                    placeholder='Projekt'
                    options={projectOptions}
                    onChange={val => setProjectId(val.value)}
                    value={projectOptions.find(c => c.value === projectId)}
                />
            </Stack>

            <FundraisingTabs>
                <Stat px='1rem' key='b3' borderRight='1px solid lightgray'>
                    <StatLabel>Anfangsdatum</StatLabel>
                    <Divider orientation='horizontal' mb='1rem'/>
                    <DateSelectInfo date={project?.startDate} />
                    <StatHelpText fontSize='xs'>Projektanfang</StatHelpText>
                </Stat>
                <Stat px='1rem' key='b4'>
                    <StatLabel>Enddatum</StatLabel>
                    <Divider orientation='horizontal' mb='1rem'/>
                    <DateSelectInfo date={project?.endDate} />
                    <StatHelpText fontSize='xs'>Leer für laufendes Projekt</StatHelpText>
                </Stat>
            </FundraisingTabs>

            {isLoading ? (
                <Stack py={5}>
                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <>
                    <DataTable columns={columns} data={data} initialSortByColumn='year' initialSortByDesc={false}
                        hideColumnsOnXs={['institute', 'nonInstitute', 'commitment', 'fundMove', 'transferMove', 'income', 'coverage', 'coveragePercent', 'open']}
                        onRowClick={(row, columnId) => {
                            setYear(row.key.year);
                            setInstituteOnly(columnId === 'institute');
                            console.log(`selected year is ${row.key.year}`);
                            onEntriesOpen();
                        }}
                    />
                </>
            )}
            <DashboardFundraisingDialog projectId={projectId} projectName={projectOptions.find(c => c.value === projectId)?.label}
                projectYear={year}
                instituteOnly={instituteOnly}
                isOpen={isEntriesOpen} onClose={onEntriesClose} />
        </Content>
    );
}

export default DashboardFundraisingProject