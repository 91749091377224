import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, Textarea, ModalFooter, Button, Flex, Tag, TagLabel, FormErrorMessage } from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { CustomDateInput } from 'src/components/DateSelect';
import { Controller, useForm } from "react-hook-form";
import { BoardCard } from "src/api/BoardCard";
import { useEffect, useState } from "react";
import ErrorMessage from "src/components/ErrorMessage";
import { dueFormat, formatDue, parseDue } from "src/data/DateUtil";
import { BoardTag } from "src/api/BoardTag";


export interface CardDialogProps {
    data: BoardCard;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (b: BoardCard) => Promise<void>;
}

const tagOptions = [
    { value: "feature", label: "Feature", colorScheme: "green" },
    { value: "improvement", label: "Improvement", colorScheme: "orange" },
    { value: "bug", label: "Bug", colorScheme: "red" },
    { value: "todo", label: "To Do", colorScheme: "blue" },
    { value: "tech", label: "Tech", colorScheme: "purple" },
    // { value: "purple", label: "Purple", colorScheme: "cyan" },
    // { value: "yellow", label: "Yellow", colorScheme: "yellow" },
];

const customOptionItem = (props) => (
    <chakraComponents.Option label={props.data.name} {...props}>
        <Flex align="center">
            <Tag size='md' key={props.data.value} variant='subtle' colorScheme={props.data.colorScheme} fontFamily='Verdana' fontSize='xs' dropShadow='3px'>
                <TagLabel>{props.data.label}</TagLabel>
            </Tag>
        </Flex>
    </chakraComponents.Option>
);

const CardDialog = ({ data, isOpen, onClose, onConfirm }: CardDialogProps) => {

    const [error, setError] = useState('');

    const createDefault = (): BoardCard => {
        return {
            id: crypto.randomUUID(),
            title: '',
            description: null,
            label: null,
            tags: [],
        }
    }

    const { control, register, handleSubmit, reset, formState: { isSubmitting } } = useForm<BoardCard>({
        defaultValues: createDefault()
    });

    const onSubmitInternal =
        async (p: BoardCard): Promise<void> => {
            try {
                console.info(`submitting: ${JSON.stringify(p)}`);
                return await onConfirm(p).then(() => {
                    reset();
                    onClose()
                });
            } catch (err) {
                setError('Fehler');
            }
        };

    const safeParseDate = (s: string): Date => {
        if (s && s.length > 0) {
            try {
                return parseDue(s);
            } catch (e) {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    const safeFormatDue = (d: Date): string => {
        try {
            return formatDue(d);
        } catch (e) {
            return null;
        }
    }


    // update the state got from the props at every open
    useEffect(() => {
        console.info(`resetting form to ${JSON.stringify(data)}`)
        data ? reset(data) : reset(createDefault());
    }, [data, reset, isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size='3xl'
            motionPreset='scale'
            scrollBehavior='outside'
        >
            <form onSubmit={handleSubmit(onSubmitInternal)}>
                <ModalOverlay
                    bg='blackAlpha.500'
                    backdropFilter='blur(10px)'
                />
                <ModalContent>
                    <ModalHeader>{data ? 'Update' : 'Create'} a task</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        {error && <ErrorMessage message={error} />}
                        <FormControl isRequired>
                            <FormLabel>Task to do</FormLabel>
                            <Input autoFocus={true} placeholder='To Do' {...register('title')} />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Description</FormLabel>
                            <Textarea placeholder='More details ...' {...register('description')} height='12rem' />
                        </FormControl>

                        <Controller control={control} name='tags'
                            render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error } }) => (
                                <FormControl>
                                    <FormLabel>Tag</FormLabel>
                                    <Select
                                        isMulti
                                        options={tagOptions}
                                        placeholder="Select tags..."
                                        closeMenuOnSelect={false}
                                        components={{ Option: customOptionItem }}
                                        ref={ref}
                                        name={name}
                                        onBlur={onBlur}
                                        onChange={val => {
                                            let ops = Array.from(val.values()).map(v => {
                                                //console.log(`v=${JSON.stringify(v)}`);
                                                let tag: BoardTag = {
                                                    title: v.label,
                                                    bgcolor: v.colorScheme,
                                                    color: 'white'
                                                }
                                                return tag;
                                            });
                                            onChange(ops);
                                        }}
                                        value={tagOptions.filter(c => value.find(t => t.title === c.label))}
                                    />
                                    <FormErrorMessage>{error?.message}</FormErrorMessage>
                                </FormControl>
                            )} />

                        <Controller control={control} name='label' render={({ field }) => (
                            <FormControl>
                                <FormLabel>Due</FormLabel>
                                <DatePicker
                                    selected={safeParseDate(field.value)}
                                    onChange={(date) => {
                                        //console.log(`date value ${date}`);
                                        let label = date ? safeFormatDue(date) : null;
                                        field.onChange(label);
                                    }}
                                    showTimeSelect
                                    timeIntervals={60}
                                    dateFormat={dueFormat}
                                    isClearable
                                    customInput={<CustomDateInput />}
                                    placeholderText='Set due date if available'
                                />
                            </FormControl>
                        )} />
                    </ModalBody>

                    <ModalFooter>
                        <Button isLoading={isSubmitting} type='submit' colorScheme='blue' mr={3}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    )
}

export default CardDialog