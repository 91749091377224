import { Box, Button, Flex, FormControl, FormLabel, Heading, Stack, useColorModeValue, InputGroup, InputLeftElement, Textarea, FormErrorMessage } from "@chakra-ui/react"
import ErrorMessage from "src/components/ErrorMessage";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Commitment } from 'src/api/Commitment';
import DateSelectController from 'src/components/DateSelect';
import { BiWallet } from "react-icons/bi";
import {
    CreatableSelect,
    OptionBase,
    Select as ReactSelect
} from "chakra-react-select";
import { CustomAmountInput } from 'src/components/AmountInput';
import { projectService } from 'src/service/ProjectService';
import { commitmentService } from "src/service/CommitmentService";
import { AmountUtil } from "src/data/AmountUtil";

export interface CommitmentsFormProps {
    commitmentId?: number;
    organizationId: number;
    onSubmit: (ps: Commitment) => Promise<void>;
    viewOnly?: boolean;
    header: string;
}

export interface ProjectOption extends OptionBase {
    label: string;
    value: number;
}

export interface InstitutionOption extends OptionBase {
    label: string;
    value: string;
}

const CommitmentsForm = ({ commitmentId, organizationId, onSubmit, viewOnly, header }: CommitmentsFormProps): JSX.Element => {
    const { control, register, handleSubmit, reset, formState: { isSubmitting } } = useForm<Commitment>({
        defaultValues: {
            id: commitmentId,
            projectId: -1,
            fundSourceName: '',
            amount: 0,
            entryDate: new Date(),
            comments: '',
            organizationId: organizationId
        }
    });
    const [serverError, setServerError] = useState('');
    const [projectNames, setProjectNames] = useState<ProjectOption[]>([]);
    const [institutions, setInstitutions] = useState<InstitutionOption[]>([]); // unique names for institutes
    const navigate = useNavigate();

    useEffect(() => {
        if (commitmentId > -1) {
            commitmentService.find(commitmentId).then(p => reset(p));
        }
    }, [commitmentId, reset]);

    useEffect(() => {
        let a = projectService.listNames().then(ps => {
            let ops = ps.map(p => {
                let n: ProjectOption = {
                    label: p.name,
                    value: p.id
                }
                return n;
            });
            setProjectNames(ops);
        });
        let b = commitmentService.fundSourceNames().then(ns => {
            let ops = ns.map(n => {
                let o: InstitutionOption = {
                    label: n,
                    value: n
                }
                return o;
            });
            setInstitutions(ops);
        });
        Promise.all([a, b]);
    }, []);

    const readOnly = viewOnly ?? false;
    console.info(`form is readonly: ${readOnly}`);

    const onSubmitInternal = (c: Commitment): Promise<void> => {
        console.info(`submitting ${JSON.stringify(c)}`);
        return onSubmit(c)
            .then(() => navigate('/fundraising/commitments'))
            .catch(_err => setServerError('Fehler'));
    };

    return (
        <Flex
            minH={{ base: '50vh', lg: '70vh' }}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.25', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <form onSubmit={handleSubmit(onSubmitInternal)}>
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'} textAlign={'center'}>{header}</Heading>
                    </Stack>
                    <Box
                        rounded={'lg'}
                        bg={useColorModeValue('gray.25', 'gray.700')}
                        boxShadow={'lg'}
                        p={8}>
                        <Stack spacing={4}>
                            {serverError && <ErrorMessage message={serverError} />}

                            <Box>
                                <Controller control={control} name='projectId'
                                    rules={{ required: "Projekt auswählen", validate: (value) => value > -1 }}
                                    render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error, invalid } }) => (
                                        <FormControl id="projectId" isRequired isReadOnly={readOnly} isInvalid={invalid}>
                                            <FormLabel>Projekt</FormLabel>
                                            <ReactSelect
                                                isSearchable
                                                isRequired
                                                required
                                                isDisabled={readOnly}
                                                autoFocus={true}
                                                ref={ref}
                                                name={name}
                                                onBlur={onBlur}
                                                placeholder='Projekt'
                                                options={projectNames}
                                                onChange={val => onChange(val.value)}
                                                value={projectNames.find(c => c.value === value)}
                                            />
                                            <FormErrorMessage>{error?.message}</FormErrorMessage>
                                        </FormControl>
                                    )} />
                            </Box>

                            <Box>
                                <Controller control={control} name='fundSourceName'
                                    rules={{ required: "Institution auswählen", validate: (value) => value.length > 0 }}
                                    render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error, invalid } }) => (
                                        <FormControl id="fundSourceName" isRequired isInvalid={invalid}>
                                            <FormLabel>Institution (oder neue Institution erstellen)</FormLabel>
                                            <CreatableSelect
                                                isSearchable
                                                isRequired
                                                required
                                                isReadOnly={readOnly}
                                                ref={ref}
                                                name={name}
                                                autoFocus={false}
                                                onBlur={onBlur}
                                                placeholder='Institution'
                                                options={institutions}
                                                onChange={val => onChange(val.value)}
                                                value={institutions.find(c => c.value === value)}
                                                formatCreateLabel={(item) => `Neue Institution Erstellen: ${item}`}
                                            />
                                            <FormErrorMessage>{error?.message}</FormErrorMessage>
                                        </FormControl>
                                    )} />
                            </Box>

                            <Stack direction={{ base: 'column', sm: 'row' }}>
                                <Box>
                                    <Box>
                                        <FormControl id="entryDate" isRequired isReadOnly={readOnly} isDisabled={readOnly}>
                                            <FormLabel>Datum</FormLabel>
                                            <DateSelectController control={control} name='entryDate' clearable={false} />
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box>
                                    <Controller control={control} name='amount'
                                        rules={{ required: "Summe eingeben", validate: (value) => value > 0 || "Summe eingeben" }}
                                        render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error, invalid } }) => {
                                            console.log(error);
                                            return (
                                                <FormControl id="amount" isRequired isInvalid={invalid}>
                                                    <FormLabel>Summe</FormLabel>
                                                    <InputGroup>
                                                        <InputLeftElement pointerEvents='none' children={<BiWallet color='blue' />} />
                                                        <CustomAmountInput value={value} name={name} onChange={v => onChange(AmountUtil.parse(v.target.value))} onBlur={onBlur} isReadOnly={readOnly} ref={ref}/>
                                                    </InputGroup>
                                                    <FormErrorMessage>{error?.message}</FormErrorMessage>
                                                </FormControl>
                                            )
                                        }} />
                                </Box>
                            </Stack>

                            <Box>
                                <FormControl id="comments" isReadOnly={readOnly}>
                                    <FormLabel>Bemerkungen</FormLabel>
                                    <Textarea {...register('comments')} />
                                </FormControl>
                            </Box>

                            {/* submittion */}
                            <Stack spacing={10} pt={2}>
                                <Stack
                                    direction={{ base: 'column', sm: 'row' }}
                                    align={'center'}
                                    justify={'space-between'}
                                    spacing={10}>
                                    <Button isDisabled={isSubmitting} onClick={() => navigate('/fundraising/commitments/')} bg={'red.400'} color={'white'} _hover={{ bg: 'red.500' }}>
                                        Schliessen
                                    </Button>
                                    {!readOnly && (
                                        <Button isLoading={isSubmitting} type='submit' bg={'blue.400'} color={'white'} _hover={{ bg: 'blue.500' }}>
                                            Speichern
                                        </Button>
                                    )}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </form>
            </Stack>
        </Flex>
    );
}

export default CommitmentsForm