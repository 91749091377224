import PartnersForm from './PartnersForm'
import { partnerService } from 'src/service/PartnerService';
import { useAuthContext } from 'src/auth/AuthContext';
import { useParams } from 'react-router-dom';

const PartnersEdit = () => {
    const { user } = useAuthContext();
    const { id } = useParams();

    return <PartnersForm partnerId={parseInt(id)}
        organizationId={user.data.organizationId}
        onSubmit={(partner) => partnerService.update(partner)}
        header='Bearbeiten' />
}

export default PartnersEdit