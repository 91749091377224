/* eslint-disable react/jsx-key */
import { Text, Box, Flex, HStack, Table, TableCaption, Tbody, Td, Th, Thead, Tr, useColorModeValue, useMediaQuery, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, IconButton } from '@chakra-ui/react';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useReactTable, getCoreRowModel, getSortedRowModel, flexRender, ColumnDef, SortingState, GroupingState, getGroupedRowModel, getExpandedRowModel, getFilteredRowModel, FilterFn, CellContext, Row, VisibilityState } from '@tanstack/react-table'
import { FcList, FcTreeStructure } from "react-icons/fc";
import { BiMinusCircle, BiPlusCircle, BiSolidSortAlt, BiSortDown, BiSortUp } from 'react-icons/bi';
import { AmountUtil } from 'src/data/AmountUtil';
import { formatOverview } from 'src/data/DateUtil';
import { Money } from 'src/api/Money';
import { InfoIcon } from '@chakra-ui/icons';
import { useVirtualizer } from '@tanstack/react-virtual'

export const amountHeader = ({ name, width = '10rem', fontWeight = 'bold', fontSize = '.7rem', casing = 'uppercase', explanation = undefined }:
    { name: string, width?: string, fontWeight?: string, fontSize?: string, casing?: 'uppercase' | 'lowercase' | 'none', explanation?: string }) => () => (
        <Flex style={{ width: width }} justify='right' alignItems='center'>
            {explanation && (<Popover trigger='hover'>
                <PopoverTrigger>
                    <IconButton size='xs' color='blue.500' aria-label='weitere Erklärung' icon={<InfoIcon />} />
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader><Text fontWeight='semibold' casing='none'>Erklärung</Text></PopoverHeader>
                    <PopoverBody><Text fontWeight='normal' casing='none'>{explanation}</Text></PopoverBody>
                </PopoverContent>
            </Popover>)}
            <Text casing={casing} fontWeight={fontWeight} fontSize={fontSize} style={{ textAlign: 'right', paddingRight: '.1rem' }}>{name}</Text>
        </Flex>
    )
export const amountCell = <T extends object>({ width = '10rem', fontWeight = 'normal' }: { width?: string, fontWeight?: string }) => (info: CellContext<T, number>) => {
    const text = AmountUtil.format(info.getValue());
    return amountText(text, width, fontWeight);
}
export const amountText = (text: string, width: string, fontWeight: string, fontColor?: string) =>
    <Text cursor='default' width={width} fontWeight={fontWeight} color={fontColor} textAlign='right' pr='0rem'>{text}</Text>

export const moneyCell = <T extends object>(width: string = '10rem') => (info: CellContext<T, Money>) => {
    const money = info.getValue();
    return moneyText(money, width, 'normal');
}
export const moneyText = (money: Money, width: string, fontWeight: string, fontColor?: string) =>
    valueCurrencyText(money.value, money.currency, width, fontWeight, fontColor);
export const valueCurrencyText = (value: number, currency: string, width: string, fontWeight: string, fontColor?: string) =>
    <Text cursor='default' width={width}
        fontWeight={fontWeight} textAlign='right'
        color={fontColor}
        pr='0rem'>{AmountUtil.format(value)} {currency}</Text>

export const moneyListCell = (moneys: ReadonlyArray<Money>, defaultCurrency: string, fontWeight: string = 'normal', width: string = '10rem') => {
    const text = moneys.length === 0 ? `${AmountUtil.format(0)} ${defaultCurrency}` : moneys.map(m => `${AmountUtil.format(m.value)} ${m.currency}`).join(' ');
    return <Text cursor='default' width={width} textAlign='right' pr='0rem' fontWeight={fontWeight}>{text}</Text>
}

export const moneySort = <T extends object>() => (rowA: Row<T>, rowB: Row<T>, columnId: string): number => {
    const a: Money = rowA.getValue(columnId);
    const b: Money = rowB.getValue(columnId);
    return a.value < b.value ? 1 : -1
}
export const dateCell = <T extends object>() => (info: CellContext<T, Date>) => (
    <Text cursor='default' pr='0rem'>{formatOverview(info.getValue())}</Text>
)

export interface DataTableProps<T extends object> {
    caption?: string;
    columns: ColumnDef<T>[];
    data: T[];
    initialSortByColumn?: string | string[];
    initialSortByDesc?: boolean | boolean[];
    initialGroupByColumns?: Array<string>;
    hideColumnsOnXs?: Array<string>;
    onRowClick?: (row: T, columnId: string) => void;
    onFilter?: (row: T, value: string) => boolean;
    filter?: string;
    virtual?: boolean
}

export const DataTable: <T extends object>(
    props: PropsWithChildren<DataTableProps<T>>,
) => React.ReactElement<DataTableProps<T>> = ({
    caption,
    children,
    columns,
    data,
    initialSortByColumn,
    initialSortByDesc,
    initialGroupByColumns,
    hideColumnsOnXs,
    onRowClick,
    onFilter,
    filter,
    virtual,
}) => {
        const sortByDesc: boolean[] = Array.isArray(initialSortByDesc) ? initialSortByDesc : [initialSortByDesc ?? false];
        const initialSorting: SortingState = initialSortByColumn ?
            Array.isArray(initialSortByColumn) ? 
                initialSortByColumn.map((c, ix) => ({ desc: sortByDesc[ix], id: c })) : [{ desc: sortByDesc[0], id: initialSortByColumn }]
            : [];
        const initialGrouping = initialGroupByColumns ?? [];

        const [sorting, setSorting] = useState<SortingState>(initialSorting);
        const [grouping, setGrouping] = useState<GroupingState>(initialGrouping);
        const [globalFilter, setGlobalFilter] = useState(filter);
        const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})

        const globalFilterFn: FilterFn<any> = (row, _columnId, value, _addMeta) => {
            return onFilter?.(row.original, value) ?? true;
        }

        const tableInstance =
            useReactTable({
                columns,
                data,
                state: {
                    sorting,
                    grouping,
                    globalFilter,
                    columnVisibility
                },
                onGlobalFilterChange: setGlobalFilter,
                globalFilterFn: onFilter && globalFilterFn,
                onSortingChange: setSorting,
                getCoreRowModel: getCoreRowModel(),
                getSortedRowModel: getSortedRowModel(),
                onGroupingChange: setGrouping,
                getGroupedRowModel: getGroupedRowModel(),
                getExpandedRowModel: getExpandedRowModel(),
                getFilteredRowModel: getFilteredRowModel(),
                onColumnVisibilityChange: setColumnVisibility,
                debugTable: false,
            });

        // hide specified columns on smaller devices
        const [isSmallScreen] = useMediaQuery('(max-width: 768px)');
        const stripedRowColor = useColorModeValue('gray.200', 'gray.700');

        // ignore click when is on a link or button
        const ignoreClickOn = new Set(['a', 'button', 'input', 'textarea', 'svg', 'path']);
        const handleRowClick = (event, row, columnId: string) => {
            if (!ignoreClickOn.has(event.target.localName)) {
                //console.log(`clicked ${JSON.stringify(row)}`);
                onRowClick?.(row, columnId);
            }
        };

        //we need a reference to the scrolling element for logic down below
        //const tableContainerRef = useRef<HTMLDivElement>(null);
        // TODO: only if virtual is set!!!
        // const virtualizer = useVirtualizer({
        //     count: data.length,
        //     getScrollElement: () => tableContainerRef.current,
        //     estimateSize: () => 34,
        //     overscan: 20,
        // });

        useEffect(() => {
            console.info(`small screen: ${isSmallScreen}`);
            let columns2Hide = hideColumnsOnXs ?? [];
            if (columns2Hide.length > 0) {
                //console.log(`visibility: ${!isSmallScreen} columns: ${columns2Hide}`);
                let items: Record<string, boolean> = {};
                columns2Hide.forEach(c => items[c] = !isSmallScreen);
                setColumnVisibility(items);
                //columns.forEach(c => c.)
            }
            // expand when loaded for the first time
            if (initialGroupByColumns) {
                tableInstance.toggleAllRowsExpanded(true);
            }
        }, [isSmallScreen, hideColumnsOnXs]);

        useEffect(() => {
            setGlobalFilter(filter);
        }, [filter]);

        return (
            <Box mt='0' pt='0' overflow='auto'>
                {children}
                {/* <div ref={tableContainerRef} className="container"> */}
                    {/* <div style={{ height: `${virtualizer.getTotalSize()}px` }}> */}
                        <Table variant='normal' size='sm'>
                            {caption && <TableCaption>{caption}</TableCaption>}
                            <Thead position='sticky' top={0}>
                                {tableInstance.getHeaderGroups().map(headerGroup => (
                                    <Tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => {
                                            const meta: any = header.column.columnDef.meta;
                                            return (
                                                <Th px={1} key={header.id} colSpan={header.colSpan} py={4}
                                                    isNumeric={meta?.isNumeric}
                                                >
                                                    {(header.column.getCanGroup() && initialGroupByColumns) ? (
                                                        // If the column can be grouped, let's add a toggle
                                                        <span onClick={header.column.getToggleGroupingHandler()}
                                                            style={{ cursor: 'pointer' }}>
                                                            {header.column.getIsGrouped() ? <FcList size='2em' /> : <FcTreeStructure size='2em' />}
                                                        </span>
                                                    ) : null}
                                                    <Flex alignItems='center'>
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        {header.column.getCanSort() ?
                                                            <span onClick={header.column.getToggleSortingHandler()}
                                                                style={{ cursor: 'pointer' }}>
                                                                {(header.column.getIsSorted() === 'desc' ? (
                                                                    <BiSortUp color={'blue'} size='1.5em' />
                                                                ) : header.column.getIsSorted() === 'asc' ? (
                                                                    <BiSortDown color={'blue'} size='1.5em' />
                                                                ) : <BiSolidSortAlt color={'gray'} size='1.5em' />
                                                                )}
                                                            </span> : null
                                                        }
                                                    </Flex>
                                                </Th>
                                            )
                                        })}
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {tableInstance.getRowModel().rows.map((row, index) => {
                                {/* {virtualizer.getVirtualItems().map((virtualRow, index) => { */}
                                    // const row = data[virtualRow.index] as Row<object extends TData>;
                                    const isEven = index % 2 === 0;
                                    return (
                                        <Tr
                                            key={row.id}
                                            _hover={onRowClick && { bg: 'blue.500' }}
                                            bg={isEven ? stripedRowColor : ''}
                                        >
                                            {row.getVisibleCells().map((cell) => (
                                                <Td mx={0} px={1}
                                                    key={cell.id}
                                                    // detect background color
                                                    style={{
                                                        background: cell.getIsGrouped() || row.getIsGrouped()
                                                            ? '#b3c8dd'
                                                            : cell.getIsAggregated()
                                                                ? '#b3c8dd'
                                                                : cell.getIsPlaceholder()
                                                                    ? stripedRowColor // when grouped and aggregated the empty space
                                                                    : '', // the regular rows
                                                        color: cell.getIsAggregated() ? 'blue' : ''
                                                    }}
                                                    onClick={onRowClick && ((ev) => {
                                                        // handle click on regular rows only
                                                        let enableRowClick = !cell.getIsAggregated() && !cell.getIsGrouped();
                                                        if (enableRowClick) {
                                                            // console.info(`column=${JSON.stringify(cell.column.id)}`);
                                                            handleRowClick(ev, row.original, cell.column.id);
                                                        }
                                                    }
                                                    )}
                                                >
                                                    {cell.getIsGrouped() ? (
                                                        // If it's a grouped cell, add an expander and row count
                                                        <><HStack>
                                                            <span onClick={row.getToggleExpandedHandler()}
                                                                style={{ cursor: row.getCanExpand() ? 'pointer' : 'normal' }}>
                                                                {row.getIsExpanded() ? <BiMinusCircle size={'2em'} /> : <BiPlusCircle size={'2em'} />}
                                                            </span>{' '}
                                                            <span>
                                                                {flexRender(
                                                                    cell.column.columnDef.cell,
                                                                    cell.getContext()
                                                                )}{' '}
                                                                ({row.subRows.length})
                                                            </span>
                                                        </HStack>
                                                        </>
                                                    ) : cell.getIsAggregated() ? (
                                                        // If the cell is aggregated, use the Aggregated renderer for cell
                                                        flexRender(
                                                            cell.column.columnDef.aggregatedCell ??
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )
                                                    ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                                                        // Otherwise, just render the regular cell
                                                        flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )
                                                    )}
                                                </Td>
                                            ))}
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    {/* </div>
                </div> */}
            </Box>
        );
    };

export default DataTable;
