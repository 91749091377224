import { HStack } from "@chakra-ui/layout";
import { Tag, TagLabel } from "@chakra-ui/tag";
import { BoardTag } from "src/api/BoardTag";

const Tags = ({ tags }: { tags: BoardTag[] }) => (
    <HStack spacing={4}>
        {tags.map(tag => (
            <Tag key={tag.title} variant='solid' colorScheme={tag.bgcolor} textColor={tag.color} fontFamily='Verdana' fontSize='xs' dropShadow='3px'>
                <TagLabel>{tag.title}</TagLabel>
            </Tag>
        ))}
    </HStack>)

export default Tags;
