// Generated by ScalaTS 0.7.0: https://scala-ts.github.io/scala-ts/

declare var exports: any;

export const nsMember = exports;

import * as nsRole from './Role';
import type { Role } from './Role';

export const dependencyModules = [
  nsRole,
];

export class Member implements Role {
  private static instance: Member;

  private constructor() {}

  public static getInstance() {
    if (!Member.instance) {
      Member.instance = new Member();
    }

    return Member.instance;
  }
}

export const MemberInhabitant: Member = Member.getInstance();

export function isMember(v: any): v is Member {
  return (v instanceof Member) && (v === MemberInhabitant);
}

export type MemberSingleton = Member;
