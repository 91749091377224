import { Select } from "@chakra-ui/react";
import { data } from 'currency-codes';
import { supportedCurrencies } from "src/data/Countries";

export interface CodeCurrency {
    code: string;
    currency: string;
};

//const supported = new Set(['CHF', 'USD', 'EUR', 'BDT', 'KHR', 'LAK', 'KES', 'UGX', 'MWK']);

// convert into code currency pairs and remove unwanted currencies
const codeCurrency = (data ?? [])
    .filter((rec) => {
        let code = rec.code;
        return supportedCurrencies.has(code);
    }).map((rec) => {
        let cc: CodeCurrency = {
            code: rec.code,
            currency: rec.currency
        }
        return cc;
    });
    // console.info(`found ${codeCurrency.length} currencies ...`);



const CurrencySelect = ({ ...props }) => {
    return (
        <Select placeholder='Währung auswählen' title='' {...props}>
            {codeCurrency.map(cc => (<option key={cc.code} value={cc.code}>{cc.code} - {cc.currency}</option>))}
        </Select>
    );
}

export default CurrencySelect