import { Card, CardBody, Divider, Flex, FormLabel, Heading, Skeleton, Spinner, Stack, Stat, StatArrow, StatGroup, StatHelpText, StatLabel, StatNumber, Text, useColorModeValue, useDisclosure, VStack } from '@chakra-ui/react';
import { CustomDateInput } from 'src/components/DateSelect';
import DatePicker from "react-datepicker";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ColumnDef, CellContext, Row } from '@tanstack/react-table';
import DataTable, { amountCell } from 'src/components/DataTable';
import { AmountUtil } from 'src/data/AmountUtil';
import { reportService } from 'src/service/ReportService';
import { ProvisionYear } from 'src/api/ProvisionYear';
import { countries } from 'src/data/Countries';
import CountryFlag from 'src/components/CountryFlag';
import Content from 'src/components/Content';
import DashboardProvisionsDialog from './DashboardProvisionsDialog';

interface QuarterStat {
    balance: number,
    change: number,
    increase: boolean
}

interface RowDetail {
    projectId: number,
    projectName: string,
    donationCode: string
}

const DashboardProvisions = () => {
    const [budgetYear, setBudgetYear] = useState(new Date().getFullYear());
    const [data, setData] = useState<ProvisionYear[]>([]);
    const [quarterStat, setQuarterStat] = useState<QuarterStat[]>([]);
    const [project2Name, setProject2Name] = useState<Map<number, string>>(new Map()); // id to project name
    const [donationTypes, setDonationTypes] = useState<Map<number, string>>(new Map()); // id to donation type name
    const [isLoading, setIsLoading] = useState(false);
    const [rowDetail, setRowDetail] = useState<RowDetail | null>(null);

    const { isOpen: isDetailsOpen, onOpen: onDetailsOpen, onClose: onDetailsClose } = useDisclosure();

    const loadAllData = useCallback((): Promise<void> => {
        setIsLoading(true);
        return reportService.provisions(budgetYear)
            .then(r => {
                let res = r.results.concat();
                setData(res);
                setProject2Name(new Map(r.projects.map(p => [p.id, p.name])));
                setDonationTypes(new Map(r.donationTypes.map(p => [p.id, p.name])));

                // quarter stats, 0 -> 4, where 0 is the prev balance, calculate, change and increase!!!!
                let quarterEnds = [
                    res.map(d => d.q1.start),
                    res.map(d => d.q1.end),
                    res.map(d => d.q2.end),
                    res.map(d => d.q3.end),
                    res.map(d => d.q4.end),
                ].map(d => d.reduce((a, b) => a + b, 0));
                let stat = [1, 2, 3, 4].map(ix => {
                    let prevEnd = quarterEnds[ix - 1];
                    let change = prevEnd === 0 ? 0 : 100 * (quarterEnds[ix] - prevEnd) / prevEnd;
                    let qs: QuarterStat = {
                        balance: quarterEnds[ix],
                        change: change,
                        increase: change >= 0
                    }
                    return qs;
                });
                setQuarterStat(stat);
                console.info(stat);
            })
            .finally(() => setIsLoading(false));
    }, [budgetYear]);

    useEffect(() => {
        loadAllData();
    }, [budgetYear, loadAllData]);

    const colorLinkHover = useColorModeValue('gray.900', 'gray.200');

    const quarterHeaders: ColumnDef<ProvisionYear>[] = [1, 2, 3, 4].map((ix) => {
        return {
            id: `hq${ix}`,
            header: () => (<div style={{ textAlign: 'right', fontWeight: 'extrabold', color: 'orange' }}>Q{ix}</div>),
            enableGrouping: false,
            columns: [
                {
                    id: `q${ix}prev`,
                    header: () => (<div style={{ textAlign: "right" }}>Vorjahr</div>),
                    accessorKey: `q${ix}.previous`,
                    cell: amountCell({ width: 'auto' }),
                    aggregatedCell: amountCell({ width: 'auto' }),
                    enableGrouping: false,
                    enableSorting: false,
                    aggregateFn: 'sum',
                },
                {
                    id: `q${ix}curr`,
                    header: () => (<div style={{ textAlign: "right" }}>Laufjahr</div>),
                    accessorKey: `q${ix}.current`,
                    cell: amountCell({ width: 'auto' }),
                    aggregatedCell: amountCell({ width: 'auto' }),
                    enableGrouping: false,
                    enableSorting: false,
                    aggregateFn: 'sum',
                },
                {
                    id: `q${ix}end`,
                    header: () => (<div style={{ textAlign: 'right', fontWeight: 'bold', color: 'orange' }}>Saldo</div>),
                    accessorKey: `q${ix}.end`,
                    cell: (info: CellContext<ProvisionYear, number>) => (<Text cursor='default' textAlign='right' fontWeight='bold'>{AmountUtil.format(info.getValue() ?? 0)}</Text>),
                    aggregatedCell: (info: CellContext<ProvisionYear, number>) => (<Text cursor='default' textAlign='right' fontWeight='bold'>{AmountUtil.format(info.getValue() ?? 0)}</Text>),
                    enableGrouping: false,
                    enableSorting: false,
                    aggregateFn: 'sum',
                }
            ]
        }
    });

    const reportHeaders: ColumnDef<ProvisionYear>[] = useMemo(() => [
        {
            id: 'projects',
            header: 'Projekte',
            enableGrouping: false,
            columns: [
                {
                    id: 'country',
                    header: 'Land',
                    accessorKey: 'key.country',
                    cell: (info: CellContext<ProvisionYear, string>) => (
                        <VStack align={'flex-start'}>
                            <CountryFlag code={info.getValue()} />
                            <Text fontSize={'xs'}>{countries[info.getValue()]}</Text>
                        </VStack>
                    ),
                    sortingFn: (rowA: Row<ProvisionYear>, rowB: Row<ProvisionYear>, _: any): number => {
                        const a = rowA.original.key.country;
                        const b = rowB.original.key.country;
                        let ap = countries[a] ?? '';
                        let bp = countries[b] ?? '';
                        return ap.trim().localeCompare(bp.trim(), undefined, { sensitivity: 'base' });
                    },
                },
                {
                    id: 'project',
                    header: 'Projekt',
                    accessorKey: 'key.projectId',
                    cell: (info: CellContext<ProvisionYear, number>) => {
                        let pid = info.getValue();
                        let projectName = project2Name.get(pid) ?? 'n/a';
                        return (
                            <Text fontSize={'xs'} color='blue.600' _hover={{ color: colorLinkHover }}>
                                <Link to={`/projects/view/${pid}`}>
                                    {projectName}
                                </Link>
                            </Text>
                        );
                    },
                    //works differently when is grouped or flattened
                    sortingFn: (rowA: Row<ProvisionYear>, rowB: Row<ProvisionYear>, _): number => {
                        const a = rowA.original.key.projectId;
                        const b = rowB.original.key.projectId;
                        let ap = project2Name.get(a) ?? '';
                        let bp = project2Name.get(b) ?? '';
                        return ap.trim().localeCompare(bp.trim(), undefined, { sensitivity: 'base' });
                    },
                },
                {
                    id: 'donationType',
                    header: 'Projektmittel',
                    accessorKey: 'key.donationTypeId',
                    cell: (info: CellContext<ProvisionYear, number>) => (<Text textAlign='left'>{donationTypes.get(info.getValue())}</Text>),
                    aggregationFn: undefined,
                    enableSorting: false,
                    enableGrouping: false,
                },
                {
                    id: 'donationCode',
                    header: 'Kostenstelle',
                    accessorKey: 'key.donationCode',
                    enableSorting: false,
                    enableGrouping: false,
                }
            ]
        }
    ], [colorLinkHover, donationTypes, project2Name]);

    const columns: ColumnDef<ProvisionYear>[] = useMemo(
        () => reportHeaders.concat(quarterHeaders), [quarterHeaders, reportHeaders]
    );

    return (
        <Content caption='Projektfonds'>
            <Flex pt='1rem' justify={'space-between'} direction={{ base: 'column', sm: 'row' }}>
                <Flex>
                    <Card shadow={'md'} border={'1px solid lightgray'} px='5'>
                        <FormLabel>Budget Yahr:</FormLabel>
                        <DatePicker
                            showYearPicker
                            dateFormat="yyyy"
                            yearItemNumber={9}
                            showPopperArrow={true}
                            selected={new Date().setFullYear(budgetYear)}
                            onChange={(date) => {
                                setBudgetYear(date.getFullYear());
                            }}
                            minDate={new Date().setFullYear(2021)}
                            maxDate={new Date()}
                            customInput={<CustomDateInput />}
                        />
                    </Card>
                    <StatGroup shadow={'md'} border={'1px solid lightgray'} rounded={'md'} mx='1rem' textAlign='right'>
                        {quarterStat.map((qt, ix) => {
                            return (
                                <Stat px='1rem' key={ix} borderLeft={ix > 0 ? '1px solid lightgray' : ''}>
                                    <StatLabel>Q{ix + 1}</StatLabel>
                                    <Divider orientation='horizontal' />
                                    <StatNumber>{AmountUtil.format(qt.balance)}</StatNumber>
                                    <StatHelpText>
                                        <StatArrow type={qt.increase ? 'increase' : 'decrease'} />
                                        {qt.change.toFixed(2)}%
                                    </StatHelpText>
                                </Stat>
                            )
                        })}
                    </StatGroup>
                </Flex>
                <Stack justify={'space-between'} alignItems={{ sm: 'flex-end' }}>
                    <Card shadow={'md'} border={'1px solid lightgray'}>
                        <CardBody textAlign="left">
                            <Text>Gesamtsaldo</Text>
                            <Divider orientation='horizontal' />
                            <Heading size='lg'>{AmountUtil.format(data.map(d => d.q4.end).reduce((a, b) => a + b, 0))}</Heading>
                        </CardBody>
                    </Card>
                </Stack>
            </Flex>

            {isLoading ? (
                <Stack py={5}>
                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <>
                    <DataTable columns={columns} data={data} initialSortByColumn={['country', 'project']} initialSortByDesc={false}
                        initialGroupByColumns={['country', 'project']}
                        hideColumnsOnXs={['q1prev', 'q2prev', 'q3prev', 'q4prev', 'q1curr', 'q2curr', 'q3curr', 'q4curr']}
                        onRowClick={row => {
                            let pid = row.key.projectId;
                            let projectName = project2Name.get(pid) ?? 'n/a';
                            let on: RowDetail = {
                                projectId: pid,
                                projectName: projectName,
                                donationCode: row.key.donationCode
                            };
                            setRowDetail(on);
                            console.log(`provision row=${JSON.stringify(on)}`);
                            onDetailsOpen();
                        }
                        } />
                </>
            )}
            {rowDetail && <DashboardProvisionsDialog
                projectId={rowDetail.projectId} projectName={rowDetail.projectName} projectYear={budgetYear} donationCode={rowDetail.donationCode}
                isOpen={isDetailsOpen} onClose={() => {
                    onDetailsClose();
                    setRowDetail(null);
                }} />}
        </Content>
    );
}

export default DashboardProvisions