import { useAuthContext } from "src/auth/AuthContext";
import { useParams } from "react-router-dom";
import TransfersMoveForm from "./TransfersMoveForm";

const TransfersMoveView = () => {
    const { user } = useAuthContext();
    const { id } = useParams();
    return <TransfersMoveForm transferMoveId={parseInt(id)} organizationId={user.data.organizationId} onSubmit={(_ps) => Promise.resolve()} viewOnly={true} header='Ansicht' />
}

export default TransfersMoveView