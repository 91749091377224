

export const AmountUtil = {

    format(n?: number, fraction: number = 2): string {
        if (n === undefined || n === null) {
            return "-";
        }
        const threshold = Math.pow(10, -fraction);
        if (Math.abs(n) < threshold) {
            n = 0;
        }
        return n.toLocaleString('en-US', {minimumFractionDigits:fraction, maximumFractionDigits:fraction}).replace(/,/g, "'");
    },

    parse(s: string): number {
        return parseFloat(s.replace(/'/g, ""));
    }
}