import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import { Controller } from "react-hook-form";
import { InputGroup, Input, InputLeftElement } from "@chakra-ui/input";
import { BiCalendar } from "react-icons/bi";
import { forwardRef } from "react";
import { parseIso } from "src/data/DateUtil";

const customInput = ({ value, onClick, onChange, onFocus }: any, ref: any) => (
    <InputGroup>
        <InputLeftElement>
            <BiCalendar color='blue' />
        </InputLeftElement>
        <Input
            autoComplete="off"
            value={value}
            ref={ref}
            onClick={onClick}
            onChange={onChange}
            onFocus={onFocus} // fixes typing in date from the keyboard
            type='text'
        />
    </InputGroup>
);
customInput.displayName = "DateSelect";

export const CustomDateInput = forwardRef(customInput);

const parseSafeIso = (v: Date): Date => {
    try {
        return parseIso(v);
    } catch {
        return undefined;
    }
}

export const formatDate = (v: Date | null): Date => v ? parseSafeIso(v) : undefined;

const DateSelectController = ({ control, name, clearable }: { control, name: string, clearable: boolean }) => {
    return (<Controller control={control} name={name} render={({ field }) => (
        <DatePicker
            isClearable={clearable}
            selected={formatDate(field.value)}
            onChange={(date) => field.onChange(date)}
            dateFormat='dd.MM.yyyy'
            locale={de}
            showPopperArrow={true}
            customInput={<CustomDateInput />}
        />
    )} />)
}

export default DateSelectController

// readonly component to show a date entry
export const DateSelectInfo = ({date}: {date: Date}) => {
    return <DatePicker
            isClearable={false}
            selected={formatDate(date)}
            dateFormat='dd.MM.yyyy'
            locale={de}
            showPopperArrow={true}
            customInput={<CustomDateInput />}
            readOnly={true}
            disabled={true}
        />
}