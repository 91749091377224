import apiClient from './core/ApiClient'
import { CommitmentsStoreRequest } from 'src/api/CommitmentsStoreRequest';
import { Commitment } from 'src/api/Commitment';
import { CommitmentsFindResponse } from 'src/api/CommitmentsFindResponse';
import { CommitmentsListResponse } from 'src/api/CommitmentsListResponse';

const fundraisingRoot = '/api/fundraising';
const root = `${fundraisingRoot}/commitments`;

const add = (commitment: Commitment): Promise<void> => {
    let payload: CommitmentsStoreRequest = {
        summary: commitment
    }
    return apiClient.post<CommitmentsStoreRequest, void>(root, payload);
}

const update = (commitment: Commitment): Promise<void> => {
    let payload: CommitmentsStoreRequest = {
        summary: commitment
    }
    return apiClient.put<CommitmentsStoreRequest, void>(root, payload);
}

const remove = (id: number): Promise<void> => apiClient.delete<void>(`${root}/${id}`);

const find = (id: number): Promise<Commitment> => apiClient.get<CommitmentsFindResponse>(`${root}/${id}`).then(r => r.commitment);

const listAll = (): Promise<CommitmentsListResponse> => apiClient.get<CommitmentsListResponse>(root);

const fundSourceNames = (): Promise<string[]> => apiClient.get<string[]>(`${fundraisingRoot}/sources`);

export const commitmentService = {
    add,
    update,
    remove,
    find,
    listAll,
    fundSourceNames
}