import { ApiClient } from "./core/ApiClient";


export interface ExchangeRate {
    currency: string;
    rates: Map<string, number>;
}

const rateClient = new ApiClient('https://rates.velocorner.com');
const root = '/api/rates';

const listRates = (base: string): Promise<ExchangeRate> => rateClient.get<ExchangeRate>(`${root}/${base}`);

export const excangeRateService = {
    listRates,
}