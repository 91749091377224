import { Box, Text, Image, Stack, Show } from "@chakra-ui/react";


const NotFound = () => {
    return (
        <Stack as={Box} direction={{ base: 'column', md: 'row' }}
            align={'center'}
            justify={'center'}
            minH={{ base: '50vh', lg: '70vh' }}
        >
            <Box>
                <Text align={'center'} fontSize={"8xl"}>404</Text>
                <Text align={'center'} pb={10} fontSize={"1xl"} color={"darkgray"}>Etwas ist schief gelaufen</Text>
                <Show above='md'>
                    <Image
                        maxW={'4xl'}
                        alt={'Not found image'}
                        objectFit={'scale-down'}
                        src={'/images/desert.webp'}
                        shadow={'lg'}
                        rounded={'lg'}
                    />
                </Show>
            </Box>
        </Stack>
    );
}

export default NotFound;