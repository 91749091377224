import { HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Skeleton, Spinner, Stack, useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable, { amountCell, amountHeader, dateCell } from 'src/components/DataTable';
import { ColumnDef, CellContext, Row } from '@tanstack/react-table';
import { TransferMove } from 'src/api/TransferMove';
import Content from 'src/components/Content';
import { DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import { transferMoveService } from 'src/service/TransferMoveService';
import { FaEllipsisV } from 'react-icons/fa';
import IdAndNameData from 'src/data/IdAndNameData';
import ConfirmDialog from 'src/components/ConfirmDialog';
import ReachLink from 'src/components/ReachLink';
import AddButton from 'src/components/AddButton';

const OverviewTransfersMoves = () => {
    const [data, setData] = useState<TransferMove[]>([]);
    const [projects, setProjects] = useState<Map<number, string>>(new Map()); // id to project name
    const [partners, setPartners] = useState<Map<number, string>>(new Map()); // id to partner name
    const [deleteData, setDeleteData] = useState<IdAndNameData<number> | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure(); // for delete dialog

    const navigate = useNavigate();

    const onDelete = useCallback((id: number, name: string): Promise<void> => {
        console.info(`deleting ${id} ${name}`);
        return transferMoveService.remove(id)
            .then(() => setDeleteData(null))
            .then(() => window.location.reload());
    }, []);

    const onEdit = useCallback((id: number): void => {
        console.info(`editing ${id}`);
        navigate(`/transfers/moves/edit/${id}`);
    }, [navigate]);

    const onView = useCallback((id: number): void => {
        console.info(`viewing ${id}`);
        navigate(`/transfers/moves/view/${id}`);
    }, [navigate]);

    const loadAllData = (): Promise<void> => {
        setIsLoading(true);
        return transferMoveService.listAll()
            .then(r => {
                setData(r.results.concat());
                setProjects(new Map(r.projects.map(p => [p.id, p.name])));
                setPartners(new Map(r.partners.map(p => [p.id, p.name])));
            })
            .finally(() => setIsLoading(false));
    }

    const onUpload = useCallback((): Promise<void> => {
        console.info(`upload funds suceeded...`);
        return loadAllData();
    }, []);

    useEffect(() => {
        loadAllData();
    }, [onUpload]);

    const columns: ColumnDef<TransferMove>[] = useMemo(
        () => [
            {
                id: 'transferDate',
                header: 'Datum',
                accessorKey: 'transferDate',
                cell: dateCell()
            },
            {
                id: 'partner',
                header: 'Partner',
                accessorFn: (row: TransferMove) => row.partnerId,
                cell: (info: CellContext<TransferMove, number>) => {
                    let pid = info.getValue();
                    let partnerName = partners.get(pid);
                    return (<ReachLink to={`/partners/view/${pid}`} name={partnerName} tooltip='zu den Partnerdetails'/>);
                },
                sortingFn: (rowA: Row<TransferMove>, rowB: Row<TransferMove>, _): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    let ap = partners.get(a.partnerId);
                    let bp = partners.get(b.partnerId);
                    return ap.trim().localeCompare(bp.trim());
                },
            },
            {
                id: 'fromProject',
                header: 'Von Projekt',
                accessorKey: 'fromProjectId',
                cell: (info: CellContext<TransferMove, number>) => {
                    let pid = info.getValue();
                    let projectName = projects.get(pid);
                    return (<ReachLink to={`/projects/view/${pid}`} name={projectName} tooltip='zu den Projektdetails'/>);
                },
                sortingFn: (rowA: Row<TransferMove>, rowB: Row<TransferMove>, _): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    let ap = projects.get(a.fromProjectId) ?? '';
                    let bp = projects.get(b.fromProjectId) ?? '';
                    return ap.trim().localeCompare(bp.trim());
                },
            },
            {
                id: 'toProject',
                header: 'Auf Projekt',
                accessorKey: 'toProjectId',
                cell: (info) => {
                    let pid = info.row.original.toProjectId;
                    let projectName = projects.get(pid);
                    return (<ReachLink to={`/projects/view/${pid}`} name={projectName} tooltip='zu den Projektdetails'/>);
                },
                sortingFn: (rowA: Row<TransferMove>, rowB: Row<TransferMove>, _): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    let ap = projects.get(a.toProjectId) ?? '';
                    let bp = projects.get(b.toProjectId) ?? '';
                    return ap.trim().localeCompare(bp.trim());
                },
            },
            {
                id: 'localTotal',
                header: amountHeader({name: 'Summe'}),
                accessorKey: 'localTotal',
                cell: amountCell({}),
            },
            {
                id: 'currency',
                header: 'Währung',
                accessorKey: 'currency'
            },
            {
                id: 'total',
                header: amountHeader({name: 'CHF'}),
                accessorKey: 'total',
                cell: amountCell({}),
            },

            {
                id: 'edit',
                enableSorting: false,
                cell: info => {
                    let row = info.row.original;
                    return (
                        <Menu isLazy>
                            <MenuButton size='sx' as={IconButton} variant='outline' p='0px' bg="transparent" color="blue.500" cursor="pointer" icon={<FaEllipsisV />} />
                            <MenuList>
                                <MenuItem icon={<ViewIcon />} command='⌘O' onClick={() => onView(row.id)}>Ansicht</MenuItem>
                                <MenuItem icon={<EditIcon />} command='⌘E' onClick={() => onEdit(row.id)}>Bearbeiten</MenuItem>
                                <MenuItem icon={<DeleteIcon color="red.500" />} command='⌘D' onClick={() => {
                                    let da: IdAndNameData<number> = {
                                        id: row.id,
                                        name: `${projects.get(row.toProjectId)} / ${partners.get(row.partnerId)}`
                                    };
                                    setDeleteData(da);
                                    onOpen();
                                }
                                }>Löschen</MenuItem>
                            </MenuList>
                        </Menu>
                    )
                }
            }
        ], [partners, projects, onView, onEdit, onOpen]
    );

    return (
        <Content caption='Eigenmittel Übertrag Partner'>
            <HStack align={'top'}>
                <AddButton caption='Neuer Übertrag' onClick={() => navigate('/transfers/moves/add')} />
            </HStack>
            {isLoading ? (
                <Stack py={5}>
                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <>
                    <DataTable columns={columns} data={data} initialSortByColumn='transferDate' initialSortByDesc={true} hideColumnsOnXs={['localTotal', 'currency', 'comments']}
                        onRowClick={row => onEdit(row.id)}
                    />
                    <ConfirmDialog
                        heading={`${deleteData?.name} Löschen`}
                        isOpen={isOpen}
                        onClose={onClose}
                        onConfirm={async (): Promise<void> => onDelete(deleteData.id, deleteData.name)}
                    />
                </>
            )}
        </Content>
    );
}

export default OverviewTransfersMoves
