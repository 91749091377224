import { useAuthContext } from "src/auth/AuthContext";
import { expenseService } from 'src/service/ExpenseService';
import { useParams } from "react-router-dom";
import ExpensesForm from "./ExpensesForm";

const ExpensesEdit = () => {
    const { user } = useAuthContext();
    const { id } = useParams();
    return <ExpensesForm expenseId={parseInt(id)} organizationId={user.data.organizationId} onSubmit={(ps) => expenseService.update(ps)} header='Ausgabe Bearbeiten' />
}

export default ExpensesEdit