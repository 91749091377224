import { useAuthContext } from "src/auth/AuthContext";
import { projectService } from "src/service/ProjectService";
import ProjectsForm from "./ProjectsForm";

const ProjectsAdd = () => {
    const { user } = useAuthContext();

    return <ProjectsForm projectId={-1} organizationId={user.data.organizationId} onSubmit={(ps) => projectService.add(ps)} header='Neues Projekt'/>

}

export default ProjectsAdd