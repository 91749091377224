import { DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import { Text, Skeleton, Spinner, Stack, Menu, MenuButton, MenuList, MenuItem, IconButton, useDisclosure, HStack } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable, { amountCell, dateCell } from 'src/components/DataTable';
import { ColumnDef, CellContext, Row } from '@tanstack/react-table';
import { Commitment } from 'src/api/Commitment';
import Content from 'src/components/Content';
import { commitmentService } from "src/service/CommitmentService";
import IdAndNameData from 'src/data/IdAndNameData';
import { FaEllipsisV } from 'react-icons/fa';
import ConfirmDialog from 'src/components/ConfirmDialog';
import ReachLink from 'src/components/ReachLink';
import AddButton from 'src/components/AddButton';
import { FileDownload, FileSelect } from 'src/components/FileSelect';
import { formatPicker } from 'src/data/DateUtil';

const OverviewCommitments = () => {
    const [data, setData] = useState<Commitment[]>([]);
    const [projects, setProjects] = useState<Map<number, string>>(new Map()); // id to project name
    const [deleteData, setDeleteData] = useState<IdAndNameData<number> | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure(); // for delete dialog

    const navigate = useNavigate();

    const onDelete = useCallback((id: number, name: string): Promise<void> => {
        console.info(`deleting ${id} ${name}`);
        return commitmentService.remove(id)
            .then(() => setDeleteData(null))
            .then(() => window.location.reload());
    }, []);

    const onEdit = useCallback((id: number): void => {
        console.info(`editing ${id}`);
        navigate(`/fundraising/commitments/edit/${id}`);
    }, [navigate]);

    const onView = useCallback((id: number): void => {
        console.info(`viewing ${id}`);
        navigate(`/fundraising/commitments/view/${id}`);
    }, [navigate]);

    const loadAllData = (): Promise<void> => {
        setIsLoading(true);
        return commitmentService.listAll().then(r => {
            setData(r.results.concat());
            setProjects(new Map(r.projects.map(p => [p.id, p.name])));
        })
            .finally(() => setIsLoading(false));
    }

    const onUploadFinished = useCallback((): Promise<void> => {
        console.info(`upload commitments suceeded...`);
        return loadAllData();
    }, []);

    useEffect(() => {
        loadAllData();
    }, [onUploadFinished]);

    const columns: ColumnDef<Commitment>[] = useMemo(
        () => [
            {
                id: 'year',
                header: 'Jahr',
                accessorFn: (row) => new Date(row.entryDate).getFullYear(),
                cell: (info: CellContext<Commitment, number>) => (<Text fontWeight={'extrabold'}>{info.getValue()}</Text>),
            },
            {
                id: 'entryDate',
                header: 'Datum',
                accessorKey: 'entryDate',
                cell: dateCell(),
                enableGrouping: false,
            },
            {
                id: 'project',
                header: 'Projekt',
                accessorKey: 'projectId',
                cell: (info: CellContext<Commitment, number>) => {
                    let pid = info.getValue() ?? 0;
                    let projectName = projects.get(pid);
                    return (<ReachLink to={`/projects/view/${pid}`} name={projectName} tooltip='zu den Projektdetails' />);
                },
                sortingFn: (rowA: Row<Commitment>, rowB: Row<Commitment>, _): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    let ap = projects.get(a.projectId) ?? '';
                    let bp = projects.get(b.projectId) ?? '';
                    return ap.trim().localeCompare(bp.trim(), undefined, { sensitivity: 'base' });
                },
                aggregatedCell: '',
                enableGrouping: false,
            },
            {
                id: 'fundSourceName',
                header: 'Fonds Quelle (Institution)',
                accessorKey: 'fundSourceName',
                enableGrouping: false,
            },
            {
                id: 'amount',
                header: () => (<div style={{ textAlign: 'right', width: '10rem' }}>Summe</div>),
                accessorKey: 'amount',
                cell: amountCell({}),
                aggregatedCell: amountCell({fontWeight: 'bold'}),
                enableGrouping: false,
            },
            {
                id: 'comments',
                header: () => (<div style={{ textAlign: 'right', width: '20rem' }}>Kommentar</div>),
                accessorKey: 'comments',
                enableGrouping: false,
            },
            {
                id: 'edit',
                enableSorting: false,
                enableGrouping: false,
                cell: (info) => {
                    let row = info.row.original;
                    return (
                        <>
                            <Menu isLazy>
                                <MenuButton size='sx' as={IconButton} variant='outline' p='0px' bg="transparent" color="blue.500" cursor="pointer" icon={<FaEllipsisV />} />
                                <MenuList>
                                    <MenuItem icon={<ViewIcon />} command='⌘O' onClick={() => onView(row.id)}>Ansicht</MenuItem>
                                    <MenuItem icon={<EditIcon />} command='⌘E' onClick={() => onEdit(row.id)}>Bearbeiten</MenuItem>
                                    <MenuItem icon={<DeleteIcon color="red.500" />} command='⌘D' onClick={() => {
                                        let da: IdAndNameData<number> = {
                                            id: row.id,
                                            name: `${projects.get(row.projectId)} / ${row.fundSourceName ?? 'n/a'}`
                                        };
                                        setDeleteData(da);
                                        onOpen();
                                    }
                                    }>Löschen</MenuItem>
                                </MenuList>
                            </Menu>
                        </>
                    )
                }
            }
        ], [projects, onEdit, onOpen, onView]
    );

    return (
        <Content caption='Zusagen'>
            <Stack align={'baseline'} direction={{ base: 'column', sm: 'row' }} justify={'space-between'}>
                <AddButton caption='Neue Zusage' onClick={() => navigate('/fundraising/commitments/add')} />
                <HStack>
                    <FileSelect path='/api/fundraising/commitments/upload' onUpload={onUploadFinished} />
                    <FileDownload path='/api/fundraising/commitments/download' name={`zusagen-${formatPicker(new Date())}.xlsx`} />
                </HStack>
            </Stack>
            {isLoading ? (
                <Stack py={5}>
                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <>
                    <DataTable columns={columns} data={data}
                        initialSortByColumn={['year', 'entryDate']} initialSortByDesc={[false, true]} hideColumnsOnXs={['project', 'comments']}
                        initialGroupByColumns={['year']}
                        onRowClick={row => onEdit(row.id)}
                    />
                    <ConfirmDialog
                        heading={`${deleteData?.name} Löschen`}
                        isOpen={isOpen}
                        onClose={onClose}
                        onConfirm={async (): Promise<void> => onDelete(deleteData.id, deleteData.name)}
                    />
                </>
            )}
        </Content>
    );
}

export default OverviewCommitments