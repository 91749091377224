import { Flex, StatGroup, Stat, StatLabel, StatNumber, StatHelpText, Divider, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { AmountUtil } from "src/data/AmountUtil";
import { excangeRateService } from "src/service/ExchangeRateService";

export interface ExchangeRatesProps {
    base: string;
    all: Array<string>;
}

const ExchangeRates = (props: ExchangeRatesProps) => {

    const [data, setData] = useState<Map<string, number>>(new Map());
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        excangeRateService.listRates('CHF').then(res => setData(new Map(Object.entries(res.rates))))
            .finally(() => setIsLoading(false));
    }, []);

    return (
        <Flex pt='1rem' justify={'space-between'} direction={{ base: 'column', sm: 'row' }}>
            <StatGroup shadow={'md'} border={'1px solid lightgray'} rounded={'md'} mx='1rem' textAlign='right'>
                {props.all.map((counter, ix) => {
                    const rate = data.get(counter) ?? 0;
                    return (
                        <Stat px='1rem' key={ix} borderLeft={ix > 0 ? '1px solid lightgray' : ''}>
                            <StatLabel>{props.base}/{counter}</StatLabel>
                            <Divider orientation='horizontal' />
                            {isLoading ?
                                (<Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='md' />)
                                :
                                (<StatNumber color={rate > 0 ? '' : 'red'}>{AmountUtil.format(rate)}</StatNumber>)
                            }
                            {/* <HistoryChart /> */}
                            {/* <StatHelpText>
                                30 Tage
                            </StatHelpText> */}
                        </Stat>
                    )
                })}
            </StatGroup>
        </Flex>
    );
}

const HistoryChart = () => {
    const height = 200;
    const width = 200;
    const options = {
        chart: {
            id: "history-line",
            stacked: false,
            height: height,
            defaultLocale: 'en',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
            xaxis: {
                type: 'datetime'
            }
        },
    };
    const series = [
        {
            name: "rates",
            data: [{
                x: new Date('2018-02-12').getTime(),
                y: 52
              }, {
                x: new Date('2022-03-11').getTime(),
                y: 101
              }, {
                x: new Date('2024-07-20').getTime(),
                y: 76
              }]
        }
    ]
    return (
        <div><ReactApexChart options={options} series={series} type="area" height={height} width={width} /></div>
    )
}

export default ExchangeRates