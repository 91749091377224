import apiClient from "./core/ApiClient";
import { TransfersListResponse } from "src/api/TransfersListResponse";

const root = "/api/funds/transfers";

const listAll = (): Promise<TransfersListResponse> =>
  apiClient.get<TransfersListResponse>(root);

const queryParams = (year?: number, projectId?: number, partnerId?: number): string => {
    const params = new URLSearchParams();
    if (year) {
      params.append('year', year.toString());
    }
    if (projectId) {
      params.append('project_id', projectId.toString());
    }
    if (partnerId) {
      params.append('partner_id', partnerId.toString()); 
    }
    return params.toString();
};
const query = (year?: number, projectId?: number, partnerId?: number): Promise<TransfersListResponse> =>
    apiClient.get<TransfersListResponse>(`${root}?${queryParams(year, projectId, partnerId)}`);

export const transferService = {
  listAll,
  queryParams,
  query,
};
