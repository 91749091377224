import { Money } from "src/api/Money";
import { AmountUtil } from "./AmountUtil";


export const MoneyUtil = {

    merge(list?: Money[]): Money[] {
        list = list ?? []; // handle undefined paremeter
        list = list.filter(x => x); // handle undefined items in the list
        const key = 'currency';
        var groupBy = list.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
        const sum = Object.keys(groupBy).map(function (key) {
            return {
                value: groupBy[key].reduce((a, v) => a + v.value, 0),
                currency: key
            }
        });
        return sum;
    },

    sumAmount(list: Money[]): number {
        return list.reduce((a, v) => a + v.value, 0);
    },

    format(money?: Money): string {
         return money ? AmountUtil.format(money.value) + ' ' + money.currency : '-'
    }
}