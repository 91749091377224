import PartnersForm from './PartnersForm'
import { useAuthContext } from 'src/auth/AuthContext';
import { useParams } from 'react-router-dom';

const PartnersView = () => {
    const { user } = useAuthContext();
    const { id } = useParams();

    return <PartnersForm partnerId={parseInt(id)}
        organizationId={user.data.organizationId}
        onSubmit={(_partner) => Promise.resolve()}
        viewOnly={true}
        header='Ansicht' />
}

export default PartnersView