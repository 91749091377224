import { Stack, Spinner, Skeleton, Box, Switch } from '@chakra-ui/react';
import { ColumnDef, CellContext } from '@tanstack/react-table';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FundraisingTotal } from 'src/api/FundraisingTotal';
import Content from 'src/components/Content';
import DataTable, { amountHeader, amountCell } from 'src/components/DataTable';
import ReachLink from 'src/components/ReachLink';
import { reportService } from 'src/service/ReportService';
import { FundraisingCoverage } from 'src/api/FundraisingCoverage';
import { ProgressBarReact } from 'src/components/ProgressBar';


const DashboardFundraisingAll = () => {
    const [data, setData] = useState<FundraisingTotal[]>([]);
    const [currentCoverage, setCurrentCoverage] = useState<Map<number, FundraisingCoverage>>(new Map()); // id to coverage
    const [active, setActive] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const loadAllData = (): Promise<void> => {
        setIsLoading(true);
        return reportService.fundraisingAll()
            .then(r => {
                // overall aggregations with every year
                setData(r.overall.concat());
                // current year for yearly coverage
                let year = new Date().getFullYear();
                let current = new Map(r.results.filter(r => r.key.year === year).map(p => [p.key.projectId, p.coverage]));
                //console.log(current);
                setCurrentCoverage(current);
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        loadAllData();
    }, []);

    const onView = useCallback((id: number): void => {
        console.info(`viewing ${id}`);
        navigate(`/fundraising/projects/${id}`);
    }, [navigate]);

    const onFilter = (row: FundraisingTotal, value: string): boolean => {
        if (value === 'active') {
            return row.project.active;
        }
        return true;
    }

    const columns: ColumnDef<FundraisingTotal>[] = useMemo(
        () => [
            {
                id: 'project',
                header: 'Projekt',
                accessorKey: 'project.name',
                cell: (info) => {
                    let row = info.row.original;
                    return (<ReachLink to={`/projects/view/${row.project.id}`} name={row.project.name} tooltip='zu den Projektdetails' />);
                },
            },
            {
                id: 'budget',
                header: amountHeader({ name: 'Budget' }),
                accessorKey: 'balance.budgetTotal',
                cell: amountCell({})
            },
            {
                id: 'income',
                header: amountHeader({ name: 'Total Mittel', explanation: 'Total Mittel = Total institutionelle Beiträge + Eigenmittel aus Einzelspenden + Eigenmittel aus Patenschaften + Übertrag aus Vorgängerprojekt beim Partner + Übertrag aus Vorgängerprojekt CO-OPERAID) + Zusagen' }),
                accessorKey: 'coverage.incomeTotal',
                cell: amountCell({})
            },
            {
                id: 'open',
                header: amountHeader({ name: 'Offen Total', explanation: 'Offen Total = Gesamt Fundraising Budget - Total Mittel' }),
                accessorKey: 'coverage.overallOpenTotal',
                cell: amountCell({})
            },
            {
                id: 'overallCoveragePercent',
                header: amountHeader({ name: 'Gesamtdeckung', explanation: 'Gesamtdeckung % = Total Mittel / Gesamt Fundraising Budget %' }),
                accessorKey: 'coverage.overallCoverage',
                cell: (info: CellContext<FundraisingTotal, number>) => ProgressBarReact(info.getValue())
            },
            {
                id: 'coverage',
                header: amountHeader({ name: 'Offener Betrag LJ', explanation: 'Offener Betrag Laufjahr = Total Mittel - Gesamt Fundraising Budget' }),
                accessorFn: (row: FundraisingTotal) => currentCoverage.get(row.project.id)?.coverageTotal ?? 0,
                cell: amountCell({})
            },
            {
                id: 'coveragePercent',
                header: amountHeader({ name: 'Jahresdeckung', explanation: 'Jahresdeckung % = Total Mittel Laufjahr - Fundraising Budget Laufjahr' }),
                accessorFn: (row: FundraisingTotal) => currentCoverage.get(row.project.id)?.coverage ?? 0,
                cell: (info: CellContext<FundraisingTotal, number>) => ProgressBarReact(info.getValue())
            },
        ], [currentCoverage]
    );

    return (
        <Content caption='Fundraising'>
            <Box pb='.5rem'>
                <Switch id='remember' isChecked={active} onChange={event => setActive(event.currentTarget.checked)}>nur die Aktiven</Switch>
            </Box>
            {isLoading ? (
                <Stack py={5}>
                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <DataTable columns={columns} data={data} initialSortByColumn='project' initialSortByDesc={false}
                    hideColumnsOnXs={['income', 'coverage', 'coveragePercent', 'open']}
                    onFilter={onFilter} filter={active ? 'active' : ''}
                    onRowClick={row => onView(row.project.id)}
                />
            )}
        </Content>
    );
}

export default DashboardFundraisingAll
