// Generated by ScalaTS 0.7.0: https://scala-ts.github.io/scala-ts/

declare var exports: any;

export const nsAdmin = exports;

import * as nsRole from './Role';
import type { Role } from './Role';

export const dependencyModules = [
  nsRole,
];

export class Admin implements Role {
  private static instance: Admin;

  private constructor() {}

  public static getInstance() {
    if (!Admin.instance) {
      Admin.instance = new Admin();
    }

    return Admin.instance;
  }
}

export const AdminInhabitant: Admin = Admin.getInstance();

export function isAdmin(v: any): v is Admin {
  return (v instanceof Admin) && (v === AdminInhabitant);
}

export type AdminSingleton = Admin;
