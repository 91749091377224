import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Avatar,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import PasswordStrengthBar from 'react-password-strength-bar';
import { ViewIcon, ViewOffIcon, LockIcon, UnlockIcon } from '@chakra-ui/icons';
import ErrorMessage from 'src/components/ErrorMessage';
import { authService } from 'src/service/AuthService';
import { useAuthContext } from 'src/auth/AuthContext';
import AvatarEditor from 'react-avatar-editor';

const Profile = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { user } = useAuthContext();

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent): void => {
      switch (e.code) {
        case 'Enter':
          handleSubmit(e)
          break;
      }
    };
    document.addEventListener('keydown', onKeyDown, true);
    return () => document.removeEventListener('keydown', onKeyDown, true);
  });

  const handleSubmit = async event => {
    console.info('changing password...');
    event.preventDefault();
    try {
      setIsSubmitting(true);
      if (!oldPassword) {
        throw new Error('Aktuelles passwort leer');
      }
      if (!newPassword) {
        throw new Error('Naues passwort leer');
      }
      if (!confirmNewPassword) {
        throw new Error('Naues passwort wiederholen leer');
      }
      if (newPassword !== confirmNewPassword) {
        throw new Error('Die beiden Passworte sind nicht identisch');
      }
      await authService.passwordChange(oldPassword, newPassword);
      setIsSubmitting(false);
      console.info(`pasword changed...`);
      setSuccess(true);
    } catch (error) {
      console.error(error);
      setError(error.message);
      setIsSubmitting(false);
      // setOldPassword('');
      // setNewPassword('');
      // setConfirmNewPassword('');
    }
  };

  return (
    <Flex
      minH={{ base: '50vh', lg: '70vh' }}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.25', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'2xl'} py={6} px={6}>
        <Stack align={'center'}>
          <Avatar bg="teal.500" />
          <Heading fontSize={'4xl'} textAlign={'center'}>{user?.data?.firstName} {user?.data?.lastName}</Heading>
        </Stack>

        {/* 2 col layout */}
        <Stack direction={{ base: 'column', sm: 'row' }} spacing={8}>

          <Box
            rounded={'lg'}
            bg={useColorModeValue('gray.25', 'gray.700')}
            boxShadow={'lg'}
            pos='relative'
            p={8}>
            <Text align='center' fontSize={'lg'} color={'gray.600'}>Bilder ändern 👤</Text>
            <AvatarEditor
              image="/images/matterhorn.webp"
              width={200}
              height={200}
              border={10}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={1.2}
              rotate={0}
              borderRadius={100}
            />
          </Box>

          <Box
            rounded={'lg'}
            bg={useColorModeValue('gray.25', 'gray.700')}
            boxShadow={'lg'}
            pos='relative'
            p={8}>
            <Stack spacing={4}>
              <Text align='center' fontSize={'lg'} color={'gray.600'}>Passwort ändern 🔐</Text>
              {error && <ErrorMessage message={error} />}
              {success && <Alert status='success'><AlertIcon />Eingestellt!</Alert>}
              <FormControl id="oldPassword" isRequired>
                <FormLabel>Aktuelles Passwort</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents='none' children={<UnlockIcon color='gray.400' />} />
                  <Input value={oldPassword} type={showPassword ? 'text' : 'password'} title='' onChange={event => {
                    setOldPassword(event.currentTarget.value);
                    setError('');
                  }
                  } />
                  <InputRightElement h={'full'}>
                    <Button variant={'ghost'} onClick={() => setShowPassword((showPassword) => !showPassword)}>
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl id="newPassword" isRequired>
                <FormLabel>Neues Passwort</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents='none' children={<LockIcon color='gray.400' />} />
                  <Input value={newPassword} type={showPassword ? 'text' : 'password'} title='' onChange={event => {
                    setNewPassword(event.currentTarget.value);
                    setError('');
                  }
                  } />
                  <InputRightElement h={'full'}>
                    <Button variant={'ghost'} onClick={() => setShowPassword((showPassword) => !showPassword)}>
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <PasswordStrengthBar password={newPassword} shortScoreWord='zu kurz' scoreWords={['schwach', 'schwach', 'okay', 'gut', 'stark']} />
              </FormControl>
              <FormControl id="confirmNewPassword" isRequired>
                <FormLabel>Neues Passwort wiederholen</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents='none' children={<LockIcon color='gray.400' />} />
                  <Input value={confirmNewPassword} type={showPassword ? 'text' : 'password'} title='' onChange={event => {
                    setConfirmNewPassword(event.currentTarget.value);
                    setError('');
                  }
                  } />
                  <InputRightElement h={'full'}>
                    <Button variant={'ghost'} onClick={() => setShowPassword((showPassword) => !showPassword)}>
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack spacing={10} pt={2} direction={{ base: 'column', sm: 'row' }} justify={'flex-end'}>
                {!success && <Button isLoading={isSubmitting} bg={'blue.400'} color={'white'} _hover={{ bg: 'blue.500' }} onClick={event => handleSubmit(event)}>
                  Passwort ändern
                </Button>}
              </Stack>
            </Stack>
          </Box>

        </Stack>

        <Stack
          direction={{ base: 'column', sm: 'row' }}
          align={'center'}
          justify={'flex-end'}
          spacing={10}>
          <Button onClick={() => navigate('/')} bg={'red.400'} color={'white'} _hover={{ bg: 'red.500' }}>
            Schliessen
          </Button>
        </Stack>

      </Stack>
    </Flex>
  );
}

export default Profile