import { Card, CardBody, CardHeader, Container, Flex, GridItem, Heading, SimpleGrid, SlideFade, Stack, Text } from '@chakra-ui/react';
import { FcCloseUpMode, FcCollaboration, FcBullish } from "react-icons/fc";
import { IconType } from 'react-icons';


interface FeatureProps {
    title: string;
    text: string;
    delay: number;
    icon: IconType;
    color: string;
}


const features: FeatureProps[] = [
    {
        title: 'Partners',
        text: `Alles auf einen Blick`,
        delay: 1,
        icon: FcCollaboration,
        color: '#00BAA3'
    },
    {
        title: 'Projekte',
        text: `Einfacher Überblick`,
        delay: 1.3,
        icon: FcCloseUpMode,
        color: '#EFC94C'
    },
    {
        title: 'Analyse',
        text: `Weniger Verwaltung`,
        delay: 1.6,
        icon: FcBullish,
        color: '#D55342'
    },
];

const FeatureCard = (props: FeatureProps) => {
    return (
        <SlideFade in={true} offsetY='20px' transition={{ enter: { duration: 0.4, delay: props.delay, ease: [.27, .52, .58, .94] } }} >
            <Card bg={props.color} shadow='lg' color='white' border='0' borderColor='gray.200'>
                <CardHeader>
                    <Flex
                        p={2}
                        w="max-content"
                        rounded="md"
                        marginInline="auto"
                        pos="absolute"
                        left={0}
                        right={0}
                        top="-2.5rem"
                    >
                        <props.icon size='4em' />
                    </Flex>
                    <Heading size='md'>{props.title}</Heading>
                </CardHeader>
                <Text align='center'>{props.text}</Text>
                <CardBody>
                    <Stack alignItems={{ base: 'flex-end' }}>
                        <Text fontSize='xs' align='right'>und mehr...</Text>
                    </Stack>
                </CardBody>
            </Card>
        </SlideFade>
    );
}

const Features = () => {
    return (
        <Container maxW={{ base: '2xl', sm: '3xl', md: '4xl', lg: '6xl' }} mt='4rem'>
            <SimpleGrid spacing={4} templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={{ base: 6, md: 10 }}>
                {features.map((cardProps, index) => (
                    <GridItem key={index}>
                        <FeatureCard {...cardProps} />
                    </GridItem>
                ))}
            </SimpleGrid>
        </Container>
    )
}

export default Features