import { ProjectsFindResponse } from 'src/api/ProjectsFindResponse';
import { ProjectsListResponse } from 'src/api/ProjectsListResponse';
import { ProjectsStoreRequest } from 'src/api/ProjectsStoreRequest';
import { ProjectSummary } from 'src/api/ProjectSummary';
import apiClient from './core/ApiClient'
import { ProjectsNameListResponse } from 'src/api/ProjectsNameListResponse';
import { IdAndName } from 'src/api/IdAndName';
import { ProjectsActiveListResponse } from 'src/api/ProjectsActiveListResponse';

const root = '/api/projects'

const add = (ps: ProjectSummary): Promise<void> => {
    let payload: ProjectsStoreRequest = {
        summary: ps
    }
    return apiClient.post<ProjectsStoreRequest, void>(root, payload);
}

const update = (ps: ProjectSummary): Promise<void> => {
    let payload: ProjectsStoreRequest = {
        summary: ps
    }
    return apiClient.put<ProjectsStoreRequest, void>(root, payload);
}

const remove = (id: number): Promise<void> => apiClient.delete<void>(`${root}/${id}`);

const find = (id: number): Promise<ProjectSummary> => apiClient.get<ProjectsFindResponse>(`${root}/${id}`).then(r => r.summary);

const listAll = (): Promise<ProjectsListResponse> => apiClient.get<ProjectsListResponse>(root);

const listNames = (): Promise<IdAndName[]> => apiClient.get<ProjectsNameListResponse>(`${root}/names`).then(r => r.results.concat([]));
const listFundraisingNames = (): Promise<IdAndName[]> => apiClient.get<ProjectsNameListResponse>(`${root}/names?fundraising_visible=true`).then(r => r.results.concat([]));

const listActives = (): Promise<ProjectsActiveListResponse> => apiClient.get<ProjectsActiveListResponse>(`${root}/actives`);

export const projectService = {
    add,
    update,
    remove,
    find,
    listAll,
    listNames,
    listFundraisingNames,
    listActives,
}