import { Box, useSteps, Step, StepDescription, StepIcon, StepIndicator, StepSeparator, StepStatus, StepTitle, Stepper } from '@chakra-ui/react';
import { FcCalendar, FcDocument } from 'react-icons/fc';
import { OverallBudget } from 'src/api/OverallBudget';
import { AmountUtil } from 'src/data/AmountUtil';

const HorizontalTimeline = ({ events }: { events: OverallBudget[] }) => {
    const steps = events.filter(e => e.year > 2019);
    const currentYear = new Date().getFullYear();

    const { activeStep } = useSteps({
        index: steps.findIndex(e => e.year === currentYear),
        count: steps.length,
    })

    return (
        <Stepper index={activeStep}>
            {steps.map((step, index) => (
                <Step key={index}>
                    <StepIndicator>
                        <StepStatus
                            complete={<StepIcon />}
                            incomplete={<FcDocument />}
                            active={<FcCalendar/>}
                        />
                    </StepIndicator>

                    <Box flexShrink='0'>
                        <StepTitle>{step.year}</StepTitle>
                        <StepDescription>{AmountUtil.format(step.budget.value)} {step.budget.currency}</StepDescription>
                    </Box>

                    <StepSeparator />
                </Step>
            ))}
        </Stepper>
    );
};

export default HorizontalTimeline;