import { Container, Stack, Box, Heading, Text, useColorModeValue } from "@chakra-ui/react";
import Carousel from "./Carousel";
import { OrgUtil } from "src/data/OrgUtil";

const cards = OrgUtil.isDemo() ? 
[
    '/images/org/demo/kids.webp',
    '/images/org/demo/flower.webp',
    '/images/org/demo/board.webp',
] :
[
    '/images/org/cooperaid/ueberuns.webp',
    '/images/org/cooperaid/uganda-schuljungs-liegen.webp',
    '/images/org/cooperaid/kontakt.webp'
];

const Hero = () => {
    return (
        <>
            <Container maxW={{ base: '2xl', sm: '3xl', md: '4xl', lg: '6xl' }}>
                <Stack
                    as={Box}
                    textAlign={'center'}
                    spacing={{ base: 5, md: 14 }}
                    py={{ base: 5, md: 16, lg: 30 }}>
                    <Heading
                        fontWeight={'bold'}
                        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                        lineHeight={'100%'}
                    >
                        Fundraising<br />
                        <Text as={'span'} color={'green.400'}>
                            Effizient und einfach
                        </Text>
                    </Heading>
                    <Box
                        rounded={'lg'}
                        bg={useColorModeValue('gray.25', 'gray.700')}
                        boxShadow={'2xl'}
                        pos='relative'
                        p={1}>
                        <Box
                            pos="absolute"
                            top="-10px"
                            right="-10px"
                            bottom="-10px"
                            left="-10px"
                            rounded="lg"
                            bgGradient="linear(to-l, #027BA6, #EE8029)"
                            transform="rotate(-3deg)"
                        ></Box>
                        <Carousel cards={cards} />
                    </Box>
                </Stack>
            </Container>
        </>
    );
}

export default Hero