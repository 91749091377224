import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Avatar,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { EmailIcon } from '@chakra-ui/icons';
import ErrorMessage from 'src/components/ErrorMessage';
import { authService } from 'src/service/AuthService';

const Forgot = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent): void => {
      switch (e.code) {
        case 'Enter':
          handleSubmit(e)
          break;
      }
    };
    document.addEventListener('keydown', onKeyDown, true);
    return () => document.removeEventListener('keydown', onKeyDown, true);
  });

  const handleSubmit = async event => {
    console.info('email forgot...');
    event.preventDefault();
    try {
      setIsSubmitting(true);
      if (!email) {
        throw new Error('E-Mail leer');
      }
      await authService.passwordForgot(email);
      setIsSubmitting(false);
      console.info(`${email} forgot requested...`);
      setSuccess(true);
    } catch (error) {
      console.error(error);
      setError('Ungültiger E-Mail');
      setIsSubmitting(false);
      setEmail('');
    }
  };

  return (
    <Flex
      minH={{ base: '50vh', lg: '70vh' }}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.25', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={6} px={6}>
        <Stack align={'center'}>
          <Avatar bg="teal.500" />
          <Heading fontSize={'4xl'} textAlign={'center'}>Passwort vergessen?</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>Gib hier deine E-Mail Adresse an, und du bekommst einen Link zugeschickt, mit dem du dein Passwort zurücksetzen kannst. 🔓</Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('gray.25', 'gray.700')}
          boxShadow={'lg'}
          pos='relative'
          p={8}>
          <Stack spacing={4}>
            {error && <ErrorMessage message={error} />}
            {success && <Alert status='success'><AlertIcon />Geschickt!</Alert>}
            <FormControl id="email" isRequired isReadOnly={success}>
              <FormLabel>E-Mail</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents='none' children={<EmailIcon color='gray.400' />} />
                <Input value={email} type="email" title='' onChange={event => {
                  setEmail(event.currentTarget.value);
                  setError('');
                }} />
              </InputGroup>
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'center'}
                justify={'space-between'}
                spacing={5}>

                <Button onClick={() => navigate('/')} bg={'red.400'} color={'white'} _hover={{ bg: 'red.500' }}>
                  Schliessen
                </Button>
                {!success && <Button isLoading={isSubmitting} bg={'blue.400'} color={'white'} _hover={{ bg: 'blue.500' }} onClick={event => handleSubmit(event)}>
                  Link zusenden
                </Button>}
              </Stack>

            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

export default Forgot