import { Flex, Stack, Spinner, Skeleton, Box, Switch, VStack } from "@chakra-ui/react";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Money } from "src/api/Money";
import { ProjectBalance } from "src/api/ProjectBalance";
import { ProjectTotal } from "src/api/ProjectTotal";
import Content from "src/components/Content"
import DataTable, { amountHeader, moneyCell, moneyListCell, moneySort, moneyText } from "src/components/DataTable";
import { ProgressBarReact } from "src/components/ProgressBar";
import ReachLink from "src/components/ReachLink";
import { reportService } from "src/service/ReportService";

const DashboardProjectsAll = () => {
    const [data, setData] = useState<ProjectTotal[]>([]);
    const [active, setActive] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const loadAllData = (): Promise<void> => {
        setIsLoading(true);
        return reportService.projectAll()
            .then(r => setData(r.overall.concat()))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        loadAllData();
    }, []);

    const onView = useCallback((id: number): void => {
        console.info(`viewing ${id}`);
        navigate(`/projects/overview/projects/${id}`);
    }, [navigate]);

    const onFilter = (row: ProjectTotal, value: string): boolean => {
        //console.log(`row=${row.project.name} value=${value}`);
        if (value === 'active') {
            return row.project.active;
        }
        return true;
    }

    const columns: ColumnDef<ProjectTotal>[] = useMemo(
        () => [
            {
                id: 'project',
                header: 'Projekt',
                accessorKey: 'project.name',
                cell: (info) => {
                    let row = info.row.original;
                    return (<ReachLink to={`/projects/view/${row.project.id}`} name={row.project.name} />);
                },
            },
            {
                id: 'budget',
                header: amountHeader({ name: 'Projekt Budget' }),
                accessorKey: 'balance.budget',
                cell: moneyCell(),
                sortingFn: moneySort(),
            },
            {
                id: 'transfer',
                header: amountHeader({ name: 'Transfer', explanation: 'Transfers an Partner und Konsulenten (in grün)' }),
                accessorFn: (row: ProjectTotal) => row.balance,
                cell: (info: CellContext<ProjectTotal, ProjectBalance>) => {
                    let balance = info.getValue();
                    return <VStack>
                        {moneyText(balance.transfer, '10rem', 'normal')}
                        {balance.transferConsultant.value !== 0 && <Flex color='green' fontSize='small'>{moneyText(balance.transferConsultant, '10rem', 'normal')}</Flex>}
                    </VStack>
                }
            },
            {
                id: 'transferCoverage',
                header: amountHeader({ name: 'Deckung %', explanation: '∑ Transfers / Budget' }),
                accessorKey: 'coverage.transferCoverage',
                cell: (info: CellContext<ProjectTotal, number>) => ProgressBarReact(info.getValue())
            },
            {
                id: 'localBudget',
                header: amountHeader({ name: 'Fremd Budget' }),
                accessorKey: 'balance.localBudget',
                cell: moneyCell(),
                sortingFn: moneySort(),
            },
            {
                id: 'localTransfers',
                header: amountHeader({ name: 'Fremd Transfer', explanation: 'Transfers an Partner und Konsulenten (in grün) in Fremdwährungen' }),
                accessorFn: (row: ProjectTotal) => row.balance,
                cell: (info: CellContext<ProjectTotal, ProjectBalance>) => {
                    let balance = info.getValue();
                    return <VStack>
                        {moneyListCell(balance.localTransfers, info.row.original.project.currency)}
                        {balance.localTransfersConsultant.length > 0 && <Flex color='green' fontSize='small'>{moneyListCell(balance.localTransfersConsultant, info.row.original.project.currency)}</Flex>}
                    </VStack>
                }
            },
            {
                id: 'localExpenses',
                header: amountHeader({ name: 'Ausgaben' }),
                accessorKey: 'balance.localExpenses',
                cell: (info: CellContext<ProjectTotal, Money[]>) => moneyListCell(info.getValue(), info.row.original.project.currency),
            },
            {
                id: 'expenseCoverage',
                header: amountHeader({ name: 'Ausgaben %', explanation: '∑ Ausgaben / Budget' }),
                accessorKey: 'coverage.expenseCoverage',
                cell: (info: CellContext<ProjectTotal, number>) => ProgressBarReact(info.getValue())
            },
        ], []
    );

    return (
        <Content caption='Projekte Übersicht'>
            <Box pb='.5rem'>
                <Switch id='remember' isChecked={active} onChange={event => setActive(event.currentTarget.checked)}>nur die Aktiven</Switch>
            </Box>
            {isLoading ? (
                <Stack py={5}>
                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <DataTable columns={columns} data={data} initialSortByColumn='project' initialSortByDesc={false}
                    hideColumnsOnXs={['transfer', 'localBudget', 'localTransfers', 'localExpenses']}
                    onFilter={onFilter} filter={active ? 'active' : ''}
                    onRowClick={row => onView(row.project.id)}
                />
            )}
        </Content>
    )
}

export default DashboardProjectsAll