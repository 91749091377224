import { Input } from "@chakra-ui/react";
import { NumericFormat } from "react-number-format";
import { forwardRef } from "react";

const AmountInput = ({ ...props }, ref): React.ReactElement => (<Input
  textAlign='right'
  as={NumericFormat}
  thousandSeparator="'"
  decimalSeparator="."
  decimalScale={2}
  fixedDecimalScale={true}
  title='Bitte das Feld ausfüllen.'
  getInputRef={ref}
  {...props}
/>)

/**
 * When using it from a controller, automatic conversion fails because of the grouping character.
 * Extend the onChange like:
 * onChange={v => onChange(AmountUtil.parse(v.target.value))}
 */
export const CustomAmountInput = forwardRef(AmountInput)

export default AmountInput