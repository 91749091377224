// Generated by ScalaTS 0.7.0: https://scala-ts.github.io/scala-ts/

declare var exports: any;

export const nsRoot = exports;

import * as nsRole from './Role';
import type { Role } from './Role';

export const dependencyModules = [
  nsRole,
];

export class Root implements Role {
  private static instance: Root;

  private constructor() {}

  public static getInstance() {
    if (!Root.instance) {
      Root.instance = new Root();
    }

    return Root.instance;
  }
}

export const RootInhabitant: Root = Root.getInstance();

export function isRoot(v: any): v is Root {
  return (v instanceof Root) && (v === RootInhabitant);
}

export type RootSingleton = Root;
