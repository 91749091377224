import * as icons from 'country-flag-icons/react/3x2'
import { Icon } from "@chakra-ui/react";

type Flags = typeof icons;
type FlagKey = keyof Flags;

const getFlag = (key: FlagKey): Flags[FlagKey] => icons[key];

// ISO2 with capital letter
const CountryFlag = ({ code, ...props }: { code: string }): React.ReactElement => {
  const selectedFlagName = code.toUpperCase();
  const Flag = getFlag(selectedFlagName as FlagKey);
  return <Icon as={Flag} {...props} />;
}

export default CountryFlag