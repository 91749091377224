import { Stack, Spinner, Skeleton, Button, useDisclosure, Heading, Divider } from '@chakra-ui/react';
import Content from 'src/components/Content';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Board from 'react-trello';
import { boardService } from 'src/service/BoardService';
import { FcDocument } from 'react-icons/fc';
import CardDialog from './CardDialog';
import CardComponent from './CardComponent';
import { BoardCard } from 'src/api/BoardCard';
import { Board as BoardData } from 'src/api/Board';
import LaneHeaderComponent from './LaneHeaderComponent';
import { CellContext, ColumnDef } from '@tanstack/table-core';
import DataTable from 'src/components/DataTable';
import { BoardTag } from 'src/api/BoardTag';
import Tags from './Tags';
import ConfirmDialog from 'src/components/ConfirmDialog';


const Dev = () => {
  const [data, setData] = useState<BoardData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [eventBus, setEventBus] = useState(undefined);
  const [changeCard, setChangeCard] = useState<BoardCard | null>(null);
  const [changeLaneId, setChangeLaneId] = useState<number | null>(null);
  const [deleteCallback, setDeleteCallback] = useState<() => any>(undefined);
  const [backlog, setBacklog] = useState<BoardCard[]>([]);
  const [archive, setArchive] = useState<BoardCard[]>([]);

  const { isOpen: isTaskOpen, onOpen: onTaskOpen, onClose: onTaskClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

  const removeNull = (card: BoardCard) => {
    if (card.description === null) delete card.description;
    if (card.label === null) delete card.label;
    return card;
  }

  const onBoardUpdated = useCallback((next) => {
    let board: BoardData = {
      name: 'dev',
      lanes: next.lanes
    }
    //console.log(JSON.stringify(next));
    console.log('board dev updating...');
    boardService.update(board);
    // update the state as well
    setData(board);
    console.log('board dev has been updated');
  }, []);

  // new or update task, callback from entry dialog
  const editedCard = (aCard: BoardCard) => {
    let type = changeCard === null ? 'ADD_CARD' : 'UPDATE_CARD';
    let laneId = changeLaneId === null ? 'todo' : changeLaneId;
    const card = removeNull(aCard);
    console.log(`card[${type}] = ${JSON.stringify(card)}`);
    if (type === 'ADD_CARD') {
      // instead of add to bottom, make a lane update to put the new card in the front
      const cards = data.lanes.find(l => l.id === laneId)?.cards.concat([]) ?? [];
      cards.unshift(card);
      eventBus.publish({
        type: 'UPDATE_CARDS',
        laneId: laneId,
        cards: cards
      });
    } else {
      eventBus.publish({
        type: type,
        laneId: laneId,
        card: card,
      });
    }
  }

  const findCard = (cardId, laneId): BoardCard | undefined => {
    const lane = data.lanes.find(l => l.id === laneId);
    return lane.cards.find(c => c.id === cardId);
  }

  const onCardClicked = (cardId, metadata, laneId) => {
    console.log(`editing ${cardId} on lane ${laneId} metadata ${metadata}`);
    let card = findCard(cardId, laneId);
    setChangeCard({ ...card });
    setChangeLaneId(laneId);
    console.log(`found card ${card.title} on lane ${laneId}`);
    onTaskOpen();
  }

  const onBeforeCardDelete = (callback) => {
    setDeleteCallback(() => callback);
    onDeleteOpen();
  }

  const onCardDelete = async (cardId, laneId) => {
    console.log(`archiving card ${cardId}, ${laneId}`);
    const card = findCard(cardId, laneId);
    console.log(`found card ${card.title}`)
    if (card) {
      await boardService.archive(card).then(_ => setArchive(data => {
        let on = [...data];
        on.unshift(card);
        return on;
      }));
    }
  }

  useEffect(() => {
    setIsLoading(true);
    const a = boardService.find('dev').then(res => setData(old => {
      let m = {
        ...old,
        ...res
      };
      return m;
    })).catch(err => {
      console.log(err);
      // set a default board
      setData({
        name: 'dev', lanes: [
          {
            id: 'todo',
            title: 'To Do 📋',
            label: '0',
            cards: []
          },
          {
            id: 'bugs',
            title: 'Analysis 🧮',
            label: '0',
            cards: []
          },
          {
            id: 'work',
            title: 'In Work ⚙️',
            label: '0',
            cards: []
          },
          {
            id: 'done',
            title: 'Done 🎉',
            label: '0',
            cards: []
          }
        ]
      })
    });
    const b = boardService.findLane('archive').then(res => {
        console.log(`archived cards ${res.length}`);
        setArchive(res);
      });
    const c = boardService.findLane('backlog').then(res => {
        console.log(`backlog cards ${res.length}`);
        setBacklog(res);
      });
    Promise.all([a, b, c]).then(_ => {}).finally(() => setIsLoading(false));
  }, []);

  const columns: ColumnDef<BoardCard>[] = useMemo(
    () => [
      {
        id: 'title',
        header: 'Title',
        accessorKey: 'title',
      },
      {
        id: 'description',
        header: 'Description',
        accessorKey: 'description',
      },
      {
        id: 'tags',
        header: 'Tags',
        accessorFn: (row: BoardCard) => row.tags,
        cell: (info: CellContext<BoardCard, BoardTag[]>) => {
          const tags = info.getValue();
          return (<Tags tags={tags} />);
        },
      },
      {
        id: 'due',
        header: 'Due',
        accessorKey: 'label',
      },
    ], []
  );

  return (
    <Content caption='Development Board'>
      <Button leftIcon={<FcDocument />} colorScheme="blue" rounded="md" onClick={() => {
        setChangeCard(null);
        setChangeLaneId(null);
        onTaskOpen();
      }}>New Task</Button>
      {isLoading || !data ? (
        <Stack py={5}>
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          <Skeleton height='20px' />
          <Skeleton height='20px' />
          <Skeleton height='20px' />
        </Stack>
      ) : (
        <Board data={data}
          onDataChange={onBoardUpdated}
          onCardClick={onCardClicked}
          onBeforeCardDelete={onBeforeCardDelete}
          onCardDelete={onCardDelete}
          eventBusHandle={setEventBus}
          components={{ Card: CardComponent, LaneHeader: LaneHeaderComponent }}
          draggable
          laneDraggable={false}
          editLaneTitle={false}
          style={{ padding: '30px 20px', fontFamily: 'Verdana', background: 'url(/images/mettmenalp.webp)', backgroundSize: 'cover', backgroundColor: 'white' }} />
      )}

      <CardDialog
        data={changeCard}
        isOpen={isTaskOpen}
        onClose={onTaskClose}
        onConfirm={(card: BoardCard) => Promise.resolve(editedCard(card))} />
      <ConfirmDialog
        heading='Task Archivieren?'
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onConfirm={async (): Promise<void> => {
          const removeFn = deleteCallback;
          if (removeFn) {
            removeFn();
            setDeleteCallback(undefined);
          }
        }}
      />

      <Divider my='1.5rem' />
      <Heading textAlign={'center'} size={'lg'}>Backlog 📋</Heading>
      <DataTable columns={columns} data={backlog}
        hideColumnsOnXs={['tags', 'description']}
      />
      <Divider my='1.5rem' />
      <Heading textAlign={'center'} size={'lg'}>Archive 🗄️</Heading>
      <DataTable columns={columns} data={archive}
        hideColumnsOnXs={['tags', 'description']}
      />
    </Content>
  );
}

export default Dev